export const pageEndPoints = {
  LOGIN: '/login',
  DASHBOARD: '/',
  FORGOT: '/forgot-password',
  RESET: '/reset-password',
  MY_ORDER: '/my-order',
  PRINT_JOB: '/print-jobs',
  PROFILE: '/profile',
  ERROR: '/error',
};

export const apiEndPoints = {
  LOGIN: 'api/auth/login/',
  LOGOUT: 'api/auth/logout/',
  DASHBOARD: 'api/dashboard',
  USER_AUTH_DATA: 'api/user/',
  MAIDEN_RESET: function (id) {
    return `api/user/${id}/reset-password`;
  },
  BUYING_AGENCY: 'api/buying-agency/list/',
  LABEL_LIST: 'api/product/',
  CREATE_ORDER: 'api/order',
  ORDER_LISTING: 'api/order',
  PRINT_LISTING: 'api/print-job',
  ORDER_STATUS_LIST: 'api/order-status/list/',
  JOB_STATUS_LIST: 'api/print-job-status/list/',
  GET_ARTWORK: 'api/get-artwork',
  GENERATE_ARTWORK: 'api/generate-artwork',
  ORDER_SHIPMENT_STATUS: function (id) {
    return `api/order/${id}/progress`;
  },
};
