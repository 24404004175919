import React, { useState } from 'react';
import { Stack } from '@mui/system';
import { connect } from 'react-redux';
import { constants } from '../../../constants';
import LabelCard from '../../../layouts/LabelCard';
import ButtonCustom from '../../ButtonCustom';
import OrderPlace from '../../OrderPlace';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import svgFiles from '../../../assets/svgFiles';

const DashboardHeader = ({ auth }) => {
  const { firstName } = auth;
  const [openDialog, setOpenDialog] = useState(false);
  const authSuccess = useSelector((state) => state?.auth);
  return (
    <>
      <LabelCard
        borderRadius={'0px'}
        height={'98px'}
        padding={'0px 36px'}
        otherStyle={{
          borderBottom: '1px solid #E4E4E4',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Stack spacing={1}>
          <div className="color_black_text fs24-24 md-semibold">Dashboard</div>
          <div className=" color_black_text fs12-17 md-light">
            Welcome Back,{' '}
            <strong className="capitalize">{firstName && firstName.toLowerCase()}</strong>
          </div>
        </Stack>
        {authSuccess?.userType !== 2 ? (
          <>
            {authSuccess?.is_delinquent ? (
              <Tooltip
                placement="bottom-end"
                title={
                  <div
                    className="md-semibold fs12-17"
                    style={{ display: 'flex', alignItems: 'center' }}>
                    {svgFiles?.Warning}{' '}
                    <span style={{ marginLeft: '5px' }}>Please contact the admin</span>
                  </div>
                }
                arrow
                slotProps={{
                  tooltip: {
                    style: {
                      marginTop: '20px',
                      boxShadow: '0px 0px 14px #D9D9D9',
                      background: '#FFFFFF',
                      color: '#000000',
                      padding: '12px',
                      width: 'fit-content',
                    },
                  },
                  arrow: {
                    style: {
                      boxShadow: '0px 0px 14px #D9D9D9',
                      color: '#FFFFFF',
                    },
                  },
                }}>
                <div>
                  <ButtonCustom padding={'14px 18px'} width={'fit-content'} disable={true}>
                    {constants?.CREATE_ORDER_BTN}
                  </ButtonCustom>
                </div>
              </Tooltip>
            ) : (
              <ButtonCustom
                padding={'14px 18px'}
                width={'fit-content'}
                disable={authSuccess?.is_delinquent}
                onClick={() => setOpenDialog(!openDialog)}>
                {constants?.CREATE_ORDER_BTN}
              </ButtonCustom>
            )}
          </>
        ) : (
          <></>
        )}
      </LabelCard>
      <OrderPlace openOrder={openDialog} closeOrder={() => setOpenDialog(!openDialog)} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(DashboardHeader);
