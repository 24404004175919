import React from 'react';
import { Stack } from '@mui/system';
import LabelCard from '../../../layouts/LabelCard';

const AmountCard = ({ due = '00', monthlyDue = '00', onClick }) => {
  return (
    <LabelCard
      padding="10px 20px 20px 20px"
      height="164px"
      isHover={true}
      onClick={onClick}
      otherStyle={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'space-between',
      }}>
      <Stack spacing={1.25}>
        <div className="color_black_text md-medium fs14-22">Amount Due</div>
        <div className="color_black_text md-semibold fs32-32">{due}</div>
      </Stack>
      <Stack spacing={1.25}>
        <div className="color_black_text md-regular fs14-22">Monthly billing</div>
        <div className="color_black_text md-medium fs20-22">{monthlyDue}</div>
      </Stack>
    </LabelCard>
  );
};

export default AmountCard;
