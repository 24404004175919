import React from 'react';

const TotalOrders = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="26" cy="26" r="26" fill="#826EF1" fillOpacity="0.16" />
      <g clip-path="url(#clip0_314_4390)">
        <path
          d="M36.5694 39.9999H15.4942C14.1879 39.5166 13.8584 39.0374 13.8584 37.6196C13.8584 32.0716 13.8584 26.5229 13.8584 20.9748V20.6289H38.206V20.9748C38.206 23.5759 38.206 26.1776 38.206 28.7787C38.206 31.8255 38.2046 34.8729 38.2073 37.9197C38.2073 38.4782 38.0583 38.9759 37.6741 39.3942C37.3733 39.7217 36.9789 39.8728 36.5701 40.0006L36.5694 39.9999ZM24.9244 30.448C24.463 29.9839 24.0385 29.5341 23.5859 29.1137C23.4355 28.9735 23.225 28.8621 23.0247 28.8211C22.6932 28.7527 22.3719 28.9551 22.2188 29.2565C22.0629 29.5642 22.1183 29.9401 22.3842 30.2108C22.996 30.8329 23.616 31.4475 24.2333 32.0641C24.7269 32.5562 25.1445 32.5549 25.6422 32.0579C26.967 30.7345 28.2918 29.4104 29.6152 28.0862C29.6925 28.009 29.7725 27.9324 29.8333 27.8436C30.0192 27.5735 30.0418 27.2816 29.89 26.9932C29.7328 26.6937 29.4655 26.5085 29.1326 26.5748C28.9009 26.6213 28.6521 26.7498 28.4825 26.9152C27.3635 28.0076 26.2643 29.1191 25.1582 30.2245C25.0885 30.2942 25.016 30.3605 24.9237 30.4494L24.9244 30.448Z"
          fill="#826EF1"
        />
        <path
          d="M25.2182 18.9539H14.4707C14.5213 18.8528 14.5562 18.7742 14.5992 18.699C15.7012 16.7849 16.8065 14.8729 17.9051 12.9575C18.2592 12.3395 18.7794 12.0435 19.4924 12.0469C21.3032 12.0544 23.1141 12.0496 24.9249 12.0496C25.0151 12.0496 25.1061 12.0496 25.2182 12.0496V18.9539Z"
          fill="#826EF1"
        />
        <path
          d="M37.6119 18.9523H26.9014V12.0645C26.9868 12.0597 27.0661 12.0515 27.1461 12.0515C28.974 12.0508 30.8026 12.0515 32.6306 12.0508C33.2861 12.0508 33.7872 12.3276 34.116 12.8943C35.2562 14.8583 36.3924 16.825 37.5299 18.791C37.5524 18.83 37.5709 18.8717 37.6119 18.953V18.9523Z"
          fill="#826EF1"
        />
      </g>
      <defs>
        <clipPath id="clip0_314_4390">
          <rect width="28" height="28" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TotalOrders;
