import React from 'react';

const ResetKey = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="44" cy="44" r="44" fill="#F4F9FF" />
      <circle cx="43.9991" cy="44.0001" r="35.4444" fill="#E1EBFC" />
      <path
        d="M58.6668 40.7402H45.0594C44.3881 38.8343 43.1419 37.1837 41.4927 36.0162C39.8435 34.8486 37.8726 34.2216 35.852 34.2217C30.4579 34.2217 26.0742 38.6054 26.0742 43.9995C26.0742 49.3935 30.4579 53.7772 35.852 53.7772C37.8726 53.7773 39.8435 53.1503 41.4927 51.9827C43.1419 50.8152 44.3881 49.1646 45.0594 47.2587H45.6298L48.889 50.518L52.1483 47.2587L55.4076 50.518L61.9261 43.9343L58.6668 40.7402ZM35.852 48.8883C33.1631 48.8883 30.9631 46.6883 30.9631 43.9995C30.9631 41.3106 33.1631 39.1106 35.852 39.1106C38.5409 39.1106 40.7409 41.3106 40.7409 43.9995C40.7409 46.6883 38.5409 48.8883 35.852 48.8883Z"
        fill="#0052CC"
      />
    </svg>
  );
};

export default ResetKey;
