import React from 'react';
import './stepperStyle.css';

const Stepper = ({ step }) => {
  return (
    <div className="stepper-box">
      <div className="stepper-div">
        <div className={`md-medium ${'stepper-count'}`}>01</div>
        <div className={`md-regular ${'stepper-text'}`}>Select Buying Agency</div>
      </div>
      <div className="stepper-div">
        <div className={`md-medium ${step >= 2 ? 'stepper-count' : 'stepper-count-inactive'}`}>
          02
        </div>
        <div className={`md-regular ${step >= 2 ? 'stepper-text' : 'stepper-text-inactive'}`}>
          Choose Label Type
        </div>
      </div>
      <div className="stepper-line stepper-lineactive"></div>
      <div className="stepper-div">
        <div className={`md-medium ${step === 3 ? 'stepper-count' : 'stepper-count-inactive'}`}>
          03
        </div>
        <div className={`md-regular ${step === 3 ? 'stepper-text' : 'stepper-text-inactive'}`}>
          Upload File
        </div>
      </div>
      <div
        className={
          step === 3 ? 'stepper-line2 stepper-lineactive' : 'stepper-line2 stepper-lineInactive'
        }></div>
    </div>
  );
};

export default Stepper;
