import { pageTitles } from './pageTitles';
import { pageEndPoints } from '../constants/endPoints';

export const getCurrentPath = (pathname) => {
  switch (pathname) {
    case pageEndPoints?.DASHBOARD:
      return pageEndPoints?.DASHBOARD;
    //   break;
    case pageEndPoints?.MY_ORDER:
      return pageEndPoints?.MY_ORDER;
    case pageEndPoints?.PRINT_JOB:
      return pageEndPoints?.PRINT_JOB;
    //   break;
    case pageEndPoints?.PROFILE:
      return pageEndPoints?.PROFILE;
    //   break;
    default:
      document.title = pageTitles?.DASHBOARD;
      return pageEndPoints?.DASHBOARD;
  }
};
