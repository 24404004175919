import React, { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { Stack } from '@mui/system';
import { useLocation } from 'react-router';
import styles from './style.module.css';
import CollapseButton from '../../assets/svgFiles/collapseButton';
// import CollapseSign from '../../assets/svgFiles/collapseSign';
import DashboardIcon from '../../assets/svgFiles/DashboardIcon';
import ExpandButton from '../../assets/svgFiles/expandButton';
// import ExpandSign from '../../assets/svgFiles/expandSign';
import MyOrder from '../../assets/svgFiles/MyOrder';
import Profile from '../../assets/svgFiles/Profile';
import { constants } from '../../constants';
import { pageEndPoints } from '../../constants/endPoints';
import { getCurrentPath } from '../../utils/currentPath';
import CustomTooltip from '../CustomTooltip';
import NavigationCard from '../NavigationCard';
import PrintJob from '../../assets/svgFiles/PrintJob';
import { useSelector } from 'react-redux';

const Sidebar = ({ setIsExpand, isExpand }) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(null);
  const authSuccess = useSelector((state) => state?.auth);

  useEffect(() => {
    setCurrentPath(getCurrentPath(location.pathname));
  }, [location.pathname]);

  const handleSidebar = (e) => {
    e.stopPropagation();
    setIsExpand(!isExpand);
  };
  return (
    <div
      className={`${isExpand ? styles?.sidebar_expand : styles?.sidebar_collapse} ${
        styles?.sidebar
      }`}>
      <div
        className={`${
          isExpand ? styles?.sidebar_navigation_expand : styles?.sidebar_navigation_collapse
        } ${styles?.sidebar_navigation}`}>
        <div className={styles?.sidebar_navigation_wrapper}>
          <Stack direction={'column'} spacing={3.5} justifyContent={'center'} alignItems={'center'}>
            <NavigationCard
              icon={<DashboardIcon active={currentPath === pageEndPoints?.DASHBOARD} />}
              heading={constants?.DASHBOARD}
              active={currentPath === pageEndPoints?.DASHBOARD}
              link={pageEndPoints?.DASHBOARD}
            />
            <NavigationCard
              icon={<MyOrder active={currentPath === pageEndPoints?.MY_ORDER} />}
              heading={constants?.MY_ORDER}
              active={currentPath === pageEndPoints?.MY_ORDER}
              link={pageEndPoints?.MY_ORDER}
            />
            {authSuccess?.userType === 2 && (
              <NavigationCard
                icon={<PrintJob active={currentPath === pageEndPoints?.PRINT_JOB} />}
                heading={constants?.PRINT_JOB}
                active={currentPath === pageEndPoints?.PRINT_JOB}
                link={pageEndPoints?.PRINT_JOB}
              />
            )}
            <NavigationCard
              icon={<Profile active={currentPath === pageEndPoints?.PROFILE} />}
              heading={constants?.MY_PROFILE}
              active={currentPath === pageEndPoints?.PROFILE}
              link={pageEndPoints?.PROFILE}
            />
          </Stack>
        </div>
      </div>
      <div className={styles?.horizontal_container} onClick={() => setIsExpand(false)}>
        <div className={styles?.horizontal_line}></div>
        {isExpand && (
          <div className={styles?.horizontal_area}>
            <div className={styles?.horizontal_line_bold}></div>
          </div>
        )}

        <CustomTooltip
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div style={{ height: 'fit-content', marginBottom: '2.5px' }}>
                {isExpand ? 'Collapse' : 'Expand'}
              </div>
              {/* {isExpand ? <CollapseSign /> : <ExpandSign />} */}
            </div>
          }
          //   placement="bottom-end"
          PopperProps={{
            sx: {
              marginTop: '5px !important',
              marginLeft: '70px !important',
            },
          }}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}>
          <div className={`${styles?.button_position} c-pointer`} onClick={handleSidebar}>
            {isExpand ? <CollapseButton /> : <ExpandButton />}
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
};

export default Sidebar;
