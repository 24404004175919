import React from 'react';
import svgFiles from '../../assets/svgFiles';

const ShipmentDetails = ({ shipmentDetails }) => {
  return (
    <div>
      <div
        className="md-light fs12-17"
        style={{
          color: '#909090',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
        }}>
        {svgFiles?.Shipment}
        <span style={{ marginLeft: '4px' }}>Mode of Shipment</span>
      </div>
      <div
        className="md-medium fs14-14"
        style={{ color: '#333840', textTransform: 'uppercase', marginTop: '8px' }}>
        {shipmentDetails?.mode_of_shipment || ''}
      </div>
      <div className="md-light fs12-17" style={{ color: '#333840', marginTop: '8px' }}>
        {shipmentDetails?.comment || 'Your order has been shipped'}
      </div>
    </div>
  );
};

export default ShipmentDetails;
