import React from 'react';

const OptionIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0417 5.83333C10.3713 5.83333 10.6935 5.73559 10.9676 5.55245C11.2417 5.36931 11.4553 5.10902 11.5815 4.80447C11.7076 4.49993 11.7406 4.16482 11.6763 3.84152C11.612 3.51822 11.4533 3.22124 11.2202 2.98816C10.9871 2.75507 10.6901 2.59633 10.3668 2.53203C10.0435 2.46772 9.70841 2.50072 9.40386 2.62687C9.09932 2.75301 8.83902 2.96664 8.65589 3.24072C8.47275 3.5148 8.375 3.83703 8.375 4.16667C8.375 4.6087 8.5506 5.03262 8.86316 5.34518C9.17572 5.65774 9.59964 5.83333 10.0417 5.83333ZM10.0417 14.1667C9.71203 14.1667 9.3898 14.2644 9.11572 14.4476C8.84164 14.6307 8.62801 14.891 8.50187 15.1955C8.37572 15.5001 8.34272 15.8352 8.40703 16.1585C8.47133 16.4818 8.63007 16.7788 8.86316 17.0118C9.09624 17.2449 9.39322 17.4037 9.71652 17.468C10.0398 17.5323 10.3749 17.4993 10.6795 17.3731C10.984 17.247 11.2443 17.0334 11.4275 16.7593C11.6106 16.4852 11.7083 16.163 11.7083 15.8333C11.7083 15.3913 11.5327 14.9674 11.2202 14.6548C10.9076 14.3423 10.4837 14.1667 10.0417 14.1667ZM10.0417 8.33333C9.71203 8.33333 9.3898 8.43108 9.11572 8.61422C8.84164 8.79735 8.62801 9.05765 8.50187 9.3622C8.37572 9.66674 8.34272 10.0019 8.40703 10.3252C8.47133 10.6485 8.63007 10.9454 8.86316 11.1785C9.09624 11.4116 9.39322 11.5703 9.71652 11.6346C10.0398 11.699 10.3749 11.6659 10.6795 11.5398C10.984 11.4137 11.2443 11.2 11.4275 10.926C11.6106 10.6519 11.7083 10.3296 11.7083 10C11.7083 9.55797 11.5327 9.13405 11.2202 8.82149C10.9076 8.50893 10.4837 8.33333 10.0417 8.33333Z"
        fill="#909090"
      />
    </svg>
  );
};

export default OptionIcon;
