import styled from '@emotion/styled';
import { Tooltip, tooltipClasses } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F0F6FF',
    color: '#172B4D',
    maxWidth: 220,
    border: '2px solid #A8C1E6',
    borderRadius: '3px',
    fontFamily: "'Madani-Medium'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    padding: '7px 12px',
  },
}));

export default CustomTooltip;
