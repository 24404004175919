import React from 'react';
import { Box, useTheme } from '@mui/material';
import ErrorMessageSvg from '../../assets/svgFiles/errorMessageSvg';

const ErrorMessage = ({ message }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        margin: '9px 0 0 5px',
        fontFamily: 'Madani-Regular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '12px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '-0.084px',
        color: `${theme?.palette?.error?.main}`,
      }}>
      <ErrorMessageSvg />
      <Box sx={{ marginLeft: '4px' }}>{message}</Box>
    </Box>
  );
};

export default ErrorMessage;
