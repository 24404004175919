import { createStore, combineReducers } from 'redux';

import authReducer from './auth/reducer';
import settingReducer from './setting/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  setting: settingReducer,
});

export const store = createStore(rootReducer);
