import React, { useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import ResetKey from '../../assets/svgFiles/resetKey';
import ResetSuccess from '../../assets/svgFiles/resetSuccess';
import ButtonCustom from '../../components/ButtonCustom';
import AuthTextField from '../../components/CustomTextField';
import ErrorMessage from '../../components/ErrorMessage';
import { constants } from '../../constants';
import { apiEndPoints, pageEndPoints } from '../../constants/endPoints';
import useNetworkRequests from '../../hooks/useNetworkRequests';
import LoginLayout from '../../layouts/LoginLayout';
import './style.css';
import { authSuccess } from '../../store/auth/action';
import { resetPasswordValidate } from '../../utils/validation';

const ResetPassword = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const { userId } = useSelector((state) => state?.auth);
  const { userId } = props.auth;

  const { request, response } = useNetworkRequests();

  //form submit using formik
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordValidate,

    onSubmit: (values) => {
      const { password } = values;
      request('POST', apiEndPoints?.MAIDEN_RESET(userId), {
        password,
      });
    },
  });

  //handle response
  useEffect(() => {
    let networkStatus, data;
    // let statusCode;
    if (response) {
      ({ networkStatus, data } = response);
    }
    if (networkStatus) {
      setPasswordChanged(true); //password changed
      dispatch(
        authSuccess({
          isPasswordReset: true,
        }),
      );
    } else {
      setApiError(data?.message);
    }
  }, [response]);

  //api error message
  useEffect(() => {
    if (apiError) {
      setTimeout(() => {
        setApiError(null);
      }, 5000);
    }
  }, [apiError]);

  return (
    <LoginLayout layoutType={constants?.RESET_LAYOUT}>
      {passwordChanged ? <ResetSuccess /> : <ResetKey />}

      <div className="reset_container">
        <div className="reset_heading md-medium fs36-51 color-dark-grey">
          {passwordChanged ? 'Password reset' : 'Set new password'}
        </div>
        {passwordChanged ? (
          <>
            <div style={{ marginTop: '9px' }} className="md-regular fs15-21 color-dark-grey">
              Your password successfully reset.
            </div>
            <div style={{ marginTop: '72px' }}>
              {' '}
              <ButtonCustom
                onClick={() =>
                  navigate(pageEndPoints?.DASHBOARD, {
                    replace: true,
                  })
                }>
                {constants?.CONTINUE_BTN}
              </ButtonCustom>
            </div>
          </>
        ) : (
          <Box component="form" pt={4} onSubmit={formik?.handleSubmit} autoComplete="off">
            <AuthTextField
              label={'Password'}
              required={true}
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder={'Enter Password'}
              isMarginTop={'20px'}
              onChange={(e) => {
                formik.setFieldValue('password', e.target.value.trim().replaceAll(' ', ''));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik?.errors?.password && formik?.touched?.password}
              message={formik?.errors?.password}
              // touched={true}
              endAdornment={
                <InputAdornment position="end">
                  <div
                    className="md-medium fs14-20 pass_visible c-pointer"
                    onClick={() => setIsPasswordVisible((prev) => !prev)}>
                    {isPasswordVisible ? constants?.PASSWORD_HIDE : constants?.PASSWORD_SHOW}
                  </div>
                </InputAdornment>
              }
            />
            <AuthTextField
              label={'Confirm password'}
              required={true}
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              placeholder={'Enter Confirm Password'}
              isMarginTop={'20px'}
              onChange={(e) => {
                formik.setFieldValue('confirmPassword', e.target.value.trim().replaceAll(' ', ''));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              error={formik?.errors?.confirmPassword && formik?.touched?.confirmPassword}
              message={formik?.errors?.confirmPassword}
              // touched={formik?.touched?.confirmPassword}
              endAdornment={
                <InputAdornment position="end">
                  <div
                    className="md-medium fs14-20 pass_visible c-pointer"
                    onClick={() => setIsConfirmPasswordVisible((prev) => !prev)}>
                    {isConfirmPasswordVisible ? constants?.PASSWORD_HIDE : constants?.PASSWORD_SHOW}
                  </div>
                </InputAdornment>
              }
            />
            <div style={{ paddingTop: '18px' }}>
              {apiError && (
                <div className="flex-center">
                  <ErrorMessage message={apiError} />
                </div>
              )}
              <div style={{ marginTop: '18px' }}>
                <ButtonCustom type={'submit'}>{constants?.RESET_BTN}</ButtonCustom>
              </div>
            </div>
          </Box>
        )}
      </div>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ResetPassword);
