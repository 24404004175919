import React, { useEffect, useState } from 'react';
import PopupLayout from '../../layouts/PopupLayout';
import './style.css';
import QuestionMark from '../../assets/svgFiles/questionMark';
import OrderDetailsStepper from '../OrderDetailsStepper';
import svgFiles from '../../assets/svgFiles';
import ButtonCustom from '../ButtonCustom';
import {
  constants,
  customerOrderStatus,
  defaultCustomerSteps,
  printOperatorOrderStatus,
  printOperatorProgress,
  status,
  typeUser,
} from '../../constants';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { ToastNotifyError } from '../Toast/ToastNotify';
import { updateLoading } from '../../store/auth/action';
import { pageEndPoints } from '../../constants/endPoints';
import { useNavigate } from 'react-router-dom';
import { cancelOrder, downloadArtwork, orderShipmentDetails } from '../../utils/api/orderPlace';
import moment from 'moment/moment';
import ArtWork from '../OrderPlace/ArtWork';
import IconStepper from '../CustomStepper';
import {
  ApprovalPending,
  ApprovalPendingActive,
  InProgress,
  InProgressActive,
  OnHold,
  OnHoldActive,
  Printed,
  PrintedActive,
  Shipped,
  ShippedActive,
} from '../../assets/svgFiles/orderStatus';

const OrderDetails = ({
  open = false,
  handleClosePopup: closePop,
  orderId,
  comments = [],
  orderListing,
  statusList,
  orderName,
  orderStatus,
  auth,
  displayOrderId,
  orderedDate,
}) => {
  // api call on the basis of order Id
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openCancel, setOpenCancel] = useState(false);
  const [step, setStep] = useState(0);
  const [shipmentDetailsData, setShipmentDetailsData] = useState([]);
  const [artWork, setArtWork] = useState('');
  const [openArtwork, setOpenArtWork] = useState(false);
  const [orderDetail, setOrderDetail] = useState(0);
  const [downloadDetail, setdownloadDetail] = useState(0);
  const [shipDetail, setshipDetail] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [fileResponse, setFileResponse] = useState('');

  const blobToBase64 = (url) => {
    return new Promise(async (resolve) => {
      // do a request to the blob uri
      const response = await fetch(url);
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
      // instantiate a file reader
      const fileReader = new FileReader();
      // read the file
      fileReader.readAsDataURL(blob);
      fileReader.onloadend = function () {
        resolve(fileReader.result); // Here is the base64 string
      };
    });
  };

  const handleClosePopup = () => {
    setShipmentDetailsData([]);
    closePop();
  };

  const handleViewArtwork = async () => {
    dispatch(updateLoading({ loading: true }));
    const responseGenerate = await downloadArtwork(orderId);
    setFileResponse(responseGenerate);
    if (parseInt(responseGenerate?.status / 100) === 2) {
      handleClosePopup();
      setdownloadDetail(responseGenerate?.data);
      const file = await blobToBase64(URL.createObjectURL(responseGenerate?.data));
      const order = {
        id: orderId,
        order_name: orderName,
        status: orderStatus,
      };
      setOrderDetail(order);
      setArtWork(file);
      setOpenArtWork(true);
      setTimeout(() => {
        dispatch(updateLoading({ loading: false }));
      }, 2000);
    } else if (parseInt(responseGenerate?.status / 100) === 5) {
      ToastNotifyError('Something went wrong');
      setOpenArtWork(false);
      dispatch(updateLoading({ loading: false }));
    } else {
      ToastNotifyError(data?.message);
      setOpenArtWork(false);
      dispatch(updateLoading({ loading: false }));
    }
  };

  const handleCancelOrder = () => {
    setOpenCancel(true);
  };

  const closeCancel = () => {
    setOpenCancel(false);
  };

  const cancelFun = async () => {
    const dataDetail = {
      id: orderId,
      status: {
        status: 11,
      },
    };
    dispatch(updateLoading({ loading: true }));
    const response = await cancelOrder(dataDetail);
    dispatch(updateLoading({ loading: false }));
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      navigate(pageEndPoints?.MY_ORDER, {
        replace: true,
      });
      setOpenCancel(false);
      handleClosePopup();
      orderListing();
    } else if (parseInt(status / 100) === 5) {
      ToastNotifyError('Something went wrong');
    } else {
      ToastNotifyError(data?.message);
    }
  };

  useEffect(() => {
    if (orderId) {
      setShipmentDetailsData([]);
      shipmentDetails();
    }
  }, [orderId, open]);

  const shipmentDetails = async () => {
    const dataDetail = {
      id: orderId,
    };
    const response = await orderShipmentDetails(dataDetail);
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      // for print operator progress
      setShipmentDetailsData([]);
      if (auth?.userType === typeUser?.PRINT_OPERATOR) {
        let statusPendingData = [...printOperatorProgress];

        let newProgress = data.filter((val) =>
          printOperatorOrderStatus.includes(val?.final_status_id),
        );

        let isError = newProgress.some((val) => val?.final_status_id === 10);
        if (isError) {
          setIsOnHold(true);
          setShipmentDetailsData([]);
          let nonActiveData = statusPendingData.splice(
            newProgress.length - 1,
            statusPendingData.length,
          );
          setShipmentDetailsData([...newProgress, ...nonActiveData]);
        } else {
          setIsOnHold(false);
          setShipmentDetailsData([]);

          let nonActiveData = statusPendingData.splice(
            newProgress.length,
            statusPendingData.length,
          );
          setShipmentDetailsData([...newProgress, ...nonActiveData]);
        }
        setStep(newProgress.length);
      } else {
        if (Array.isArray(data)) {
          setShipmentDetailsData([]);
          let statusPendingData = [...defaultCustomerSteps];

          let orderActiveProgress = data.filter((val) =>
            customerOrderStatus.includes(val?.final_status_id),
          );

          let newOrderActiveProgress = orderActiveProgress.filter(
            (a, i) =>
              orderActiveProgress.findIndex((s) => a.final_status_id === s.final_status_id) === i,
          );

          setStep(newOrderActiveProgress.length - 1);

          let nonActiveData = statusPendingData.splice(
            newOrderActiveProgress.length,
            statusPendingData.length,
          );

          setShipmentDetailsData([...newOrderActiveProgress, ...nonActiveData]);
          setshipDetail(true);
          // }
        }
      }
    } else if (parseInt(status / 100) === 5) {
      ToastNotifyError('Something went wrong');
    } else {
      ToastNotifyError(data?.message);
    }
  };

  // for print operator progress

  const iconsWithError = {
    5: <ApprovalPendingActive />,
    6: <InProgressActive />,
    7: <PrintedActive />,
    8: <ShippedActive />,
    10: <OnHoldActive />,
  };
  const iconsInactiveWithError = {
    5: <ApprovalPending />,
    6: <InProgress />,
    7: <Printed />,
    8: <Shipped />,
    10: <OnHold />,
  };

  const icons = {
    5: <ApprovalPendingActive />,
    6: <InProgressActive />,
    7: <PrintedActive />,
    8: <ShippedActive />,
  };
  const iconsInactive = {
    5: <ApprovalPending />,
    6: <InProgress />,
    7: <Printed />,
    8: <Shipped />,
  };

  return (
    <>
      <PopupLayout
        isOpenPopup={open}
        handleClosePopup={handleClosePopup}
        padding={'0px'}
        popupWidth={'calc(100vw - 160px)'}>
        <div className="order_details_header md-semibold fs24-24">
          <div>Order Details</div>
          <div className="details_close_btn c-pointer" onClick={handleClosePopup}>
            {svgFiles?.CrossButton}
          </div>
        </div>
        <div className="order_details_container">
          <div>
            <div className="order_id_container md-light fs12-22">
              Order ID: <span className="order_number">{displayOrderId || ''}</span>
            </div>
            <div className="md-medium fs20-28 order_name">{orderName}</div>
          </div>
          <div>
            <div className="order_date_container md-light fs12-22">Order Created on</div>
            <div className="md-medium fs12-20 order_date_data">
              {moment(orderedDate).format('DD/MM/YYYY')}
            </div>
          </div>
        </div>
        <div className="order_details_status">
          {auth?.userType === typeUser?.PRINT_OPERATOR ? (
            <IconStepper
              step={step}
              shipmentDetailsData={shipmentDetailsData}
              icons={isOnHold ? iconsWithError : icons}
              iconsInactive={isOnHold ? iconsInactiveWithError : iconsInactive}
            />
          ) : (
            <OrderDetailsStepper
              step={step}
              shipmentDetailsData={shipmentDetailsData}
              statusList={statusList}
            />
          )}
        </div>
        {comments && comments.length > 0 && (
          <div className="order_details_comments">
            <div className="md-medium fs16-23 color_black_text">Comments</div>
            <div className="comments_remarks">
              <div className="comments_remarks_data">
                {comments.map((data, idx) => (
                  <div className="remarks_details" key={idx}>
                    <div className="remarks_profile">
                      <div
                        className={`md-medium fs14-20 user_message_name ${
                          data?.user_type === typeUser?.ADMIN
                            ? 'color_admin'
                            : data?.user_type === typeUser?.PRINT_OPERATOR
                            ? 'color_operator'
                            : data?.user_type === typeUser?.CUSTOMER
                            ? 'color_customer'
                            : 'color-customer'
                        }`}>
                        {data?.user_name || ''}
                      </div>
                      <div className="md-light user_timestamp">
                        {`(${moment(data?.timestamp).format('hh:mm A DD/MM/YYYY')})`}
                      </div>
                    </div>
                    <div className="md-regular user_message_data">{data?.text || ''}</div>
                    {idx === comments.length - 1 ? null : (
                      <Box sx={{ borderBottom: '1px dashed #CED7E3', margin: '16px 0px' }} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {auth?.userType === 2 ? (
          <div className="order_details_btn">
            <ButtonCustom
              width="fit-content"
              padding="16px 30px"
              margin="0px 0px 0px 42px"
              // disable={step >= 2}
              onClick={() => {
                navigate(`/print-jobs?orderId=${orderId}`, {
                  // navigate('/print-jobs', {
                  state: {
                    orderId: orderId,
                  },
                });
              }}>
              {constants?.VIEW_JOBS}
            </ButtonCustom>
          </div>
        ) : (
          <div className="order_details_btn">
            <ButtonCustom
              width="fit-content"
              color={orderStatus > status?.APPROVED ? '#DADBDB' : '#333840'}
              padding="16px 30px"
              background="#ffffff"
              border={orderStatus > status?.APPROVED ? '1px solid #DADBDB' : '1px solid #333840'}
              onClick={() => {
                orderStatus > status?.APPROVED ? null : handleCancelOrder();
              }}
              className={orderStatus > status?.APPROVED ? 'cust_btn_order' : 'button_hover_cancel'}>
              {constants?.CANCEL_ORDER}
            </ButtonCustom>

            <ButtonCustom
              width="fit-content"
              padding="16px 30px"
              margin="0px 0px 0px 42px"
              // disable={step >= 2}
              onClick={handleViewArtwork}>
              {constants?.VIEW_ARTWORK}
            </ButtonCustom>
          </div>
        )}
      </PopupLayout>
      <PopupLayout
        isOpenPopup={openCancel}
        handleClosePopup={closeCancel}
        popupWidth={'467px'}
        padding={'32px 50px'}>
        <div className="progressError-popupAlone">
          <div style={{ width: '100%', textAlign: 'center' }}>
            <div>
              <QuestionMark />
            </div>
            <div className="progress-text" style={{ fontWeight: 'bold' }}>
              {constants.CANCEL_CONFIRMATION}
            </div>
            <div>
              <Button
                className={`md-medium ${'dialog-button'}`}
                onClick={cancelFun}
                style={{ marginRight: '10px', width: '95px' }}>
                {constants?.YES}
              </Button>
              <Button
                className={`md-medium ${'dialog-button-inactive'}`}
                onClick={closeCancel}
                style={{ minWidth: '95px' }}>
                {constants?.NO}
              </Button>
            </div>
          </div>
        </div>
      </PopupLayout>
      {openArtwork && (
        <ArtWork
          openDialog={openArtwork}
          closeDialog={() => {
            setOpenArtWork(!openArtwork);
          }}
          artWorkRes={artWork}
          orderDetail={orderDetail}
          orderListing={orderListing}
          downloadDetail={downloadDetail}
          shipDetail={shipDetail}
          orderStatus={orderStatus}
          responseGenerate={fileResponse}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(OrderDetails);
