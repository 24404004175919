export const getIndianCurrency = (value) => {
  const integerValue = Number(value);

  if (isNaN(integerValue)) {
    return 0;
  }

  let indianCurrency = integerValue.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR',
  });

  return indianCurrency;
};
export const getIndianFormat = (value) => {
  let integerValue = Number(value);

  if (isNaN(integerValue)) {
    return 0;
  }

  let indianFormat = integerValue.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
  });

  return indianFormat;
};

export function createOrderData(orderId, name, heading, days, status) {
  return { orderId, name, heading, days, status };
}

// ago time display

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en'; // English.
import { constants } from '../constants';

TimeAgo.addDefaultLocale(en);
// Create formatter (English).
const timeAgo = new TimeAgo('en-US');

export const agoTimeDisplay = (time = new Date()) => {
  return timeAgo.format(new Date(time));
};

//get total pages

export const getTotalPage = (count = 0) => {
  let totalPages = 1;
  totalPages =
    count % constants?.PER_PAGE_DATA === 0
      ? count / constants?.PER_PAGE_DATA
      : parseInt(count / constants?.PER_PAGE_DATA) + 1;
  return totalPages;
};

//debounce

export function debounce(func, wait, immediate) {
  var timeout;
  return (...args) => {
    var context = this;
    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function getFullName(fName, lName) {
  if (lName) {
    return `${fName} ${lName}`;
  } else {
    return `${fName}`;
  }
}

// displaying data

export function gettingDisplayCount(total = 0, current = 0, page = 1) {
  if (total === 0) {
    return 'Displaying 0-0 of 0';
  }
  return `Displaying ${page * 20 - 19}-${page * 20 - 20 + current} of ${total}`;
}

export const getFilename = (name = '') => {
  if (name) {
    let data = name.split('filename=').pop();
    return data;
  }
};
