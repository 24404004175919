import { Stack } from '@mui/system';
import React from 'react';
import { typeUser } from '../../constants';

const GraphStatisticTabs = ({ userType, statisticTab, handleStatisticTab }) => {
  return (
    <div>
      {userType === typeUser?.CUSTOMER ? (
        <>
          <div className="graph_heading md-semibold fs18-28">Order Statistics </div>
          <div className="graph_subheading md-semibold fs14-22">{'(+43%) than last year'} </div>
        </>
      ) : (
        <>
          <Stack direction={'row'} spacing={6}>
            <div>
              <div
                className={
                  statisticTab === 'order'
                    ? 'graph_print_heading graph_heading md-semibold fs18-28 statistic_active'
                    : 'graph_print_heading graph_heading md-semibold fs18-28'
                }
                onClick={() => handleStatisticTab('order')}>
                Order Statistics
              </div>
              {statisticTab === 'order' && (
                <div className="graph_subheading md-semibold fs14-22">
                  {'(+43%) than last year'}{' '}
                </div>
              )}
            </div>
            <div>
              <div
                className={
                  statisticTab === 'job'
                    ? 'graph_print_heading graph_heading md-semibold fs18-28 statistic_active'
                    : 'graph_print_heading graph_heading md-semibold fs18-28'
                }
                onClick={() => handleStatisticTab('job')}>
                Job Statistics
              </div>
              {statisticTab === 'job' && (
                <div className="graph_subheading md-semibold fs14-22">
                  {'(+43%) than last year'}{' '}
                </div>
              )}
            </div>
          </Stack>
        </>
      )}
    </div>
  );
};

export default GraphStatisticTabs;
