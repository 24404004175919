import React from 'react';
import Approved from './Approved';
import { ArtworkFailed } from './artworkFailed';
import Canceled from './Canceled';
import { CrossButton } from './CrossButton';
import GenerationFailed from './generationFailed';
import GenerationProcess from './generationProcess';
import Incline from './incline';
import OptionIcon from './optionIcon';
import PendingStatus from './pendingStatus';
import Print from './Print';
import Priority from './Priority';
import Progress from './Progress';
import Shipment from './Shipment';
import ShippedOrders from './ShippedOrders';
import ShippedTooltip from './ShippedTooltip';
import TotalOrders from './TotalOrders';
import Warning from './Warning';

const svgFiles = {
  Progress: <Progress />,
  Print: <Print />,
  Approved: <Approved />,
  Incline: <Incline />,
  Option: <OptionIcon />,
  Pending: <PendingStatus />,
  ArtWorkFailed: <ArtworkFailed />,
  CrossButton: <CrossButton />,
  GenerationProcess: <GenerationProcess />,
  GenerationFailed: <GenerationFailed />,
  Priority: <Priority />,
  Canceled: <Canceled />,
  Shipment: <Shipment />,
  ShippedOrders: <ShippedOrders />,
  TotalOrders: <TotalOrders />,
  ShippedTooltip: <ShippedTooltip />,
  Warning: <Warning />,
};

export default svgFiles;
