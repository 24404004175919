import React from 'react';

const RightArrow = ({ color }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="21.1304"
        height="21.1304"
        transform="matrix(-1 0 0 -1 22 22)"
        fill="white"
        fill-opacity="0.01"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.62959 11.4339C2.62959 6.57143 6.57143 2.62959 11.4339 2.62959C16.2964 2.62959 20.2383 6.57143 20.2383 11.4339C20.2383 16.2964 16.2964 20.2383 11.4339 20.2383C6.57143 20.2383 2.62959 16.2964 2.62959 11.4339ZM9.74019 13.7444C9.78467 13.6381 9.84984 13.5416 9.93191 13.4607L11.9807 11.4295L9.93983 9.40718C9.85776 9.32625 9.7926 9.22982 9.74812 9.12349C9.70364 9.01716 9.68073 8.90305 9.68073 8.78779C9.68073 8.67254 9.70364 8.55842 9.74812 8.4521C9.7926 8.34577 9.85776 8.24934 9.93983 8.16841C10.1063 8.00388 10.3309 7.91161 10.5649 7.91161C10.799 7.91161 11.0236 8.00388 11.1901 8.16841L13.7917 10.7481C13.8817 10.8373 13.9531 10.9435 14.0019 11.0604C14.0506 11.1774 14.0757 11.3028 14.0757 11.4295C14.0757 11.5562 14.0506 11.6817 14.0019 11.7987C13.9531 11.9156 13.8817 12.0218 13.7917 12.111L11.1812 14.6995C11.0148 14.8637 10.7904 14.9558 10.5566 14.9558C10.3227 14.9558 10.0983 14.8637 9.93191 14.6995C9.84984 14.6185 9.78467 14.5221 9.74019 14.4158C9.69571 14.3095 9.67281 14.1953 9.67281 14.0801C9.67281 13.9648 9.69571 13.8507 9.74019 13.7444Z"
        fill={color ? color : '#172B4D'}
      />
    </svg>
  );
};

export default RightArrow;
