import React, { useEffect, useState } from 'react';
// import { Stack } from '@mui/system';
import { Grid } from '@mui/material';
import svgFiles from '../../assets/svgFiles';
import AmountCard from '../../components/DashboardComponent/AmountCard';
import DashboardHeader from '../../components/DashboardComponent/DashboardHeader';
import OrdersCard from '../../components/DashboardComponent/OrdersCard';
import { typeUser } from '../../constants';

import DashboardLayout from '../../layouts/DashboardLayout';
import './style.css';
import { getIndianCurrency, getIndianFormat } from '../../utils';
import DashboardGraph from './DashboardGraph';
import { connect } from 'react-redux';
import { dashboardDetails } from '../../utils/api/auth';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import { useNavigate } from 'react-router';

const Dashboard = ({ auth }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('2023');
  const [statisticTab, setStatisticTab] = useState('order'); // order, job
  const [statisticData, setStatisticData] = useState([]); // order, job
  const [labelsByMonth, setLabelsByMonth] = useState([]); // order, job

  const navigate = useNavigate();

  const [status, setStatus] = useState('total');

  useEffect(() => {
    getDashboardDetails({ month, year, category: statisticTab, status });
  }, [month, statisticTab, year, status]);

  const labelsByYear = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const getDashboardDetails = async (props) => {
    const response = await dashboardDetails({ ...props });
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      // let { count, results } = data;
      setDashboardData(data);
      if (month) {
        let labelsValues = [];
        data?.statistics &&
          data?.statistics.map((data, idx) => {
            labelsValues.push(idx + 1);
          });
        setLabelsByMonth(labelsValues);
      }
      setStatisticData(data?.statistics || []);
    } else {
      ToastNotifyError(data?.message);
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardHeader />
        <div className="dash_container">
          <Grid container className="dash_tiles" columnSpacing={3}>
            <Grid item xs={3}>
              {auth?.userType === typeUser?.CUSTOMER ? (
                <AmountCard
                  due={getIndianCurrency(dashboardData?.amount_due || 0)}
                  monthlyDue={getIndianCurrency(dashboardData?.monthly_bill || 0)}
                  onClick={() => null}
                />
              ) : (
                <OrdersCard
                  heading="Total Orders"
                  qty={getIndianFormat(dashboardData?.total_orders || 0)}
                  icon={svgFiles?.TotalOrders}
                  onClick={() =>
                    navigate('/my-order', {
                      replace: true,
                      // state: {
                      //   orderStatus: ['In Progress'],
                      // },
                    })
                  }
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <OrdersCard
                heading="Printed Orders"
                qty={getIndianFormat(dashboardData?.printed_orders || 0)}
                icon={svgFiles?.Print}
                onClick={() =>
                  navigate('/my-order', {
                    replace: true,
                    state: {
                      orderStatus:
                        auth?.userType === typeUser?.CUSTOMER ? ['In Quality Check'] : ['Printed'],
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <OrdersCard
                heading="Order in Progress"
                qty={getIndianFormat(
                  (auth?.userType === typeUser?.CUSTOMER
                    ? dashboardData?.order_in_progress
                    : dashboardData?.in_progress_orders) || 0,
                )}
                icon={svgFiles?.Progress}
                onClick={() =>
                  navigate('/my-order', {
                    replace: true,
                    state: {
                      orderStatus: ['In Progress'],
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              {auth?.userType === typeUser?.CUSTOMER ? (
                <OrdersCard
                  heading="Approved Orders"
                  qty={getIndianFormat(dashboardData?.approved_orders || 0)}
                  icon={svgFiles?.Approved}
                  onClick={() =>
                    navigate('/my-order', {
                      replace: true,
                      state: {
                        orderStatus: ['Approved'],
                      },
                    })
                  }
                />
              ) : (
                <OrdersCard
                  heading="Shipped Orders"
                  qty={getIndianFormat(dashboardData?.shipped_orders || 0)}
                  icon={svgFiles?.ShippedOrders}
                  onClick={() =>
                    navigate('/my-order', {
                      replace: true,
                      state: {
                        orderStatus: ['Shipped'],
                      },
                    })
                  }
                />
              )}
            </Grid>
          </Grid>
          <DashboardGraph
            setMonth={setMonth}
            setYear={setYear}
            statisticTab={statisticTab}
            setStatus={setStatus}
            month={month}
            year={year}
            labels={month ? labelsByMonth : labelsByYear}
            statisticData={statisticData}
            setStatisticTab={setStatisticTab}
            status={status}
          />
        </div>
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Dashboard);
