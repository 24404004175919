import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import React from 'react';
import { monthlyOptions } from '../../constants';
import './style.css';

const GraphFilter = ({ type, setType, month, setMonth, year, setYear }) => {
  const getFilterType = (event) => {
    setType(event.target.value);
    if (event.target.value === 'yearly') {
      setMonth('');
    }
    if (event.target.value === 'monthly' && !month) {
      setMonth('1');
    }
  };

  const getMonthFilter = (event) => {
    setMonth(event.target.value);
    if (event.target.value) {
      setType('monthly');
    }
  };

  const getYearFilter = (event) => {
    setYear(event.target.value);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={type}
          row
          onChange={getFilterType}>
          <FormControlLabel
            value="monthly"
            control={<Radio />}
            label={
              <FormControl
                sx={{ m: '8px 0px', minWidth: 120 }}
                size="small"
                className="graph_month_select">
                <Select
                  value={month}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={getMonthFilter}>
                  <MenuItem
                    value=""
                    selected
                    disabled
                    hidden
                    className="md-regular fs14-14"
                    sx={{ display: 'none' }}>
                    <span className="md-regular fs14-14">Monthly</span>
                  </MenuItem>
                  {monthlyOptions &&
                    monthlyOptions.map((data) => (
                      <MenuItem key={data.value} value={data?.value}>
                        <span className="md-regular fs14-14"> {data?.month}</span>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            }
          />
          <FormControlLabel
            value="yearly"
            control={<Radio />}
            label={<span className="md-regular fs14-14 graph_year_div">Yearly</span>}
          />
        </RadioGroup>
      </FormControl>
      <div className="graph_filter_line"></div>
      <FormControl
        sx={{ m: '8px 8px 8px 16px', minWidth: 90 }}
        size="small"
        className="graph_month_select">
        <Select
          value={year}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={getYearFilter}>
          <MenuItem value="2023" selected>
            <span className="md-regular fs14-14">2023</span>
          </MenuItem>
          <MenuItem value="2024">
            <span className="md-regular fs14-14">2024</span>
          </MenuItem>
          <MenuItem value="2025">
            <span className="md-regular fs14-14">2025</span>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default GraphFilter;
