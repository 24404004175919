export const ArtworkFailed = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.16602 4.99935V8.33268C9.16602 8.56879 9.24602 8.76657 9.40602 8.92602C9.56546 9.08602 9.76324 9.16602 9.99935 9.16602C10.2355 9.16602 10.4335 9.08602 10.5935 8.92602C10.753 8.76657 10.8327 8.56879 10.8327 8.33268V4.99935C10.8327 4.76324 10.753 4.56518 10.5935 4.40518C10.4335 4.24574 10.2355 4.16602 9.99935 4.16602C9.76324 4.16602 9.56546 4.24574 9.40602 4.40518C9.24602 4.56518 9.16602 4.76324 9.16602 4.99935ZM9.99935 12.4993C10.2355 12.4993 10.4335 12.4193 10.5935 12.2593C10.753 12.0999 10.8327 11.9021 10.8327 11.666C10.8327 11.4299 10.753 11.2318 10.5935 11.0718C10.4335 10.9124 10.2355 10.8327 9.99935 10.8327C9.76324 10.8327 9.56546 10.9124 9.40602 11.0718C9.24602 11.2318 9.16602 11.4299 9.16602 11.666C9.16602 11.9021 9.24602 12.0999 9.40602 12.2593C9.56546 12.4193 9.76324 12.4993 9.99935 12.4993ZM1.66602 16.3118V3.33268C1.66602 2.87435 1.82935 2.48185 2.15602 2.15518C2.48213 1.82907 2.87435 1.66602 3.33268 1.66602H16.666C17.1243 1.66602 17.5168 1.82907 17.8435 2.15518C18.1696 2.48185 18.3327 2.87435 18.3327 3.33268V13.3327C18.3327 13.791 18.1696 14.1835 17.8435 14.5102C17.5168 14.8363 17.1243 14.9993 16.666 14.9993H4.99935L3.08268 16.916C2.81879 17.1799 2.51657 17.2388 2.17602 17.0927C1.83602 16.9471 1.66602 16.6868 1.66602 16.3118ZM3.33268 14.3118L4.31185 13.3327H16.666V3.33268H3.33268V14.3118Z"
        fill="#BCBCBC"
      />
    </svg>
  );
};
