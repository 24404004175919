import { constants } from '../../constants';
import { apiEndPoints } from '../../constants/endPoints';
import { serviceCall } from '../../network';

export const orderList = async (
  page = 1,
  orderId = '',
  buying = [],
  product = [],
  statusOrder = [],
  period = '',
) => {
  const token = localStorage.getItem('token');
  let url = `${apiEndPoints?.ORDER_LISTING}?page=${page}&page_size=${
    constants?.PER_PAGE_DATA
  }&buying_agency=${buying || ''}&status=${statusOrder || ''}&id=${orderId || ''}&product_type=${
    product || ''
  }&days=${period || ''}`;

  const response = await serviceCall({
    url: url,

    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // data: dataToSend,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const printList = async (page = 1, id = '', status = '', jobId = '', period = '') => {
  const token = localStorage.getItem('token');
  let url = `${apiEndPoints?.PRINT_LISTING}?page=${page}&page_size=${
    constants?.PER_PAGE_DATA
  }&order_id=${id || ''}&status=${status || ''}&job_id=${jobId || ''}&days=${period || ''}`;
  const response = await serviceCall({
    url: url,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const printStatusUpdate = async ({ id, data }) => {
  const token = localStorage.getItem('token');
  let url = apiEndPoints?.PRINT_LISTING + '/' + id + '/update-status';
  const response = await serviceCall({
    url: url,
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const orderStatusList = async () => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints?.ORDER_STATUS_LIST,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const jobStatusList = async () => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints?.JOB_STATUS_LIST,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const addComment = async ({ id, data }) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints?.ORDER_LISTING + '/' + id + '/add-comment',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};
