import React from 'react';

const ErrorIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" fill="white" fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.51115 2.6544C8.67312 2.72151 8.82029 2.81989 8.94423 2.9439L13.0562 7.05523C13.1803 7.17919 13.2788 7.32639 13.346 7.48843C13.4132 7.65047 13.4477 7.82415 13.4477 7.99956C13.4477 8.17497 13.4132 8.34866 13.346 8.5107C13.2788 8.67273 13.1803 8.81994 13.0562 8.9439L8.94423 13.0552C8.82029 13.1792 8.67312 13.2776 8.51115 13.3447C8.34917 13.4118 8.17556 13.4464 8.00023 13.4464C7.8249 13.4464 7.65129 13.4118 7.48931 13.3447C7.32734 13.2776 7.18017 13.1792 7.05623 13.0552L2.94423 8.9439C2.82012 8.81994 2.72166 8.67273 2.65449 8.5107C2.58731 8.34866 2.55273 8.17497 2.55273 7.99956C2.55273 7.82415 2.58731 7.65047 2.65449 7.48843C2.72166 7.32639 2.82012 7.17919 2.94423 7.05523L7.05623 2.9439C7.18017 2.81989 7.32734 2.72151 7.48931 2.6544C7.65129 2.58728 7.8249 2.55273 8.00023 2.55273C8.17556 2.55273 8.34917 2.58728 8.51115 2.6544ZM7.52882 9.1373C7.65385 9.26232 7.82342 9.33256 8.00023 9.33256C8.17704 9.33256 8.34661 9.26232 8.47163 9.1373C8.59666 9.01227 8.6669 8.8427 8.6669 8.66589V5.33256C8.6669 5.15575 8.59666 4.98618 8.47163 4.86116C8.34661 4.73613 8.17704 4.66589 8.00023 4.66589C7.82342 4.66589 7.65385 4.73613 7.52882 4.86116C7.4038 4.98618 7.33356 5.15575 7.33356 5.33256V8.66589C7.33356 8.8427 7.4038 9.01227 7.52882 9.1373ZM7.52882 11.1373C7.65385 11.2623 7.82342 11.3326 8.00023 11.3326C8.17704 11.3326 8.34661 11.2623 8.47163 11.1373C8.59666 11.0123 8.6669 10.8427 8.6669 10.6659C8.6669 10.4891 8.59666 10.3195 8.47163 10.1945C8.34661 10.0695 8.17704 9.99923 8.00023 9.99923C7.82342 9.99923 7.65385 10.0695 7.52882 10.1945C7.4038 10.3195 7.33356 10.4891 7.33356 10.6659C7.33356 10.8427 7.4038 11.0123 7.52882 11.1373Z"
        fill="#DE350B"
      />
    </svg>
  );
};

export default ErrorIcon;
