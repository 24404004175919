import axios from 'axios';
import { ToastNotifyError } from '../components/Toast/ToastNotify';
import { v4 as uuidv4 } from 'uuid';

export function serviceCall(requestData) {
  const uuid = uuidv4();

  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_NETWORK_BASE_URL + '/' + requestData.url;
    let reqHeader = null;
    if (requestData.headers) {
      reqHeader = {
        ...requestData?.headers,
        'X-Request-ID': uuid,
      };
    }
    const request = {
      method: requestData.method || 'GET',
      headers: reqHeader || { 'Content-Type': 'application/json', 'X-Request-ID': uuid },
      data: requestData.data || {},
      url: url,
      responseType: requestData.responseType || '',
    };
    axios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          //Auth Permission Denied
        } else if (error?.response?.status === 401) {
          ToastNotifyError('Session Expired!');

          //Auth Failed Logout
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.href = '/';
        } else if (parseInt(error?.response?.status / 100) === 5) {
          window.location.href = `/error?transactionId=${uuid}`;

          //Sever Failed (Something Went Worng)
        }
        reject(error);
      });
  });
}
