import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
} from '@mui/material';
import React from 'react';
import './style.css';

const FilterSelect = ({
  value,
  handleChange,
  list,
  label,
  multiple = true,
  type = 'checkbox',
  disabledList = [],
}) => {
  return (
    <div className="filter-select-field">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="md-regular fs14-24"
          multiple={multiple}
          value={value}
          label={label}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: '310px',
                '& .MuiMenuItem-root': {
                  padding: 2,
                },
              },
            },
          }}
          renderValue={(selected) => (multiple ? selected.join(', ') : selected)}
          onChange={handleChange}>
          {list?.length > 0 &&
            list?.map((item, index) => {
              return (
                <MenuItem
                  value={item}
                  key={index}
                  disabled={!disabledList.includes(item)}
                  sx={{
                    '&.MuiMenuItem-root': {
                      padding: '0px 9px',
                    },
                  }}>
                  {type === 'radio' ? (
                    <Radio
                      checked={value.indexOf(item) > -1}
                      sx={{
                        color: '#909090',
                        '&.Mui-checked': {
                          color: '#0052CC',
                        },
                        transform: 'scale(0.8)',
                      }}
                    />
                  ) : (
                    <Checkbox
                      checked={value.indexOf(item) > -1}
                      sx={{
                        color: '#909090',
                        '&.Mui-checked': {
                          color: '#0052CC',
                        },
                        transform: 'scale(0.8)',
                      }}
                    />
                  )}
                  <ListItemText
                    primary={item}
                    sx={{
                      '& .MuiTypography-root ': {
                        fontFamily: 'Madani-Regular !important',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '12px',
                      },
                      color: '#7A869A',
                    }}
                    // className="color-text-grey md-regular fs12-12"
                  />
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterSelect;
