import React from 'react';

const Print = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="26" cy="26" r="26" fill="#36B37E" fillOpacity="0.16" />
      <path
        d="M33.9993 19.3333H17.9993V14H33.9993V19.3333ZM17.9993 38V32.6667H12.666V24.6667C12.666 22.4533 14.4527 20.6667 16.666 20.6667H35.3327C37.546 20.6667 39.3327 22.4533 39.3327 24.6667V28.4133C38.1593 27.7333 36.7993 27.3333 35.3327 27.3333C33.6927 27.3333 32.186 27.8267 30.9193 28.6667H20.666V35.3333H27.3327C27.3327 36.2667 27.506 37.16 27.7993 38H17.9993ZM33.9993 24.6667C33.9993 25.4 34.5993 26 35.3327 26C36.066 26 36.666 25.4 36.666 24.6667C36.666 23.9333 36.066 23.3333 35.3327 23.3333C34.5993 23.3333 33.9993 23.9333 33.9993 24.6667ZM41.3327 32.6667L39.3327 30.6667L34.666 35.3333L31.9993 32.6667L29.9993 34.6667L34.666 39.3333L41.3327 32.6667Z"
        fill="#36B37E"
      />
    </svg>
  );
};

export default Print;
