import React from 'react';

const Incline = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.591797" width="24" height="24" rx="12" fill="#36B37E" fillOpacity="0.16" />
      <path
        d="M18.591 8.66667C18.5973 8.62021 18.5973 8.57312 18.591 8.52667C18.5852 8.48753 18.574 8.44938 18.5577 8.41333C18.5401 8.38075 18.52 8.34955 18.4977 8.32C18.4723 8.27784 18.4409 8.23965 18.4044 8.20667L18.3244 8.16C18.2859 8.1313 18.2431 8.10881 18.1977 8.09333H18.0644C18.0237 8.054 17.9763 8.02237 17.9244 8H14.591C14.2228 8 13.9244 8.29848 13.9244 8.66667C13.9244 9.03486 14.2228 9.33333 14.591 9.33333H16.4777L13.811 12.4733L10.931 10.76C10.6492 10.5924 10.2874 10.6546 10.0777 10.9067L6.74435 14.9067C6.63098 15.0427 6.5764 15.2183 6.59266 15.3946C6.60892 15.571 6.69468 15.7336 6.83102 15.8467C6.95096 15.9461 7.10192 16.0003 7.25769 16C7.45597 16.0003 7.64411 15.9124 7.77102 15.76L10.7377 12.2L13.5844 13.9067C13.8631 14.072 14.2203 14.0129 14.431 13.7667L17.2577 10.4667V12C17.2577 12.3682 17.5562 12.6667 17.9244 12.6667C18.2925 12.6667 18.591 12.3682 18.591 12V8.66667Z"
        fill="#00AB55"
      />
    </svg>
  );
};

export default Incline;
