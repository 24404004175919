import { pageEndPoints } from '../constants/endPoints';
import Dashboard from '../pages/Dashboard';
import ForgotPassword from '../pages/ForgotPassword';
import Login from '../pages/Login';
import MyOrder from '../pages/MyOrder';
import Profile from '../pages/MyProfile';
import PrintJobs from '../pages/PrintJobs';
import RequestError from '../pages/RequestError';
import ResetPassword from '../pages/ResetPassword';

//routes for before authentication
export const publicRoutes = [
  {
    path: pageEndPoints?.LOGIN,
    element: <Login />,
  },
  {
    path: pageEndPoints?.FORGOT,
    element: <ForgotPassword />,
  },

  {
    path: pageEndPoints?.ERROR,
    element: <RequestError />,
  },

  // setup purpose only, remove this object when development start
  // {
  //   path: pageEndPoints?.DASHBOARD,
  //   element: <Dashboard />,
  // },
];

//routes for after authentication
export const privateRoutes = [
  {
    path: pageEndPoints?.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: pageEndPoints?.MY_ORDER,
    element: <MyOrder />,
  },
  {
    path: pageEndPoints?.PRINT_JOB,
    element: <PrintJobs />,
  },
  {
    path: pageEndPoints?.PROFILE,
    element: <Profile />,
  },
  {
    path: pageEndPoints?.ERROR,
    element: <RequestError />,
  },
];

// for custom conditional routes
export const passwordResetRoutes = [
  {
    path: pageEndPoints?.RESET,
    element: <ResetPassword />,
  },
];

// common for all auth or not
export const commonRoutes = [];
