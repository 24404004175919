import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { connect } from 'react-redux';
import svgFiles from '../../assets/svgFiles';
import message from '../../assets/svgFiles/message.svg';
import { typeUser } from '../../constants';
import ShipmentDetails from '../ShipmentDetails';
const Status = ({
  type,
  statusColorCode,
  statusList,
  comment,
  priority = false,
  auth,
  shipmentDetails,
  isJobList = true,
  isPrintJobs = false,
  disableActions = false,
}) => {
  const getColorCode = (type) => {
    let color = 'transparent';
    if (auth.userType === typeUser?.PRINT_OPERATOR && type === 12) {
      color = `${statusColorCode[7]}`;
    } else if (auth.userType === typeUser?.CUSTOMER && type === 10) {
      color = `${statusColorCode[6]}`;
    } else {
      color = `${statusColorCode[type]}`;
    }
    return color;
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', width: '70%' }}>
        <Box
          sx={{
            width: '4px',
            minWidth: '4px',
            background: getColorCode(type) || 'transparent',
            borderRadius: '15px',
            height: '22px',
          }}
        />
        <Box sx={{ paddingLeft: '6px' }}>
          {(auth.userType === typeUser?.PRINT_OPERATOR && type === 12
            ? statusList[7]
            : auth.userType === typeUser?.CUSTOMER && type === 10
            ? statusList[6]
            : statusList[type]) || 'Canceled'}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'start', width: '30%' }}>
        {comment && <img src={message} alt="message" style={{ paddingLeft: '12px' }} />}
        {isJobList && (
          <>
            {type === 4 ? (
              <Box sx={{ paddingLeft: '12px', display: 'flex', alignItems: 'center' }}>
                {svgFiles?.Pending}
              </Box>
            ) : type === 3 ? (
              <Tooltip
                placement="bottom-end"
                title={
                  <div className="md-regular fs12-12">
                    <div style={{}}>Please contact the admin</div>
                  </div>
                }
                arrow
                slotProps={{
                  tooltip: {
                    style: {
                      marginTop: '20px',
                      boxShadow: '0px 0px 14px rgba(116, 116, 116, 0.17)',
                      background: '#FFFFFF',
                      color: '#000000',
                      padding: '13px',
                    },
                  },
                  arrow: {
                    style: {
                      boxShadow: '0px 0px 14px rgba(116, 116, 116, 0.17)',
                      color: '#FFFFFF',
                    },
                  },
                }}>
                <Box sx={{ paddingLeft: '12px', display: 'flex', alignItems: 'center' }}>
                  {svgFiles?.ArtWorkFailed}
                </Box>
              </Tooltip>
            ) : null}

            {type === 8 && (
              <Tooltip
                placement="bottom-end"
                // open={true}
                // disableFocusListener
                // disableHoverListener
                // disableTouchListener
                title={<ShipmentDetails shipmentDetails={shipmentDetails} />}
                arrow
                slotProps={{
                  tooltip: {
                    style: {
                      marginTop: '20px',
                      boxShadow: '0px 0px 14px #D9D9D9',
                      background: '#FFFFFF',
                      color: '#000000',
                      padding: '16px',
                      width: '248px',
                    },
                  },
                  arrow: {
                    style: {
                      boxShadow: '0px 0px 14px #D9D9D9',
                      color: '#FFFFFF',
                    },
                  },
                }}>
                <Box sx={{ paddingLeft: '12px', display: 'flex', alignItems: 'center' }}>
                  {svgFiles?.ShippedTooltip}
                </Box>
              </Tooltip>
            )}

            {priority && auth.userType === 2 && (
              <Box sx={{ paddingLeft: '12px', display: 'flex', alignItems: 'center' }}>
                {svgFiles?.Priority}
              </Box>
            )}
          </>
        )}
        {isPrintJobs && disableActions && (
          <Tooltip
            placement="bottom-end"
            title={
              <div className="md-bold fs12-12">
                <div style={{}}>On hold</div>
              </div>
            }
            arrow
            slotProps={{
              tooltip: {
                style: {
                  marginTop: '20px',
                  boxShadow: '0px 0px 14px rgba(116, 116, 116, 0.17)',
                  background: '#FFFFFF',
                  color: '#000000',
                  padding: '13px',
                },
              },
              arrow: {
                style: {
                  boxShadow: '0px 0px 14px rgba(116, 116, 116, 0.17)',
                  color: '#FFFFFF',
                },
              },
            }}>
            <Box sx={{ paddingLeft: '12px', display: 'flex', alignItems: 'center' }}>
              {svgFiles?.Warning}
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Status);
