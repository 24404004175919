import React from 'react';

const LeftArrow = ({ color }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="21.1304" height="21.1304" fill="white" fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3704 10.5661C19.3704 15.4286 15.4286 19.3704 10.5661 19.3704C5.70356 19.3704 1.76172 15.4286 1.76172 10.5661C1.76172 5.70356 5.70356 1.76172 10.5661 1.76172C15.4286 1.76172 19.3704 5.70356 19.3704 10.5661ZM12.2598 8.25561C12.2153 8.36194 12.1502 8.45837 12.0681 8.5393L10.0193 10.5705L12.0602 12.5928C12.1422 12.6737 12.2074 12.7702 12.2519 12.8765C12.2964 12.9828 12.3193 13.0969 12.3193 13.2122C12.3193 13.3275 12.2964 13.4416 12.2519 13.5479C12.2074 13.6542 12.1422 13.7507 12.0602 13.8316C11.8937 13.9961 11.6691 14.0884 11.4351 14.0884C11.201 14.0884 10.9764 13.9961 10.8099 13.8316L8.20826 11.2519C8.11829 11.1627 8.04687 11.0565 7.99814 10.9396C7.9494 10.8226 7.92431 10.6972 7.92431 10.5705C7.92431 10.4438 7.9494 10.3183 7.99814 10.2013C8.04687 10.0844 8.11829 9.97822 8.20826 9.889L10.8188 7.30053C10.9852 7.13627 11.2096 7.04417 11.4434 7.04417C11.6773 7.04417 11.9017 7.13627 12.0681 7.30053C12.1502 7.38145 12.2153 7.47788 12.2598 7.58421C12.3043 7.69054 12.3272 7.80465 12.3272 7.91991C12.3272 8.03517 12.3043 8.14928 12.2598 8.25561Z"
        fill={color ? color : '#172B4D'}
      />
    </svg>
  );
};

export default LeftArrow;
