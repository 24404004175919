import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { ToastNotifyError } from '../Toast/ToastNotify';
// import { updateLoading } from '../../store/auth/action';
import { Document, pdfjs, Page } from 'react-pdf';
// import { viewPrintJob } from '../../utils/api/orderPlace';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
export default function ViewJob({ frontImage, jobName, displayId, artWork }) {
  // const dispatch = useDispatch();
  // const [artWork, setArtWork] = useState('');
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  // useEffect(() => {
  //   handleViewArtwork();
  // }, []);

  // const blobToBase64 = (url) => {
  //   return new Promise(async (resolve) => {
  //     // do a request to the blob uri
  //     const response = await fetch(url);
  //     // response has a method called .blob() to get the blob file
  //     const blob = await response.blob();
  //     // instantiate a file reader
  //     const fileReader = new FileReader();
  //     // read the file
  //     fileReader.readAsDataURL(blob);
  //     fileReader.onloadend = function () {
  //       resolve(fileReader.result); // Here is the base64 string
  //     };
  //   });
  // };

  // const handleViewArtwork = async () => {
  //   dispatch(updateLoading({ loading: true }));
  //   const responseGenerate = await viewPrintJob(rowId);
  //   if (parseInt(responseGenerate?.status / 100) === 2) {
  //     const file = await blobToBase64(URL.createObjectURL(responseGenerate?.data));
  //     setArtWork(file);
  //     setTimeout(() => {
  //       dispatch(updateLoading({ loading: false }));
  //     }, 1000);
  //   } else if (parseInt(responseGenerate?.status / 100) === 5) {
  //     ToastNotifyError('Something went wrong');
  //     handleClose();
  //     dispatch(updateLoading({ loading: false }));
  //   } else {
  //     ToastNotifyError(data?.message);
  //     handleClose();
  //     dispatch(updateLoading({ loading: false }));
  //   }
  // };

  return (
    <div className="preview-content">
      <div className="heading">
        <div>
          <span className={`md-light ${'jobIdHead'}`}>Job ID</span>
          <span className={`md-medium ${'jobId'}`}>{displayId}</span>
        </div>
        <div className={`md-medium ${'jobName'}`}>{jobName}</div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '497px',
        }}>
        <div style={{ display: 'flex', justifyContent: 'center', height: 'fit-content' }}>
          <div className="slide-flex-img">
            {frontImage ? (
              <div className="slider-img-inline">
                <img className="slider-img-inline" src={frontImage} height="100%" />
              </div>
            ) : (
              <div className="slider-img-inline noImgBox">
                <div className="noImgeText">No Front</div>
              </div>
            )}
            <div
              className="slider-img-inline"
              style={{
                backgroundColor: 'white',
                marginRight: '2px',
                // minHeight: '497px',
              }}>
              <Document
                options={{
                  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                  cMapPacked: true,
                }}
                file={JSON.parse(JSON.stringify(artWork))}
                className="viewPdf-main"
                loading={
                  <Box sx={{ pt: 0.5, minHeight: '400px' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </Box>
                }
                noData={() => {
                  return (
                    <Box sx={{ pt: 0.5, minHeight: '400px' }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </Box>
                  );
                }}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode="svg">
                <Page
                  pageNumber={numPages}
                  className="viewJob-pdf"
                  renderTextLayer={false}
                  loading={
                    <Box sx={{ pt: 0.5, minHeight: '400px' }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </Box>
                  }
                />
              </Document>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
