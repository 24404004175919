import { pageEndPoints } from '../constants/endPoints';

export const pageTitles = {
  DEFAULT: 'Label App',

  LOGIN: 'Login',
  DASHBOARD: 'Dashboard',
  FORGOT: 'Forgot Password',
  RESET: 'Reset Password',
  MY_ORDER: 'My Order',
  PRINT_JOB: 'Print Jobs',
  PROFILE: 'Profile',
};

export const handlePageTitles = (location) => {
  switch (location) {
    case pageEndPoints?.LOGIN:
      document.title = pageTitles?.LOGIN;
      break;
    case pageEndPoints?.DASHBOARD:
      document.title = pageTitles?.DASHBOARD;
      break;
    case pageEndPoints?.FORGOT:
      document.title = pageTitles?.FORGOT;
      break;
    case pageEndPoints?.RESET:
      document.title = pageTitles?.RESET;
      break;
    case pageEndPoints?.MY_ORDER:
      document.title = pageTitles?.MY_ORDER;
      break;
    case pageEndPoints?.PRINT_JOB:
      document.title = pageTitles?.PRINT_JOB;
      break;
    case pageEndPoints?.PROFILE:
      document.title = pageTitles?.PROFILE;
      break;
    default:
      document.title = pageTitles?.DEFAULT;
  }
};
