import * as yup from 'yup';
import { messages } from '../common/messages';
import { REGEX } from '../constants/regexConstants';
// login validate function

export const loginValidate = yup.object({
  username: yup.string().required(messages?.usernameEmpty),
  password: yup.string().required(messages?.passwordEmpty),
});

// reset password validate function

export const resetPasswordValidate = yup.object({
  password: yup
    .string()
    .required(messages?.passwordEmpty)
    .matches(REGEX?.PASSWORD_PATTERN, messages?.passwordValidation)
    .min(8, messages?.passwordValidation),
  confirmPassword: yup
    .string()
    .required(messages?.confirmPasswordEmpty)
    .oneOf([yup.ref('password'), null], messages?.confirmPasswordMismatched),
});

export const forgotPasswordValidate = yup.object({
  username: yup.string().required(messages?.usernameEmpty),
});
