import React, { useMemo, useState } from 'react';
import './style.css';
import './labelStyle.css';
import { constants } from '../../constants';
import { labelList } from '../../utils/api/orderPlace';
import Modal from '../Modal';
import { ToastNotifyError } from '../Toast/ToastNotify';
import { updateLoading } from '../../store/auth/action';
import { useDispatch } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import RightArrow from '../../assets/svgFiles/RightArrow';
import LeftArrow from '../../assets/svgFiles/LeftArrow';

const LabelSelect = ({ id, selectImage, setselectImage, setListData, listData }) => {
  const dispatch = useDispatch();
  const [selectLabel, setSelectLabel] = useState(selectImage);
  const [sliderRef, setSliderRef] = useState(null);
  // const [listData, setListData] = useState([]);
  const [previewData, setpreviewData] = useState({});
  const [open, setOpen] = useState(false);
  const labelListFun = async () => {
    dispatch(updateLoading({ loading: true }));
    const response = await labelList(id);
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      setListData(data);
      setpreviewData({});
      setTimeout(() => {
        dispatch(updateLoading({ loading: false }));
      }, 2000);
    } else {
      ToastNotifyError(data?.message);
      dispatch(updateLoading({ loading: false }));
    }
  };

  useMemo(() => labelListFun(), [id]);

  const selectFun = (selectId) => {
    setSelectLabel(selectId);
    setselectImage(selectId);
  };

  const sliderFun = () => {
    const sliderSettings = {
      slidesToShow: listData?.length < 3 ? listData?.length : 3,
      infinite: true,
      arrows: false,
    };
    return (
      <Slider ref={setSliderRef} {...sliderSettings}>
        {listData?.length > 0 &&
          listData?.map((item, index) => {
            return (
              <div key={index} style={{ width: '33.33%' }}>
                <div
                  className={selectLabel === item?.id ? 'slider-box-active' : 'slider-box-inactive'}
                  onClick={() => selectFun(item.id)}>
                  <div className="display-block">
                    <div className="slide-flex-img">
                      {item?.front_image ? (
                        <img
                          src={item?.front_image}
                          className="slider-img-inline"
                          alt="slider-img"
                          style={{ marginRight: '2px' }}
                        />
                      ) : (
                        <div
                          className="slider-img-inline noImgBox"
                          style={{
                            backgroundColor: 'white',
                            marginRight: '2px',
                            fontSize: '10px',
                          }}>
                          <div className="noImgeText">No Front</div>
                        </div>
                      )}
                      <img
                        src={item?.product_file}
                        className="slider-img-inline"
                        alt="slider-img"
                        style={{ marginLeft: '2px' }}
                      />
                    </div>
                    <div className="slider-name">{item?.name}</div>
                  </div>
                </div>
                {selectLabel === item?.id && (
                  <div
                    className="preview-button"
                    onClick={() => {
                      setOpen(true);
                      setpreviewData({
                        front_image: item?.front_image,
                        product_file: item?.product_file,
                        name: item?.name,
                      });
                    }}>
                    {constants.PREVIEW_LABEL}
                  </div>
                )}
              </div>
            );
          })}
      </Slider>
    );
  };

  return (
    <>
      {listData?.length > 0 && (
        <div className="slider-div">
          <div className="controls">
            <button
              onClick={sliderRef?.slickPrev}
              className={listData?.length <= 3 ? 'slickBut disabled' : 'slickBut'}>
              <LeftArrow color={listData?.length <= 3 && 'gray'} />
            </button>
            <button
              onClick={sliderRef?.slickNext}
              className={
                listData?.length <= 3 ? 'slickNext slickBut disabled' : 'slickNext slickBut'
              }>
              <RightArrow color={listData?.length <= 3 && 'gray'} />
            </button>
          </div>
          <div className="sliderDiv">{sliderFun()}</div>
          <Modal
            openDialog={open}
            closeDialog={() => {
              setOpen(false);
              setpreviewData({});
            }}
            noHeader={false}
            dialogCss={'label-popup'}
            heading={constants.LABEL_PREVIEW}
            dialogContent={
              <>
                <div className="slide-flex-img">
                  {previewData?.front_image ? (
                    <img
                      src={previewData?.front_image}
                      className="slider-img-inline"
                      alt="slider-img"
                      style={{ marginRight: '5px' }}
                    />
                  ) : (
                    <div
                      className="slider-img-inline noImgBox"
                      style={{
                        backgroundColor: 'white',
                        marginRight: '5px',
                        fontSize: '25px',
                      }}>
                      <div className="noImgeText">No Front</div>
                    </div>
                  )}
                  <img
                    src={previewData?.product_file}
                    className="slider-img-inline"
                    alt="slider-img"
                    style={{ marginLeft: '5px' }}
                  />
                </div>
                <div className={`md-medium ${'jobName'}`}>{previewData?.name}</div>
              </>
            }
            dialogAction={<></>}
          />
        </div>
      )}
    </>
  );
};

export default LabelSelect;
