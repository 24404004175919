import React from 'react';

const ShippedOrders = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="26" cy="26" r="26" fill="#00B8D9" fillOpacity="0.16" />
      <path
        d="M38.6673 34.0007H29.334V19.334H36.4006C37.0006 19.334 37.4673 19.734 37.6673 20.2673L40.0007 27.334V32.6673C40.0007 33.4007 39.4007 34.0007 38.6673 34.0007Z"
        fill="#00B8D9"
      />
      <path
        d="M29.3333 33.9993H13.3333C12.6 33.9993 12 33.3993 12 32.666V15.9993C12 15.266 12.6 14.666 13.3333 14.666H28C28.7333 14.666 29.3333 15.266 29.3333 15.9993V33.9993Z"
        fill="#00B8D9"
      />
      <path
        d="M34.6673 37.3327C36.5083 37.3327 38.0007 35.8403 38.0007 33.9993C38.0007 32.1584 36.5083 30.666 34.6673 30.666C32.8264 30.666 31.334 32.1584 31.334 33.9993C31.334 35.8403 32.8264 37.3327 34.6673 37.3327Z"
        fill="#00B8D9"
      />
      <path
        d="M18.6673 37.3327C20.5083 37.3327 22.0007 35.8403 22.0007 33.9993C22.0007 32.1584 20.5083 30.666 18.6673 30.666C16.8264 30.666 15.334 32.1584 15.334 33.9993C15.334 35.8403 16.8264 37.3327 18.6673 37.3327Z"
        fill="#00B8D9"
      />
      <path
        d="M34.6673 35.3327C35.4037 35.3327 36.0007 34.7357 36.0007 33.9993C36.0007 33.263 35.4037 32.666 34.6673 32.666C33.9309 32.666 33.334 33.263 33.334 33.9993C33.334 34.7357 33.9309 35.3327 34.6673 35.3327Z"
        fill="white"
      />
      <path
        d="M18.6673 35.3327C19.4037 35.3327 20.0007 34.7357 20.0007 33.9993C20.0007 33.263 19.4037 32.666 18.6673 32.666C17.9309 32.666 17.334 33.263 17.334 33.9993C17.334 34.7357 17.9309 35.3327 18.6673 35.3327Z"
        fill="white"
      />
      <path
        d="M37.3333 26.666H32.6667C32.2667 26.666 32 26.3993 32 25.9993V21.3327C32 20.9327 32.2667 20.666 32.6667 20.666H36.2C36.4667 20.666 36.7333 20.866 36.8 21.1327L37.9333 24.5993C37.9333 24.666 38 24.7327 38 24.7993V25.9993C38 26.3993 37.7333 26.666 37.3333 26.666Z"
        fill="white"
      />
      <path
        d="M24.534 19.1992L19.2673 24.4659L16.8007 21.9326L15.334 23.3992L19.2673 27.3326L26.0007 20.5992L24.534 19.1992Z"
        fill="white"
      />
    </svg>
  );
};

export default ShippedOrders;
