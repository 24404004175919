import React from 'react';
import { Avatar } from '@mui/material';
import { nameAvatar } from '../../common';
import { avatarStyle } from '../../styles/custom_style';
const UserAvatar = ({ fullName, size = 38, avatarSt = { ...avatarStyle } }) => {
  return (
    <>
      <Avatar
        sx={{
          width: size,
          height: size,
          background: '#FFAB00',
          ...avatarSt,
        }}>
        {nameAvatar(fullName)}
      </Avatar>
    </>
  );
};

export default UserAvatar;
