import { apiEndPoints } from '../../constants/endPoints';
import { serviceCall } from '../../network';

export const buyingAgencyList = async () => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.BUYING_AGENCY,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const labelList = async (id) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.LABEL_LIST + id,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const orderCreate = async (dataToSend) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.CREATE_ORDER,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: dataToSend,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const downloadArtwork = async (id) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.CREATE_ORDER + '/' + id + '/get-artwork',
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: '*/*',
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const viewPrintJob = async (id) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.PRINT_LISTING + '/' + id + '/view',
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: '*/*',
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const approveArtwork = async ({ id, status }) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.CREATE_ORDER + '/' + id + '/approve-artwork',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: status,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const generateArtwork = async (id) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.CREATE_ORDER + '/' + id + '/generate-artwork',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const cancelOrder = async ({ id, status }) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.CREATE_ORDER + '/' + id + '/cancel',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: status,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const orderShipmentDetails = async ({ id }) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints?.ORDER_SHIPMENT_STATUS(id),
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const statusUpdate = async ({ id, status }) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.CREATE_ORDER + '/' + id,
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: status,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const shippingUpdate = async ({ id, data }) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.CREATE_ORDER + '/' + id + '/action',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const downloadJob = async (id) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.PRINT_LISTING + '/' + id + '/download',
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: '*/*',
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const downloadAllJob = async (id) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: apiEndPoints.ORDER_LISTING + '/' + id + '/download-jobs',
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: '*/*',
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};
