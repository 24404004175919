import { constants } from '../../constants';
import { apiEndPoints } from '../../constants/endPoints';
import { serviceCall } from '../../network';

export const loginUtils = async (dataToSend) => {
  const response = await serviceCall({
    url: apiEndPoints.LOGIN,
    method: 'POST',
    data: dataToSend,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const userDetails = async (userId) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: `${apiEndPoints?.USER_AUTH_DATA}${userId}`,
    method: constants?.apiMethods?.GET,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const userLogout = async () => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: `${apiEndPoints?.LOGOUT}`,
    method: constants?.apiMethods?.POST,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const dashboardDetails = async ({
  year = '2023',
  month = '',
  status = 'total',
  category = 'order',
}) => {
  const token = localStorage.getItem('token');
  const response = await serviceCall({
    url: `${apiEndPoints?.DASHBOARD}?year=${year}&month=${month}&status=${status}&category=${category}`,
    method: constants?.apiMethods?.GET,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};
