import React from 'react';
import { Backdrop, Grid } from '@mui/material';
import { Box } from '@mui/system';

const PopupLayout = ({
  padding,
  backdropColor,
  backdropFilter,
  popupBackground,
  popupBorderRadius,
  popupWidth,
  isOpenPopup = false,
  handleClosePopup,
  children,
  indexingInc = 2,
  popStyle,
}) => {
  return (
    <Backdrop
      sx={{
        backgroundColor: backdropColor || 'rgba(68, 68, 68, 0.4) ',
        backdropFilter: backdropFilter || 'blur(5px)',
        zIndex: (theme) => theme.zIndex.drawer + indexingInc,
      }}
      open={isOpenPopup}
      onClick={handleClosePopup}>
      <Box onClick={(e) => e.stopPropagation()} justifyContent="center">
        <Grid
          item
          xs={12}
          p={padding || '36px 72px 30px 72px'}
          sx={{
            position: 'relative',
            background: popupBackground || '#FFFFFF',
            borderRadius: popupBorderRadius || '6px',
            width: popupWidth ? `${popupWidth} !important` : '400px !important',
            ...popStyle,
          }}>
          {children}
        </Grid>
      </Box>
    </Backdrop>
  );
};

export default PopupLayout;
