export const messages = {
  backendSlow: 'Slow Network Speed. Try Again later.',
  backendUnusual: 'Oops!! Unusual error occurred',

  //login error message
  usernameEmpty: 'This field is mandatory to proceed further',
  passwordEmpty: 'This field is mandatory to proceed further',

  //reset password message
  confirmPasswordEmpty: 'This field is mandatory to proceed further',
  confirmPasswordMismatched: 'Password doesn’t match with new password',
  passwordValidation: 'Password must be at least 8 characters',
};
