import React from 'react';

const UploadButton = () => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7657 12.5V15.8333C15.7657 16.2754 15.6075 16.6993 15.3259 17.0118C15.0443 17.3244 14.6624 17.5 14.2642 17.5H3.75348C3.35525 17.5 2.97333 17.3244 2.69174 17.0118C2.41015 16.6993 2.25195 16.2754 2.25195 15.8333V12.5"
        stroke="#0052CC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7635 6.66667L9.00968 2.5L5.25586 6.66667"
        stroke="#0052CC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00977 2.5V12.5"
        stroke="#0052CC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadButton;
