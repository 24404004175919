import { AUTH_SUCCESS, AUTH_LOGOUT, LOADING } from './actionType';

export const authSuccess = (updates) => {
  if (updates?.token) {
    localStorage.setItem('token', updates?.token);
  }
  if (updates?.userId) {
    localStorage.setItem('userId', updates?.userId);
  }
  return {
    type: AUTH_SUCCESS,
    updates,
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  return {
    type: AUTH_LOGOUT,
  };
};

export const updateLoading = (updates) => {
  return {
    type: LOADING,
    updates,
  };
};
