// start: textfield style
export const inputFieldFont = {
  fontFamily: "'Madani-Medium'",
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.02em',
};

export const inputFieldStyle = {
  ...inputFieldFont,
  color: '#333840',
  padding: '22px 10px 22px 30px',

  '&::placeholder': {
    ...inputFieldFont,
    color: '#7a869a',
    opacity: 1,
  },
};

export const textFieldStyle = {
  '& input': inputFieldStyle,
  '& fieldset': {
    border: '1px solid #dfe1e6 !important',
    borderRadius: '6px',
  },
  '&.Mui-focused fieldset': {
    border: '1px solid #0052CC !important',
  },
  '&:hover fieldset': {
    border: '1px solid #0052CC !important',
  },
  '&.Mui-error fieldset': {
    border: '1px solid #d32f2f !important',
  },
};

export const avatarStyle = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '12px',
};

export const avatarSt = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '32px',
  lineHeight: '32px',
};

export const confirmationPopStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  justifyContent: 'center',
  transform: 'translate(-50%, -50%)',
  padding: '0px 24px',
  border: 'none !important',
  outline: 'none !important',
  width: 'fit-content',
};

export const tableHeadingStyle = {
  fontFamily: "'Madani-Medium'",
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '16px',
  color: '#333840',
};

export const tableDataStyle = {
  fontFamily: "'Madani-Regular'",
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
};

export const orderMenuOptionStyle = {
  padding: '12px 20px',
  '&:hover': {
    background: '#00000047',
  },
  fontFamily: "'Madani-Medium'",
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '17px',
};
