import React from 'react';

export const CrossButton = () => {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6391 13.9942L20.6496 8.99535C21.1065 8.53844 21.1065 7.79764 20.6496 7.34073C20.1927 6.88382 19.4519 6.88382 18.995 7.34073L13.9962 12.3512L8.99734 7.34073C8.54043 6.88382 7.79963 6.88382 7.34272 7.34073C6.88581 7.79764 6.88581 8.53844 7.34272 8.99535L12.3532 13.9942L7.34272 18.993C7.12213 19.2118 6.99805 19.5096 6.99805 19.8203C6.99805 20.131 7.12213 20.4288 7.34272 20.6476C7.56151 20.8682 7.85934 20.9923 8.17003 20.9923C8.48072 20.9923 8.77855 20.8682 8.99734 20.6476L13.9962 15.6371L18.995 20.6476C19.2138 20.8682 19.5116 20.9923 19.8223 20.9923C20.133 20.9923 20.4308 20.8682 20.6496 20.6476C20.8702 20.4288 20.9943 20.131 20.9943 19.8203C20.9943 19.5096 20.8702 19.2118 20.6496 18.993L15.6391 13.9942Z"
        fill="#212B36"
      />
    </svg>
  );
};
