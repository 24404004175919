import React from 'react';

const ExpandButton = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_223_3251)">
        <rect x="2" y="2" width="28" height="28" rx="3" fill="#EFF0F2" />
      </g>
      <path
        d="M13.5 11L18.5 16L13.5 21"
        stroke="#333840"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_223_3251"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.3 0 0 0 0 0.3 0 0 0 0 0.3 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_223_3251" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_223_3251"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ExpandButton;
