import React from 'react';

const GenerationProcess = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_740_3602)">
        <path
          d="M33.4906 26.2805C33.4203 26.0311 33.2559 25.8187 33.032 25.6881C32.8082 25.5575 32.5424 25.5189 32.2906 25.5805L29.8006 26.2505C31.1632 24.242 31.9893 21.9184 32.2006 19.5005C32.4049 17.2384 32.0741 14.96 31.235 12.8493C30.396 10.7387 29.0722 8.85499 27.3706 7.35051C27.1739 7.18782 26.9224 7.10651 26.6677 7.12325C26.413 7.13998 26.1743 7.25349 26.0006 7.44051C25.8258 7.63953 25.7371 7.89977 25.754 8.16412C25.7709 8.42847 25.8919 8.67533 26.0906 8.85051C27.5569 10.1447 28.6976 11.7662 29.4203 13.5835C30.1431 15.4008 30.4275 17.3628 30.2506 19.3105C30.0793 21.362 29.3913 23.3368 28.2506 25.0505L28.0006 22.5405C28.0195 22.3928 28.0051 22.2427 27.9585 22.1013C27.912 21.9598 27.8344 21.8306 27.7315 21.7229C27.6286 21.6153 27.503 21.532 27.3638 21.4791C27.2246 21.4262 27.0753 21.4051 26.9269 21.4172C26.7785 21.4294 26.6346 21.4746 26.5059 21.5495C26.3772 21.6243 26.2668 21.727 26.1828 21.85C26.0989 21.973 26.0434 22.1131 26.0205 22.2603C25.9977 22.4074 26.0079 22.5578 26.0506 22.7005L26.5506 29.1405L32.8006 27.4805C33.0482 27.4081 33.2582 27.2428 33.3868 27.0192C33.5154 26.7956 33.5526 26.5309 33.4906 26.2805Z"
          fill="#FFD748"
        />
        <path
          d="M4.31051 17.0801C4.44342 17.1656 4.59375 17.2202 4.75051 17.2401C5.01149 17.2744 5.27545 17.2043 5.48512 17.0452C5.69479 16.8861 5.83326 16.6507 5.87051 16.3901C6.28371 13.3393 7.83417 10.5571 10.2114 8.60081C12.5886 6.64451 15.6172 5.65845 18.6905 5.84012L16.4505 7.37012C16.292 7.48952 16.1726 7.65337 16.1075 7.84082C16.0425 8.02828 16.0346 8.23086 16.085 8.42279C16.1354 8.61472 16.2418 8.78731 16.3905 8.91861C16.5393 9.04991 16.7238 9.13398 16.9205 9.16012C17.1458 9.18071 17.3714 9.12431 17.5605 9.00012L22.8905 5.34012L18.3305 0.760116C18.2494 0.635777 18.1416 0.531068 18.0149 0.453591C17.8882 0.376113 17.7459 0.32781 17.5983 0.31219C17.4506 0.29657 17.3013 0.314024 17.1613 0.363285C17.0212 0.412547 16.8939 0.492379 16.7885 0.596986C16.6832 0.701593 16.6024 0.82835 16.5521 0.968054C16.5019 1.10776 16.4833 1.2569 16.4979 1.40466C16.5124 1.55242 16.5597 1.69509 16.6363 1.8223C16.7128 1.94951 16.8168 2.05808 16.9405 2.14012L18.6405 3.84012C15.0913 3.67041 11.6075 4.83748 8.87679 7.11092C6.14606 9.38437 4.36689 12.599 3.89051 16.1201C3.86487 16.3033 3.89055 16.4901 3.9647 16.6595C4.03885 16.829 4.15856 16.9746 4.31051 17.0801Z"
          fill="#FFD748"
        />
        <path
          d="M21.7297 29.93C20.1681 30.4288 18.5209 30.6024 16.8897 30.44C14.903 30.2453 12.9936 29.57 11.3263 28.4723C9.65901 27.3747 8.28388 25.8878 7.31967 24.14L9.80967 25.07C10.0392 25.1098 10.2754 25.0682 10.4776 24.9525C10.6797 24.8367 10.8351 24.654 10.9169 24.4358C10.9987 24.2177 11.0018 23.9779 10.9256 23.7577C10.8494 23.5376 10.6988 23.3509 10.4997 23.23L5.90967 21.53L4.43967 21L3.32967 27.35C3.29119 27.6037 3.35174 27.8626 3.49879 28.0729C3.64584 28.2833 3.86814 28.429 4.11967 28.48H4.28967C4.52503 28.4843 4.75435 28.4054 4.93727 28.2572C5.12019 28.1091 5.24498 27.9011 5.28967 27.67L5.70967 25.27C6.84069 27.2513 8.42934 28.9332 10.3429 30.1753C12.2565 31.4174 14.4395 32.1836 16.7097 32.41C18.6076 32.5981 20.524 32.3939 22.3397 31.81C22.5709 31.7143 22.7578 31.5353 22.8635 31.3085C22.9692 31.0817 22.986 30.8236 22.9107 30.5849C22.8353 30.3463 22.6733 30.1446 22.4565 30.0197C22.2397 29.8947 21.9839 29.8556 21.7397 29.91L21.7297 29.93Z"
          fill="#FFD748"
        />
        <path
          d="M22 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V22C13 22.2652 13.1054 22.5196 13.2929 22.7071C13.4804 22.8946 13.7348 23 14 23H22C22.2652 23 22.5196 22.8946 22.7071 22.7071C22.8946 22.5196 23 22.2652 23 22V14C23 13.7348 22.8946 13.4804 22.7071 13.2929C22.5196 13.1054 22.2652 13 22 13ZM21 21H15V15H21V21Z"
          fill="#FFD748"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_3602">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GenerationProcess;
