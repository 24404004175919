import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';
import './style.css';
import { Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import GraphFilter from './GraphFilter';
import { connect } from 'react-redux';
import { customerGraphTabs, printGraphTabs, printJobsGraphTabs, typeUser } from '../../constants';
import GraphStatisticTabs from './GraphStatisticTabs';
import { useState } from 'react';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// tabs

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box className="md-medium fs16-16" sx={{ p: 3 }}>
          <Typography className="md-medium fs16-16">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

//tabs

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function () {
          return 'Total Orders';
        },
      },
      backgroundColor: 'rgba(217, 217, 217, 0.5)',
      backdropFilter: 'blur(20px)',
      displayColors: false,
      titleColor: '#1B1B1B',
      titleFont: {
        family: 'Madani-Medium',
        weight: '400',
        size: '12px',
      },
      bodyColor: '#1B1B1B',
      bodyFont: {
        family: 'Madani-Medium',
        weight: '400',
        size: '16px',
      },
      borderColor: '#D9D9D9',
      borderWidth: 1,
      usePointStyle: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        font: {
          family: 'Madani-Regular',
        },
      },
    },

    y: {
      border: {
        dash: [2, 4],
        display: false,
      },
      ticks: {
        font: {
          family: 'Madani-Regular',
        },
        precision: 0,
      },
    },
  },
  animations: {
    radius: {
      duration: 400,
      easing: 'linear',
      loop: (context) => context.active,
    },
  },
};

// const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
// const labels = [
//   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
//   28, 29, 30, 31,
// ];

const tabStyle = {
  fontSize: '16px',
  lineHeight: '20px',
  fontFamily: 'Madani-Medium',
  fontStyle: 'normal',
  fontWeight: 400,
  color: '#AEAEAE',
  textTransform: 'none',
  padding: '8px 60px 8px 0px',
  '&.Mui-selected': {
    color: '#0052CC',
  },
  '&:hover': {
    color: '#0052CC',
    opacity: 1,
  },
};

const DashboardGraph = ({
  auth,
  month,
  setMonth,
  year,
  setYear,
  statisticTab,
  setStatisticTab,
  labels,
  statisticData,
  setStatus,
}) => {
  const data = {
    labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: statisticData,
        backgroundColor: '#4C9AFF',
        barThickness: 7,
        borderRadius: [4, 4, 4],
        borderWidth: 1,
      },
    ],
  };
  const [value, setValue] = useState(0);

  // filters
  const [type, setType] = useState('yearly'); //yearly, monthly(jan-dec)

  const getTabsCLick = (props) => {
    setStatus(props.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStatisticTab = (val) => {
    setStatisticTab(val);
    setValue(0);
    setYear('2023');
    setType('yearly');
    setMonth('');
  };
  return (
    <div className="graph_container">
      <div className="graph_header_div">
        <GraphStatisticTabs
          userType={auth?.userType}
          statisticTab={statisticTab}
          handleStatisticTab={handleStatisticTab}
        />
        <div className="graph_filter_div">
          <GraphFilter
            type={type}
            setType={setType}
            month={month}
            setMonth={setMonth}
            year={year}
            setYear={setYear}
          />
        </div>
      </div>
      <div className="graph_tabs">
        {auth?.userType === typeUser?.CUSTOMER ? (
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              disableTransition: true,
              style: {
                backgroundColor: '#0052CC',
                height: '2.5px',
                width: '52px',
              },
            }}>
            {customerGraphTabs &&
              customerGraphTabs.map((val) => (
                <Tab
                  disableRipple
                  sx={tabStyle}
                  key={val?.id}
                  label={val[val?.id]}
                  {...a11yProps(val?.id)}
                  onClick={() => getTabsCLick(val)}
                />
              ))}
          </Tabs>
        ) : auth?.userType === typeUser?.PRINT_OPERATOR && statisticTab === 'order' ? (
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              disableTransition: true,
              style: {
                backgroundColor: '#0052CC',
                height: '2.5px',
                width: '52px',
              },
            }}>
            {printGraphTabs &&
              printGraphTabs.map((val) => (
                <Tab
                  disableRipple
                  sx={tabStyle}
                  key={val?.id}
                  label={val[val?.id]}
                  {...a11yProps(val?.id)}
                  onClick={() => getTabsCLick(val)}
                />
              ))}
          </Tabs>
        ) : auth?.userType === typeUser?.PRINT_OPERATOR && statisticTab === 'job' ? (
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              disableTransition: true,
              style: {
                backgroundColor: '#0052CC',
                height: '2.5px',
                width: '52px',
              },
            }}>
            {printJobsGraphTabs &&
              printJobsGraphTabs.map((val) => (
                <Tab
                  disableRipple
                  sx={tabStyle}
                  key={val?.id}
                  label={val[val?.id]}
                  {...a11yProps(val?.id)}
                  onClick={() => getTabsCLick(val)}
                />
              ))}
          </Tabs>
        ) : null}
      </div>
      <div className="graph_mainDiv">
        <div className="graph_outerDiv md-medium">
          <Bar options={options} data={data} height="100" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(DashboardGraph);
