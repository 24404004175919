import { REGEX } from '../constants/regexConstants';

export const nameAvatar = (fullName) => {
  let avatarCharacter = '';
  let splittedName;
  if (fullName) {
    splittedName = fullName.toUpperCase().split(' ');
    if (splittedName.length === 1) {
      avatarCharacter = splittedName[0][0];
    } else if (splittedName.length > 1) {
      avatarCharacter = `${splittedName[0][0]}${splittedName[splittedName.length - 1][0]}`;
    }
  }
  return avatarCharacter;
};

export const hideEmail = function (email) {
  return email.replace(REGEX?.HIDE_EMAIL, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += '*';
    }
    return gp2;
  });
};
