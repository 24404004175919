import React from 'react';

const EmailIcon = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="44" cy="44" r="44" fill="#F4F9FF" />
      <circle cx="43.9991" cy="44.0001" r="35.4444" fill="#E1EBFC" />
      <path
        d="M30.5189 56.5919C29.6227 56.5919 28.8557 56.273 28.218 55.6353C27.5792 54.9965 27.2598 54.229 27.2598 53.3327V33.7777C27.2598 32.8815 27.5792 32.1145 28.218 31.4767C28.8557 30.838 29.6227 30.5186 30.5189 30.5186H56.5923C57.4885 30.5186 58.2561 30.838 58.8949 31.4767C59.5326 32.1145 59.8514 32.8815 59.8514 33.7777V53.3327C59.8514 54.229 59.5326 54.9965 58.8949 55.6353C58.2561 56.273 57.4885 56.5919 56.5923 56.5919H30.5189ZM43.5556 44.8996C43.6914 44.8996 43.8337 44.879 43.9825 44.8377C44.1325 44.7975 44.2753 44.7367 44.4111 44.6552L55.9404 37.4443C56.1577 37.3085 56.3207 37.139 56.4293 36.9359C56.5379 36.7316 56.5923 36.5073 56.5923 36.2628C56.5923 35.7196 56.3614 35.3122 55.8997 35.0406C55.438 34.7691 54.9627 34.7826 54.4738 35.0814L43.5556 41.9256L32.6374 35.0814C32.1485 34.7826 31.6732 34.7756 31.2115 35.0602C30.7498 35.3459 30.5189 35.7468 30.5189 36.2628C30.5189 36.5344 30.5733 36.7718 30.6819 36.975C30.7905 37.1792 30.9535 37.3356 31.1708 37.4443L42.7001 44.6552C42.8359 44.7367 42.9787 44.7975 43.1286 44.8377C43.2775 44.879 43.4198 44.8996 43.5556 44.8996Z"
        fill="#0052CC"
      />
    </svg>
  );
};

export default EmailIcon;
