import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';

import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import {
  ApprovalPending,
  Approved,
  InProgress,
  Printed,
  Shipped,
  QualityChecked,
  ApprovalPendingActive,
  ApprovedActive,
  InProgressActive,
  PrintedActive,
  QualityCheckedActive,
  ShippedActive,
} from '../../assets/svgFiles/orderStatus';
import moment from 'moment';

const OrderDetailsStepper = ({ step: activeStep = 0, shipmentDetailsData = [], statusList }) => {
  // api call on the basis of order Id
  const ColorlibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 30,
    },

    [`& .${stepConnectorClasses.line}`]: {
      height: 0,
      border: '2px dashed #DDDDDD',

      borderRadius: 1,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        height: 4,
        background: '#4E8CDB',
        border: 0,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        height: 4,
        background: '#4E8CDB',
        border: 0,
      },
    },
  }));

  const CustomStepIconRoot = styled('div')(() => ({
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }));

  function CustomStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <ApprovalPendingActive />,
      2: <ApprovedActive />,
      3: <InProgressActive />,
      4: <PrintedActive />,
      5: <QualityCheckedActive />,
      6: <ShippedActive />,
    };

    const iconsInactive = {
      1: <ApprovalPending />,
      2: <Approved />,
      3: <InProgress />,
      4: <Printed />,
      5: <QualityChecked />,
      6: <Shipped />,
    };

    return (
      <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
        {active || completed ? icons[String(props.icon)] : iconsInactive[String(props.icon)]}
      </CustomStepIconRoot>
    );
  }

  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
      {shipmentDetailsData.map((data, idx) => (
        <Step key={data?.id + idx}>
          <StepLabel
            StepIconComponent={CustomStepIcon}
            slotProps={{
              label: {
                style: {
                  marginTop: '-24px',
                  textTransform: 'uppercase',
                },
              },
            }}>
            <div
              className="md-regular fs12-12"
              style={{ color: idx <= Number(activeStep) ? '#333840' : '#B3B3B3' }}>
              {statusList[data?.final_status_id]}
            </div>
            <div
              className="md-regular"
              style={{ color: '#B3B3B3', marginTop: '6px', fontStyle: 'italic' }}>
              {data?.created_at && moment(data?.created_at).format('DD/MM/YYYY hh:mm A')}
            </div>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default OrderDetailsStepper;
