import { Stack } from '@mui/system';
import React from 'react';
import svgFiles from '../../assets/svgFiles';
import PopupLayout from '../../layouts/PopupLayout';
import ButtonCustom from '../ButtonCustom';

const CanceledOrder = ({ open, handleClosePopup }) => {
  return (
    <PopupLayout
      isOpenPopup={open}
      handleClosePopup={handleClosePopup}
      popupWidth={'467px'}
      padding={'32px 50px'}>
      <Stack justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <div>{svgFiles?.Canceled}</div>
        <div
          className="md-semibold fs14-22"
          style={{ textAlign: 'center', marginBottom: '24px', marginTop: '16px' }}>
          Order Canceled <br />
          <span className="md-regular">No details to display!</span>
        </div>
        <ButtonCustom
          width="fit-content"
          padding="18px 48px"
          onClick={handleClosePopup}
          textStyle={{
            letterSpacing: ' 0.04em',
          }}>
          Close
        </ButtonCustom>
      </Stack>
    </PopupLayout>
  );
};

export default CanceledOrder;
