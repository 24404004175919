import { createTheme } from '@mui/material';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // for responsive purpose only
      sm: 600, // for responsive purpose only
      bmd: 768, // bootstrap medium-screens
      md: 960, // small-medium-screens
      lg: 1200, // large screens
      xl: 1536, //extra large screens
    },
  },
  palette: {
    primary: {
      main: '#0052CC', // default palette color
      light: '#FFFFFF', //
      dark: '#172B4D',
    },
    secondary: {
      main: '#FF5630', // default palette color
      light: '#FFAB00', //
      dark: '#36B37E',
    },
    error: {
      main: '#DE350B',
    },
  },
});
