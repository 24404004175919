import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import { addSetting } from '../../store/setting/action';
import './style.css';

const DashboardLayout = ({ children, setting, background = '#F4F4F4' }) => {
  const [isExpand, setIsExpand] = useState(setting?.sidebarExpand);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      addSetting({
        sidebarExpand: isExpand,
      }),
    );
  }, [isExpand]);

  return (
    <div className="outer_container" style={{ background: background || '#F4F4F4' }}>
      <Sidebar setIsExpand={setIsExpand} isExpand={isExpand} />
      <Navbar />
      <div
        className={
          isExpand ? 'content_expand content_wrapper' : 'content_collapse content_wrapper'
        }>
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    setting: state.setting,
  };
};

export default connect(mapStateToProps)(DashboardLayout);
