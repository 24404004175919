import moment from 'moment';
import React from 'react';
import { printOperatorStatus } from '../../constants';
import './style.css';

const IconStepper = ({ shipmentDetailsData = [], step = 1, icons, iconsInactive }) => {
  return (
    <div className="c-stepper-wrapper">
      {shipmentDetailsData &&
        shipmentDetailsData.map((data, idx) => (
          <div
            key={data?.final_status_id}
            className={` c-stepper-item ${idx < step && 'c-completed '} ${
              idx < step - 1 && 'line-progress'
            }`}>
            <div className="c-step-counter">
              {idx < step ? icons[data?.final_status_id] : iconsInactive[data?.final_status_id]}
            </div>
            <div
              className="md-regular fs12-12"
              style={{
                color: idx < step ? '#333840' : '#B3B3B3',
                marginTop: '-24px',
                textTransform: 'uppercase',
              }}>
              {printOperatorStatus[data?.final_status_id]}
            </div>
            <div
              className="md-regular fs12-12"
              style={{ color: '#B3B3B3', marginTop: '6px', fontStyle: 'italic' }}>
              {idx < step && moment(data?.created_at).format('DD/MM/YYYY hh:mm A')}
            </div>
          </div>
        ))}
    </div>
  );
};

export default IconStepper;
