import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './style.css';

const Modal = ({
  openDialog,
  closeDialog,
  noHeader,
  dialogCss,
  heading,
  dialogContent,
  dialogAction,
}) => {
  const [open, setOpen] = useState(openDialog);
  useEffect(() => {
    if (openDialog) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openDialog]);
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    closeDialog();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        disableBackdropClick="true"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        className={dialogCss}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {!noHeader && (
          <DialogTitle id="alert-dialog-title">
            <div className={`md-semibold ${'dialog-title'}`}>{heading}</div>
            <div onClick={handleClose} style={{ cursor: 'pointer' }}>
              <CloseIcon style={{ color: '#212B36', cursor: 'pointer' }} onClick={handleClose} />
            </div>
          </DialogTitle>
        )}
        <DialogContent>{dialogContent}</DialogContent>
        {dialogAction}
      </Dialog>
    </div>
  );
};

export default Modal;
