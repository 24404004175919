import React, { useEffect, useState } from 'react';
import ProfileHeader from '../../components/ProfileHeader';
import DashboardLayout from '../../layouts/DashboardLayout';
import UserAvatar from '../../components/UserAvatar';
import './style.css';
import { avatarSt } from '../../styles/custom_style';
import { Grid } from '@mui/material';
import { userDetails } from '../../utils/api/auth';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import { connect } from 'react-redux';
import { getFullName } from '../../utils';

const ProfileDetails = ({ heading = 'NA', details = 'NA' }) => {
  return (
    <Grid item xs={4}>
      <div className="md-oblique fs12-12 info_subheading">{heading}</div>
      <div className="info_data fs16-23 md-regular">{details}</div>
    </Grid>
  );
};

const Profile = ({ auth }) => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const response = await userDetails(auth?.userId);
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      // let { count, results } = data;
      setUserData(data);
    } else {
      ToastNotifyError(data?.message);
    }
  };
  return (
    <>
      <DashboardLayout background={'#FFFFFF'}>
        <ProfileHeader />
        <div className="avatar_container">
          <div>
            <UserAvatar
              fullName={getFullName(userData?.first_name, userData?.last_name)}
              size={90}
              avatarSt={avatarSt}
            />
          </div>
          <div className="avatar_details">
            <div className="avatar_name md-medium fs36-36">
              {getFullName(userData?.first_name, userData?.last_name)}
            </div>
            <div className="avatar_username md-regular fs16-22">{userData?.username || ''}</div>
          </div>
        </div>

        <div className="user_details_container">
          <div className="info_heading fs20-22 md-regular">Contact Info</div>
          <Grid container mt={0.1} rowSpacing={4} columnSpacing={2}>
            <ProfileDetails heading="Email" details={userData?.email || 'NA'} />
            <ProfileDetails heading="Primary Contact Number" details={userData?.phone_no || 'NA'} />
            <ProfileDetails
              heading="Secondary Contact Number"
              details={userData?.alternate_phone_no || 'NA'}
            />
            <ProfileDetails heading="Company Name" details={userData?.customer || 'NA'} />
            <ProfileDetails
              heading="Address"
              details={
                userData?.address_line_2
                  ? `${userData?.address_line_1}, ${userData?.address_line_2}`
                  : userData?.address_line_1
              }
            />
          </Grid>
        </div>
        {/* <div>Webpage in development sprint-1</div> */}
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Profile);
