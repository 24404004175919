import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { constants, status } from '../../constants';
import { updateLoading } from '../../store/auth/action';
import { approveArtwork } from '../../utils/api/orderPlace';
import Modal from '../Modal';
import { ToastNotifyError } from '../Toast/ToastNotify';
import { useNavigate } from 'react-router';
import './labelStyle.css';
import { pageEndPoints } from '../../constants/endPoints';
import { Document, pdfjs, Page } from 'react-pdf';
import downloadIcon from '../../assets/svgFiles/download.svg';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import QuestionMark from '../../assets/svgFiles/questionMark';
import { getFilename } from '../../utils';
import PopupLayout from '../../layouts/PopupLayout';
import { Stack } from '@mui/system';
import ButtonCustom from '../ButtonCustom';
const ArtWork = ({
  openDialog,
  closeDialog,
  artWorkRes,
  orderDetail,
  orderListing,
  downloadDetail,
  // shipDetail,
  // orderStatus = 1,
  responseGenerate,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { order_name } = orderDetail;
  const [approveArt, setApproveArt] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [getData, setGetData] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    setTimeout(() => {
      setGetData(JSON.stringify(artWorkRes));
    }, 2000);
  }, []);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);
  const downloadFun = async () => {
    let fileName =
      getFilename(responseGenerate?.headers['content-disposition']) || orderDetail?.order_name;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE variant
      window.navigator.msSaveOrOpenBlob(
        new Blob([downloadDetail], {
          type: 'application/pdf',
          encoding: 'UTF-8',
        }),
        fileName,
      );
    } else {
      const url = window.URL.createObjectURL(
        new Blob([downloadDetail], {
          type: 'application/pdf',
          encoding: 'UTF-8',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const approveFun = async () => {
    dispatch(updateLoading({ loading: true }));
    const response = await approveArtwork({ id: orderDetail?.id, status: { status: 5 } });
    dispatch(updateLoading({ loading: false }));
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      navigate(pageEndPoints?.MY_ORDER, {
        replace: true,
      });
      closeDialog();
      if (orderListing) {
        orderListing();
      }
    } else if (parseInt(status / 100) === 5) {
      ToastNotifyError('Something went wrong');
    } else {
      ToastNotifyError(data?.message);
    }
  };

  const closeArtWork = () => {
    setApproveArt(false);
    if (orderListing) {
      orderListing();
    }
  };

  const laterFun = () => {
    navigate(pageEndPoints?.MY_ORDER, {
      replace: true,
    });
    closeDialog();
    if (orderListing) {
      orderListing();
    }
  };

  return (
    <div>
      <Modal
        openDialog={openDialog}
        closeDialog={closeDialog}
        noHeader={false}
        dialogCss={'preview-popup'}
        heading={constants.PREVIEW_ORDER}
        dialogContent={
          <div style={{ margin: '20px', marginBottom: '0px' }}>
            <Document
              options={{
                cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                cMapPacked: true,
              }}
              loading={
                <Box sx={{ pt: 0.5 }} className="skeletonBoxArt">
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Box>
              }
              file={JSON.parse(getData)}
              noData={() => {
                return (
                  <Box sx={{ pt: 0.5 }} className="skeletonBoxArt">
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </Box>
                );
              }}
              onLoadSuccess={onDocumentLoadSuccess}
              renderMode="cansvs">
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page, ind) => {
                  return (
                    <div
                      key={ind}
                      id={`viewer-page-${ind + 1}`}
                      style={{ marginBottom: '7px' }}
                      className="d-flex justify-content-center align-items-center">
                      <Page
                        key={`page_${ind}`}
                        pageNumber={ind + 1}
                        scale={2}
                        rotate={0}
                        width={448}
                        loading={
                          <Box sx={{ pt: 0.5 }} className="skeletonBoxArt">
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                          </Box>
                        }
                        style={{ margin: 'auto' }}
                        renderAnnotationLayer={false}
                      />
                    </div>
                  );
                })}
            </Document>
          </div>
        }
        dialogAction={
          <DialogActions className="preview-action">
            <div>
              <Button className={`md-medium ${'dialog-button-inactive'}`} onClick={downloadFun}>
                <img src={downloadIcon} style={{ marginRight: '10px' }} />
                {constants?.DOWNLOAD_ARTWORK}
              </Button>
            </div>
            <div>
              {orderDetail?.status < status?.APPROVED && (
                <>
                  <Button
                    className={`md-medium ${'dialog-button-inactive'}`}
                    style={{ marginRight: '10px' }}
                    onClick={laterFun}>
                    {constants?.LATER}
                  </Button>
                  <Button
                    className={`md-medium ${'dialog-button'}`}
                    onClick={() => setApproveArt(true)}>
                    {constants?.APPROVE}
                  </Button>
                </>
              )}
            </div>
          </DialogActions>
        }
      />

      <PopupLayout
        isOpenPopup={approveArt}
        handleClosePopup={closeArtWork}
        popupWidth={'467px'}
        padding={'32px 50px'}>
        <Stack justifyContent={'center'} alignItems={'center'} direction={'column'}>
          <div>{<QuestionMark />}</div>
          <div
            className="md-semibold fs14-22"
            style={{ textAlign: 'center', marginBottom: '24px', marginTop: '8px' }}>
            Approve Order <br />
            <div className="md-regular" style={{ marginTop: '8px' }}>
              {constants.APPROVE_CONTENT}
            </div>
          </div>
          <div>
            <ButtonCustom
              width="fit-content"
              padding="18px 48px"
              onClick={closeArtWork}
              // className="cust_btn_hover"
              background="#FFFFFF"
              border="1px solid #0052CC"
              color="#0052CC">
              {constants?.CANCELED}
            </ButtonCustom>
            <ButtonCustom
              width="fit-content"
              padding="18px 48px"
              margin="0px 0px 0px 42px"
              onClick={approveFun}>
              {constants?.APPROVE}
            </ButtonCustom>
          </div>
        </Stack>
      </PopupLayout>
      <Modal
        // openDialog={false}
        // closeDialog={closeArtWork}
        noHeader={true}
        dialogCss={''}
        heading={''}
        dialogContent={
          <div className="progressError-popup">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <div>
                <QuestionMark />
              </div>
              <div className="progress-text">{constants.APPROVE_CONTENT}</div>
              <div>
                <Button
                  className={`md-medium ${'dialog-button-inactive'}`}
                  style={{ marginRight: '10px' }}
                  onClick={closeArtWork}>
                  {constants?.CANCELED}
                </Button>
                <Button className={`md-medium ${'dialog-button'}`} onClick={approveFun}>
                  {constants?.APPROVE}
                </Button>
              </div>
            </div>
          </div>
        }
        dialogAction={''}
      />
    </div>
  );
};

export default ArtWork;
