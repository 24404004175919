import React from 'react';
import { Stack } from '@mui/system';
import './style.css';
import { useNavigate } from 'react-router';
// import { NavLink } from 'react-router-dom';

const NavigationCard = ({ icon, heading, active, link }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        !active ? navigate(link, { replace: true }) : null;
      }}>
      <Stack
        direction={'column'}
        spacing={1.25}
        justifyContent={'center'}
        alignItems={'center'}
        p={1.5}
        className={!active && 'c-pointer nav_hover'}>
        <div>{icon}</div>
        <div
          className={
            active ? 'md-medium fs12-12 color_active' : 'md-regular fs12-12 color_inactive'
          }>
          {heading}
        </div>
      </Stack>
    </div>
  );
};

export default NavigationCard;
