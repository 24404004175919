import React from 'react';

const WelcomeHi = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.36274 18.4393C6.34625 18.4393 6.32826 18.4363 6.31028 18.4318C4.50544 17.8816 2.55071 15.9089 2.12648 14.2105C2.10249 14.1146 2.16096 14.0186 2.2554 13.9946C2.35133 13.9707 2.44727 14.0291 2.47126 14.1236C2.8625 15.69 4.74229 17.5803 6.41371 18.0915C6.50815 18.12 6.56062 18.2189 6.53213 18.3134C6.50965 18.3898 6.43919 18.4393 6.36274 18.4393Z"
        fill="#404040"
      />
      <path
        d="M7.22267 20.6779C7.20918 20.6779 7.19569 20.6764 7.1822 20.6734C5.68916 20.3256 3.95328 19.2103 2.42127 17.6124C0.988198 16.1193 0.0602973 14.5303 0.00033607 13.466C-0.00566006 13.3686 0.0692915 13.2832 0.168228 13.2787C0.265665 13.2727 0.351109 13.3476 0.355607 13.4465C0.409572 14.4149 1.32098 15.9529 2.67761 17.3665C4.16315 18.9165 5.83457 19.9943 7.26314 20.3271C7.35908 20.3496 7.41904 20.4455 7.39656 20.5415C7.37707 20.6224 7.30362 20.6779 7.22267 20.6779Z"
        fill="#404040"
      />
      <path
        d="M14.8743 5.10121C14.7948 5.10121 14.7229 5.04875 14.7019 4.9693C14.4515 4.01891 13.3138 2.65479 12.2779 2.38797C12.1835 2.36398 12.125 2.26654 12.1505 2.17061C12.176 2.07467 12.2719 2.0177 12.3679 2.04319C13.5341 2.34449 14.7663 3.81504 15.0466 4.87936C15.0721 4.97379 15.0152 5.07123 14.9192 5.09671C14.9042 5.09971 14.8892 5.10121 14.8743 5.10121Z"
        fill="#404040"
      />
      <path
        d="M17.1193 5.41712C17.0398 5.41712 16.9679 5.36316 16.9469 5.28371C16.2798 2.6574 13.7854 0.608228 12.3614 0.353393C12.2639 0.335404 12.201 0.243963 12.2175 0.146526C12.2355 0.0490893 12.3269 -0.01387 12.4243 0.00261935C13.9758 0.281439 16.5781 2.39358 17.2917 5.19527C17.3157 5.2912 17.2587 5.38714 17.1628 5.41113C17.1478 5.41562 17.1343 5.41712 17.1193 5.41712Z"
        fill="#404040"
      />
      <path
        d="M12.6887 10.0844C12.9751 10.4007 13.2329 10.8159 13.1235 10.9254C13.014 11.0348 12.6783 10.8369 12.4219 10.5791C12.1656 10.3227 11.5525 13.051 11.4925 13.1889C11.4475 13.2923 10.734 14.1108 10.3608 14.8888C10.2363 15.1481 10.2543 15.9306 10.2079 15.9771C10.0205 16.1645 9.78213 16.145 9.26796 15.6908C8.75379 15.2366 8.48696 17.9423 9.10007 18.3695C10.1479 19.1011 12.2435 22.0077 15.0122 22.0077C18.2157 22.0077 21.1823 18.6663 21.1823 16.2139C21.1823 13.7615 20.4627 12.8321 20.5107 10.7769C20.5497 9.03657 21.8163 8.20611 21.8163 7.33667C21.8163 6.60514 20.7296 6.42676 20.3728 6.42676C19.7402 6.42676 18.1182 7.23773 18.1182 9.37235C18.1182 10.2028 17.8814 10.9538 17.0314 10.9538C16.1815 10.9538 15.2326 9.78759 14.6585 8.87767"
        fill="url(#paint0_linear_210_3183)"
      />
      <path
        d="M21.815 7.33625C21.815 6.93901 21.4927 6.70516 21.1419 6.57324C21.5632 7.96884 19.427 8.0288 19.427 12.7837C19.427 17.5387 16.6583 18.9223 15.5116 19.1606C14.3648 19.3975 12.3441 19.4664 10.8061 17.5791C10.3339 16.999 9.69383 16.3694 8.98179 15.7743C8.68049 16.2765 8.61603 18.0318 9.10021 18.3691C10.148 19.1006 12.2437 22.0073 15.0124 22.0073C18.2158 22.0073 21.1824 18.6659 21.1824 16.2135C21.1824 13.7611 20.4629 12.8317 20.5108 10.7765C20.5498 9.03615 21.815 8.20569 21.815 7.33625Z"
        fill="url(#paint1_linear_210_3183)"
      />
      <path
        d="M9.26882 15.6901C8.75465 15.2359 7.53893 13.9408 6.74745 13.3067C5.95596 12.6741 5.2649 12.6816 4.78971 13.3801C4.31452 14.0787 5.69962 15.3049 6.01592 15.6212C6.5001 16.1054 8.48782 17.9417 9.10092 18.3704"
        fill="url(#paint2_linear_210_3183)"
      />
      <path
        d="M11.4932 13.1887C11.4333 13.3266 11.03 13.4225 10.2281 12.5501C9.61496 11.883 7.97352 9.96578 7.00514 9.09634C6.03677 8.2269 5.28426 7.87013 4.65166 8.32584C4.01907 8.78154 4.37884 9.72893 5.08638 10.5204C5.96182 11.4993 6.96917 12.4976 7.93304 13.457C8.68706 14.2065 10.3945 15.791 10.2071 15.9784"
        fill="url(#paint3_linear_210_3183)"
      />
      <path
        d="M7.93421 13.4553C8.64625 14.1628 10.2052 15.6124 10.2217 15.9317C10.4466 15.5809 10.0359 14.5376 9.60114 14.1718C7.08876 12.0567 4.66183 8.64639 4.35303 8.74833C4.22111 9.24451 4.5554 9.92507 5.08606 10.5202C5.96149 11.4976 6.96884 12.4974 7.93421 13.4553Z"
        fill="url(#paint4_linear_210_3183)"
      />
      <path
        d="M14.6584 8.87914C14.0842 7.96923 11.7308 5.04312 11.1581 4.21265C10.584 3.38219 9.81351 3.38219 9.27985 3.75845C8.7462 4.1347 8.5873 4.88572 9.78952 6.48219C10.548 7.48954 11.1776 8.41594 12.6916 10.0859L14.4515 9.96294L14.6584 8.87914Z"
        fill="url(#paint5_linear_210_3183)"
      />
      <path
        d="M12.4227 10.5785C12.1664 10.3221 9.89238 7.62687 9.37821 7.04825C8.41434 5.96595 7.19262 3.64694 5.73557 4.67978C4.96956 5.22243 5.38479 6.00942 5.60215 6.32571C5.81951 6.64201 8.64669 9.9054 8.92401 10.1812C9.20133 10.4585 11.5533 13.0489 11.4948 13.1868"
        fill="url(#paint6_linear_210_3183)"
      />
      <path
        d="M12.6901 10.0844C12.966 10.3887 13.2133 10.783 13.1354 10.9104C14.0528 10.1729 12.113 8.00525 11.0142 6.70409C10.1643 5.69674 9.93193 4.41507 9.32332 3.72852C9.30833 3.73751 9.29484 3.748 9.27985 3.757C8.7462 4.13325 8.5873 4.88427 9.78952 6.48074C10.5465 7.48809 11.1776 8.41599 12.6901 10.0844Z"
        fill="url(#paint7_linear_210_3183)"
      />
      <path
        d="M8.92177 10.1826C9.19909 10.4599 11.5511 13.0502 11.4926 13.1881C12.2196 12.2093 9.40146 9.33264 8.03734 7.87858C6.8546 6.61789 6.65223 5.51461 5.49798 4.89551C5.06926 5.41417 5.40954 6.04976 5.59991 6.32708C5.81727 6.64338 8.64595 9.90677 8.92177 10.1826Z"
        fill="url(#paint8_linear_210_3183)"
      />
      <path
        d="M11.4399 13.2463C11.3575 13.2973 11.1881 13.3018 10.9258 13.1504C11.1207 13.3078 11.3005 13.3678 11.4399 13.2463Z"
        fill="url(#paint9_linear_210_3183)"
      />
      <path
        d="M20.5389 6.72357C21.143 6.61564 21.2495 6.68459 21.1415 6.57366C20.8477 6.46423 20.5329 6.42676 20.371 6.42676C19.7384 6.42676 18.1165 7.23773 18.1165 9.37235C18.1165 10.2028 17.8796 10.9538 17.0282 10.9538C16.6924 10.9538 16.3431 10.7725 16.0059 10.4966C17.4989 12.1501 18.6696 10.5926 18.848 9.52526C19.0264 8.45795 18.7671 7.04136 20.5389 6.72357Z"
        fill="url(#paint10_radial_210_3183)"
      />
      <path
        d="M9.09673 3.91895C8.72797 4.33718 8.73996 5.08669 9.78778 6.48079C10.5463 7.48814 11.1759 8.41454 12.6899 10.0845C12.9762 10.4008 13.2341 10.816 13.1246 10.9254C13.378 9.19554 10.9151 7.49414 10.1146 6.18998C9.48048 5.15565 9.35456 4.54554 9.09673 3.91895Z"
        fill="url(#paint11_linear_210_3183)"
      />
      <path
        d="M8.92143 10.1831C9.19875 10.4604 11.5507 13.0507 11.4923 13.1886C12.0109 12.8184 11.6572 12.2412 10.8552 11.4392C9.21074 9.79481 6.92172 7.58524 6.00131 5.76541C5.91437 5.59303 5.75248 5.46561 5.5621 5.44162C5.06592 5.37716 5.43468 6.08621 5.60107 6.32755C5.81693 6.64385 8.64561 9.90724 8.92143 10.1831Z"
        fill="url(#paint12_linear_210_3183)"
      />
      <path
        d="M5.08503 10.5204C5.96047 11.4992 6.96782 12.4976 7.93169 13.457C8.68571 14.2065 10.3931 15.791 10.2057 15.9783C11.264 15.0879 6.34872 11.8695 4.35051 8.75C4.22009 9.24318 4.55437 9.92524 5.08503 10.5204Z"
        fill="url(#paint13_linear_210_3183)"
      />
      <path
        d="M9.99126 16.1555C10.0917 16.1165 10.1442 16.0701 10.1607 16.0191C9.97927 16.1585 9.74092 16.109 9.26873 15.6908C8.75456 15.2366 7.53884 13.9414 6.74735 13.3073C6.39958 13.03 6.07129 12.8756 5.77148 12.8486C6.20021 13.117 6.00084 13.2129 6.95422 13.9444C7.46689 14.3372 9.33768 16.4118 9.99126 16.1555Z"
        fill="url(#paint14_linear_210_3183)"
      />
      <path
        d="M8.74494 11.4093C9.43899 12.2548 10.7941 13.6504 11.4027 13.2651C11.2348 13.3221 10.8526 13.2291 10.229 12.5501C9.61588 11.883 7.97444 9.96574 7.00607 9.0963C6.22207 8.39176 5.57899 8.0245 5.02734 8.15191C6.63281 8.67957 8.08687 10.6103 8.74494 11.4093Z"
        fill="url(#paint15_linear_210_3183)"
      />
      <path
        d="M9.49516 7.77084C10.6029 8.97606 12.2474 11.367 13.0808 10.9488C12.9444 10.9908 12.6506 10.8094 12.4213 10.5785C12.1649 10.3222 9.8909 7.62693 9.37674 7.0483C8.70817 6.29879 7.91818 4.95566 7.00977 4.54492C7.92567 5.26745 8.59724 6.79497 9.49516 7.77084Z"
        fill="url(#paint16_linear_210_3183)"
      />
      <path
        d="M16.0058 10.4979C16.0073 10.4979 16.0073 10.4979 16.0058 10.4979C15.4887 10.0752 15.003 9.42913 14.6552 8.87899C14.0826 7.96908 11.7291 5.04297 11.1565 4.2125C10.9811 3.95917 10.7892 3.78378 10.5898 3.67285C10.8567 4.0566 11.0081 4.60375 11.6122 5.28131C12.5806 6.36811 13.8472 8.32585 14.282 8.99891C14.7152 9.66898 15.4167 11.0871 16.0058 10.4979Z"
        fill="url(#paint17_linear_210_3183)"
      />
      <path
        d="M19.1785 20.1484C18.8188 19.2729 18.2926 18.7393 17.5326 19.24C15.4594 20.6056 13.0745 20.1439 10.0839 17.7274C7.41714 15.5703 6.03803 14.0293 4.73987 13.4717C4.42807 14.1807 5.71124 15.3185 6.01404 15.6213C6.49823 16.1055 8.48595 17.9418 9.09905 18.3705C10.1469 19.102 12.2425 22.0087 15.0112 22.0087C16.5597 22.0072 18.0498 21.2292 19.1785 20.1484Z"
        fill="url(#paint18_linear_210_3183)"
      />
      <path
        d="M20.5088 10.7763C20.5478 9.03589 21.8145 8.20542 21.8145 7.33598C21.8145 7.07965 21.6811 6.89227 21.4922 6.75586C21.723 7.43342 21.1729 8.03453 20.3514 9.05687C19.461 10.1647 19.2646 11.884 19.8762 13.6049C20.4893 15.3258 19.7173 17.6988 18.4716 18.4498C17.2259 19.2008 15.571 22.5032 10.8326 18.7721C10.8311 18.7706 10.8311 18.7706 10.8296 18.7691C10.5403 18.5008 10.2539 18.2624 9.96463 18.0825C7.96342 16.8428 6.82266 15.7276 5.96071 14.7907C5.79132 14.6063 5.33562 13.9002 4.79297 14.1326C5.06429 14.7472 5.79432 15.4023 6.01318 15.6211C6.49737 16.1053 8.48508 17.9416 9.09819 18.3704C9.17764 18.4258 9.26458 18.4948 9.35602 18.5742C9.38001 18.5952 9.40399 18.6162 9.42797 18.6372C9.43397 18.6432 9.44147 18.6492 9.44746 18.6537C9.46245 18.6672 9.47894 18.6822 9.49393 18.6956C9.49693 18.6986 9.50143 18.7016 9.50443 18.7061C10.1265 19.2713 10.9825 20.2067 12.0318 20.9367C12.0318 20.9367 12.0333 20.9367 12.0333 20.9382C12.1247 21.0027 12.2192 21.0656 12.3136 21.1256C12.3151 21.1271 12.3166 21.1271 12.3181 21.1286C12.4125 21.1885 12.5085 21.247 12.6044 21.3025C12.6059 21.3025 12.6059 21.304 12.6074 21.304C13.333 21.7207 14.1379 22.0085 15.0074 22.0085C18.2108 22.0085 21.1774 18.6672 21.1774 16.2147C21.1774 13.7623 20.4623 12.8329 20.5088 10.7763Z"
        fill="url(#paint19_radial_210_3183)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_210_3183"
          x1="19.6249"
          y1="22.6421"
          x2="16.9852"
          y2="16.4576"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_210_3183"
          x1="20.1504"
          y1="18.3403"
          x2="14.6138"
          y2="11.736"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_210_3183"
          x1="5.35234"
          y1="13.9431"
          x2="8.61497"
          y2="16.3456"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_210_3183"
          x1="4.16423"
          y1="7.58469"
          x2="9.88867"
          y2="13.5761"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_210_3183"
          x1="8.55838"
          y1="11.1423"
          x2="6.66608"
          y2="12.9249"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_210_3183"
          x1="10.3479"
          y1="4.75814"
          x2="13.6699"
          y2="9.97836"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_210_3183"
          x1="6.91822"
          y1="5.62476"
          x2="11.4266"
          y2="11.715"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_210_3183"
          x1="12.2806"
          y1="6.36029"
          x2="9.43323"
          y2="8.93085"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_210_3183"
          x1="9.66015"
          y1="8.1909"
          x2="5.74498"
          y2="11.0531"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_210_3183"
          x1="12.5765"
          y1="12.1801"
          x2="8.66131"
          y2="15.0423"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </linearGradient>
        <radialGradient
          id="paint10_radial_210_3183"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.7137 7.53436) scale(4.70028 4.70028)">
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </radialGradient>
        <linearGradient
          id="paint11_linear_210_3183"
          x1="12.8014"
          y1="5.85551"
          x2="10.3693"
          y2="8.10969"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_210_3183"
          x1="10.9376"
          y1="7.07695"
          x2="7.73429"
          y2="9.96389"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_210_3183"
          x1="9.85465"
          y1="10.1995"
          x2="5.89994"
          y2="13.5215"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_210_3183"
          x1="5.54086"
          y1="12.613"
          x2="10.1382"
          y2="16.3502"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFBC47" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_210_3183"
          x1="6.42017"
          y1="7.98041"
          x2="10.0091"
          y2="13.4379"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFBC47" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_210_3183"
          x1="9.23056"
          y1="8.36994"
          x2="10.239"
          y2="7.59877"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_210_3183"
          x1="14.2074"
          y1="11.417"
          x2="12.8628"
          y2="5.01041"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFBC47" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_210_3183"
          x1="11.1387"
          y1="17.5654"
          x2="9.12175"
          y2="19.4192"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </linearGradient>
        <radialGradient
          id="paint19_radial_210_3183"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.4736 11.4387) rotate(48.14) scale(13.6241 12.3664)">
          <stop offset="0.6134" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default WelcomeHi;
