import React from 'react';

const ErrorMessageSvg = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM6.83881 4.04547H9.16089L8.94854 10.6055H7.05116L6.83881 4.04547ZM9.20244 12.4336C9.19321 13.0984 8.63461 13.6339 8.00216 13.6339C7.33738 13.6339 6.79264 13.0984 6.80187 12.4336C6.79264 11.7781 7.33738 11.2472 8.00216 11.2472C8.63461 11.2472 9.19321 11.7781 9.20244 12.4336Z"
        fill="#DE350B"
      />
    </svg>
  );
};

export default ErrorMessageSvg;
