import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import svgFiles from '../../assets/svgFiles';
import { Divider, IconButton, MenuItem } from '@mui/material';
import { orderMenuOptionStyle } from '../../styles/custom_style';
import './style.css';
import Modal from '../Modal';
import { constants } from '../../constants';
import { printStatusUpdate } from '../../utils/api/orderListing';
import { useDispatch } from 'react-redux';
import { ToastNotifyError } from '../Toast/ToastNotify';
import { updateLoading } from '../../store/auth/action';
import { downloadJob } from '../../utils/api/orderPlace';
import ViewJob from './ViewJob.js';
import { getFilename } from '../../utils';
export default function PrintMenu({
  id: rowId,
  tableListing,
  frontImage,
  statusId,
  jobName,
  disableActions,
  setIsOrderDetails,
  isSpecial,
  row,
  setOrderDetail,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadClose = async () => {
    dispatch(updateLoading({ loading: true }));
    const response = await downloadJob(rowId);
    dispatch(updateLoading({ loading: false }));
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      handleClose();
      tableListing();

      let fileName = getFilename(response?.headers['content-disposition']) || jobName + '-' + rowId;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([data], {
            type: 'application/pdf',
            encoding: 'UTF-8',
          }),
          fileName,
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/pdf',
            encoding: 'UTF-8',
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } else if (parseInt(status / 100) === 5) {
      ToastNotifyError('Something went wrong');
    } else {
      ToastNotifyError(data?.message);
    }
  };

  const handlePrintedClose = async () => {
    const dataItem = {
      id: rowId,
      data: {
        status: 3,
      },
    };
    dispatch(updateLoading({ loading: true }));
    const response = await printStatusUpdate(dataItem);
    dispatch(updateLoading({ loading: false }));
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      setAnchorEl(null);
      tableListing();
    } else {
      ToastNotifyError(data?.message);
    }
  };

  const closePreview = () => {
    setOpenPreview(false);
  };

  // const previewJob = () => {
  //   setOpenPreview(true);
  // };

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        {svgFiles?.Option}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            background: '#333840',
            color: '#EFEFEF',
            boxShadow:
              '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
            backdropFilter: 'blur(2.5px)',
            borderRadius: '8px',
            marginLeft: '-16px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <MenuItem
          sx={{
            ...orderMenuOptionStyle,
          }}
          disabled={disableActions}
          onClick={handleDownloadClose}>
          Download to Print
        </MenuItem>

        <Divider sx={{ background: 'rgba(255, 255, 255, 0.1)', margin: '0px !important' }} />

        <MenuItem
          sx={{
            ...orderMenuOptionStyle,
          }}
          disabled={statusId !== 2 || disableActions}
          onClick={handlePrintedClose}>
          Mark as printed
        </MenuItem>
        <Divider sx={{ background: 'rgba(255, 255, 255, 0.1)', margin: '0px !important' }} />
        <MenuItem
          sx={{
            ...orderMenuOptionStyle,
          }}
          disabled={disableActions}
          onClick={() => {
            setOrderDetail({
              rowId: row?.id,
              jobId: row?.display_id,
              frontImage: row?.front_image,
              jobName: row?.job_name,
              isSpecial: row?.is_special,
            });
            setIsOrderDetails(rowId, isSpecial);
          }}>
          View job
        </MenuItem>
      </Popover>
      <Modal
        openDialog={openPreview}
        closeDialog={closePreview}
        noHeader={false}
        heading={constants.JOB_PREVIEW}
        dialogCss={'preview-jobpopup'}
        dialogContent={
          <ViewJob
            rowId={rowId}
            frontImage={frontImage}
            jobName={jobName}
            handleClose={handleClose}
          />
        }
        dialogAction={''}
      />
    </div>
  );
}
