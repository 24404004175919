import React, { useEffect, useState } from 'react';
import { constants } from '../../constants';
import Modal from '../Modal';
import './labelStyle.css';
import { Document, pdfjs, Page } from 'react-pdf';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
const SpecialArtwork = ({ openDialog, closeDialog, artWorkRes: artWork, jobName, displayId }) => {
  // const { order_name } = orderDetail;
  // console.log(isSpecial);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (
    <div>
      <Modal
        openDialog={openDialog}
        closeDialog={closeDialog}
        noHeader={false}
        dialogCss={'preview-popup'}
        heading={constants.JOB_PREVIEW}
        dialogContent={
          <div>
            <div className="heading">
              <div style={{ paddingTop: '10px' }}>
                <span className={`md-light ${'jobIdHead'}`}>Job ID </span>
                <span className={`md-medium ${'jobId'}`}>{displayId}</span>
              </div>
              <div className={`md-medium ${'jobName'}`}>{jobName}</div>
            </div>
            <div style={{ margin: '20px', marginBottom: '0px' }}>
              <Document
                options={{
                  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                  cMapPacked: true,
                }}
                file={JSON.parse(JSON.stringify(artWork))}
                loading={
                  <Box sx={{ pt: 0.5, minHeight: '400px' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </Box>
                }
                noData={() => {
                  return (
                    <Box sx={{ pt: 0.5, minHeight: '400px' }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </Box>
                  );
                }}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode="canvas">
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page, ind) => {
                    return (
                      <div
                        key={ind}
                        id={`viewer-page-${ind + 1}`}
                        style={{ marginBottom: '7px' }}
                        className="d-flex justify-content-center align-items-center">
                        <Page
                          key={`page_${ind}`}
                          pageNumber={ind + 1}
                          scale={2}
                          rotate={0}
                          width={448}
                          loading={
                            <Box sx={{ pt: 0.5 }} className="skeletonBoxArt">
                              <Skeleton animation="wave" />
                              <Skeleton animation="wave" />
                              <Skeleton animation="wave" />
                              <Skeleton animation="wave" />
                              <Skeleton animation="wave" />
                            </Box>
                          }
                          style={{ margin: 'auto' }}
                          renderAnnotationLayer={false}
                        />
                      </div>
                    );
                  })}
              </Document>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default SpecialArtwork;
