import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import './style.css';

const SelectField = ({ selectedValue, handleChange, list, placeHolder }) => {
  return (
    <div className="select-field">
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          labelId="demo-dialog-select-label"
          id="demo-dialog-select"
          value={selectedValue}
          onChange={handleChange}
          IconComponent={ExpandMoreIcon}
          displayEmpty
          renderValue={
            selectedValue !== '' && selectedValue !== null
              ? undefined
              : () => <div style={{ color: 'gray' }}>{placeHolder}</div>
          }>
          {list?.length > 0 &&
            list?.map((item, index) => {
              return (
                <MenuItem value={item?.id} key={index}>
                  {item?.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectField;
