import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './style.module.css';
import LabelCard from '../../layouts/LabelCard';
import { authSuccess, logout } from '../../store/auth/action';
import ConfirmationPopup from '../ConfirmationPopup';
import UserAvatar from '../UserAvatar';
import { ToastNotifyError } from '../Toast/ToastNotify';
import { userLogout } from '../../utils/api/auth';

const MenuCard = ({ fullName, email }) => {
  const dispatch = useDispatch();

  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const handleClosePopup = () => {
    setIsOpenPopup(false);
  };

  const handleLogout = async () => {
    const response = await userLogout();
    const { status = 0 } = response;
    if (parseInt(status / 100) === 2) {
      dispatch(logout());
      dispatch(
        authSuccess({
          loggedIn: false,
        }),
      );
    } else {
      ToastNotifyError(data?.message);
    }
  };

  const onConfirmedYes = () => {
    handleLogout();
  };
  return (
    <div className={styles?.menu_container}>
      <ConfirmationPopup
        isOpenPopup={isOpenPopup}
        handleClosePopup={handleClosePopup}
        onConfirmedYes={onConfirmedYes}
      />

      <LabelCard width="260px">
        <div className={`${styles?.menu_account} md-medium fs14-22`}>Account</div>
        <div className={styles?.user_details_menu}>
          <UserAvatar fullName={fullName} />
          <div className={styles?.menu_content}>
            <div className={`${styles?.menu_name} md-medium fs14-14`}>{fullName}</div>
            <div className={`${styles?.menu_email} md-light fs12-12`}>{email}</div>
          </div>
        </div>
        <div className={styles?.menu_divider} />
        <div className={styles?.menu_options}>
          <div
            className={`${styles?.menu_options_data} md-medium fs14-14`}
            onClick={() => {
              setIsOpenPopup(true);
            }}>
            Log out
          </div>
        </div>
      </LabelCard>
    </div>
  );
};

export default MenuCard;
