import React from 'react';

const Warning = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.20899 9.91699H7.79232V6.37533H9.20899M9.20899 12.7503H7.79232V11.3337H9.20899M0.708984 14.8753H16.2923L8.50065 1.41699L0.708984 14.8753Z"
        fill="#DE350B"
      />
    </svg>
  );
};

export default Warning;
