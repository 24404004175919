import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {
  // this is dummy loader change when design part ready
  return (
    <div>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 99999,
        }}
        open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Loader;
