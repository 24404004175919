import { Stack } from '@mui/system';
import React from 'react';
import svgFiles from '../../assets/svgFiles';
import PopupLayout from '../../layouts/PopupLayout';
import ButtonCustom from '../ButtonCustom';

const ArtworkGenerationFailed = ({ open, handleClosePopup }) => {
  return (
    <PopupLayout
      isOpenPopup={open}
      handleClosePopup={handleClosePopup}
      popupWidth={'467px'}
      padding={'32px 50px'}>
      <Stack justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <div>{svgFiles?.GenerationFailed}</div>
        <div
          className="md-regular fs14-22"
          style={{ textAlign: 'center', marginBottom: '42px', marginTop: '16px' }}>
          Artwork generation failed! Please contact the admin
        </div>
        <ButtonCustom width="fit-content" padding="18px 48px" onClick={handleClosePopup}>
          Close
        </ButtonCustom>
      </Stack>
    </PopupLayout>
  );
};

export default ArtworkGenerationFailed;
