import { TextField } from '@mui/material';
import React from 'react';
import './style.css';

const BasicTextField = ({ value, handleChange, label }) => {
  return (
    <div className="basic-textfield">
      <TextField
        id="outlined-basic"
        fullWidth
        variant="outlined"
        value={value}
        label={label}
        onChange={handleChange}
      />
    </div>
  );
};

export default BasicTextField;
