import * as React from 'react';
import Popover from '@mui/material/Popover';
import svgFiles from '../../assets/svgFiles';
import { Button, DialogActions, Divider, IconButton, MenuItem } from '@mui/material';
import { orderMenuOptionStyle } from '../../styles/custom_style';
import message from '../../assets/svgFiles/message.svg';
import cross from '../../assets/svgFiles/cross.svg';
import './style.css';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { addComment } from '../../utils/api/orderListing';
import { updateLoading } from '../../store/auth/action';
import { useSelector } from 'react-redux';
import Modal from '../Modal';
import { constants } from '../../constants';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import line from '../../assets/images/Line.png';
import { shippingUpdate, statusUpdate } from '../../utils/api/orderPlace';
export default function OrderMenu({
  rowId = '',
  reRenderListing,
  status,
  shippingStatus,
  orderData,
}) {
  const dispatch = useDispatch();
  const [openCom, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElComment, setAnchorElComment] = React.useState(null);
  const [openPreview, setOpenPreview] = React.useState(false);
  // const [commentData, setCommentData] = React.useState('');
  const [value, setValue] = React.useState('Drop off');
  const valueRef = React.useRef('');
  const authSuccess = useSelector((state) => state?.auth);
  const [valueTextField, setvalueTextField] = React.useState('');
  const [isError, setIsError] = React.useState(false);
  const handleChange = (event) => {
    setvalueTextField(event.target.value);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const open = Boolean(anchorEl);
  const commentOpen = Boolean(anchorElComment);
  const id = open ? 'simple-popover' : commentOpen ? 'simple-popover' : undefined;

  const closeComment = () => {
    setOpen(false);
    reRenderListing();
    orderData();
  };

  const openComment = () => {
    setOpen(true);
  };

  const commentButton = (event) => {
    handleClose();
    openComment();
    setAnchorElComment(event.currentTarget);
    setIsError(false);
  };

  const errorButton = async (event) => {
    setAnchorElComment(event.currentTarget);
    const dataDetail = {
      id: rowId,
      status: {
        status: 10,
      },
    };
    dispatch(updateLoading({ loading: true }));
    const response = await statusUpdate(dataDetail);
    dispatch(updateLoading({ loading: false }));
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      handleClose();
      openComment();
      setIsError(true);
    } else if (parseInt(status / 100) === 5) {
      ToastNotifyError('Something went wrong');
    } else {
      ToastNotifyError(data?.message);
    }
  };

  const closePreview = () => {
    setOpenPreview(false);
    setvalueTextField('');
    setValue('Drop off');
  };

  const previewJob = () => {
    handleClose();
    setOpenPreview(true);
  };

  const postComment = async () => {
    closeComment();
    if (isError) {
      setIsError(false);
      const dataValue = {
        id: rowId,
        data: {
          comment: valueRef?.current?.value,
        },
      };
      dispatch(updateLoading({ loading: true }));
      const response = await addComment(dataValue);
      dispatch(updateLoading({ loading: false }));
      const { status = 0, data } = response;
      if (parseInt(status / 100) === 2) {
        closeComment();
        reRenderListing();
        orderData();
      } else if (parseInt(status / 100) === 5) {
        ToastNotifyError('Something went wrong');
      } else {
        ToastNotifyError(data?.message);
      }
    } else {
      const dataValue = {
        id: rowId,
        data: {
          comment: valueRef?.current?.value,
        },
      };
      dispatch(updateLoading({ loading: true }));
      const response = await addComment(dataValue);
      dispatch(updateLoading({ loading: false }));
      const { status = 0, data } = response;
      if (parseInt(status / 100) === 2) {
        closeComment();
        reRenderListing();
        orderData();
      } else if (parseInt(status / 100) === 5) {
        ToastNotifyError('Something went wrong');
      } else {
        ToastNotifyError(data?.message);
      }
    }
  };

  const shippedFun = async () => {
    const dataValue = {
      id: rowId,
      data: {
        status_id: 8,
        mode_of_shipment: value,
        comment: valueTextField,
      },
    };
    dispatch(updateLoading({ loading: true }));
    const response = await shippingUpdate(dataValue);
    dispatch(updateLoading({ loading: false }));
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      closeComment();
      reRenderListing();
      orderData();
    } else if (parseInt(status / 100) === 5) {
      ToastNotifyError('Something went wrong');
    } else {
      ToastNotifyError(data?.message);
    }
  };

  const errorStatus = [8, 10, 11, 12].includes(status);
  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        {svgFiles?.Option}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            background: '#333840',
            color: '#EFEFEF',
            boxShadow:
              '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
            backdropFilter: 'blur(2.5px)',
            borderRadius: '8px',
            marginLeft: '-16px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        {authSuccess?.userType !== 2 ? (
          <MenuItem
            sx={{
              ...orderMenuOptionStyle,
            }}
            onClick={commentButton}>
            Add Comment
          </MenuItem>
        ) : (
          <>
            <MenuItem
              sx={{
                ...orderMenuOptionStyle,
              }}
              onClick={commentButton}>
              Add Comment
            </MenuItem>
            <MenuItem
              sx={{
                ...orderMenuOptionStyle,
              }}
              disabled={shippingStatus && status === 12 ? false : true}
              onClick={previewJob}>
              Mark as shipped
            </MenuItem>
            <Divider sx={{ background: 'rgba(255, 255, 255, 0.1)', margin: '0px !important' }} />
            <MenuItem
              sx={{
                ...orderMenuOptionStyle,
              }}
              disabled={errorStatus ? true : false}
              onClick={errorButton}>
              Mark Error & hold
            </MenuItem>
          </>
        )}
      </Popover>
      <Popover
        id={id}
        open={openCom}
        anchorEl={anchorElComment}
        onClose={closeComment}
        PaperProps={{
          sx: {
            background: 'white',
            color: 'black',
            boxShadow:
              '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
            backdropFilter: 'blur(2.5px)',
            borderRadius: '16px',
            width: '260px',
            marginLeft: '-20px',
            marginTop: authSuccess?.userType === 2 ? '-75px' : '-35px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <div style={{ position: 'relative' }}>
          <div className="headingComment">
            <img src={message} alt="message" />
            <img src={cross} alt="cross" style={{ cursor: 'pointer' }} onClick={closeComment} />
          </div>
          <div style={{ position: 'relative', top: '-5px' }}>
            <img src={line} />
          </div>
        </div>
        <div className="contentComment">
          <TextField
            id="filled-multiline-static"
            multiline
            rows={3}
            defaultValue=""
            placeholder="Comment"
            variant="filled"
            inputRef={valueRef}
            inputProps={{ maxLength: 100 }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                return false;
              }
            }}
          />
          <div className="postMain">
            <div className="postComment" onClick={postComment}>
              Post
            </div>
          </div>
        </div>
      </Popover>
      <Modal
        openDialog={openPreview}
        closeDialog={closePreview}
        noHeader={false}
        heading={constants.SHIPPED_BY}
        dialogCss={'preview-shipment'}
        dialogContent={
          <div className="shipment-content">
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleRadioChange}>
                <FormControlLabel
                  value="Drop off"
                  className={value !== 'Drop off' && 'inactiveRadio'}
                  control={<Radio />}
                  label="Drop off"
                />
                <FormControlLabel
                  value="Pickup"
                  className={value !== 'Pickup' && 'inactiveRadio'}
                  control={<Radio />}
                  label="Pickup"
                />
                <FormControlLabel
                  value="Courier service"
                  className={value !== 'Courier service' && 'inactiveRadio'}
                  control={<Radio />}
                  label="Courier service"
                />
              </RadioGroup>
            </FormControl>
            <div className="shipmentField">
              <TextField
                inputProps={{
                  maxlength: 100,
                }}
                multiline
                rows={4}
                value={valueTextField}
                helperText={`${valueTextField.length}/${100}`}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                placeholder="Add Shipment Details"
              />
            </div>
          </div>
        }
        dialogAction={
          <DialogActions className="preview-shipment">
            <div>
              <Button
                className={`md-medium ${'dialog-button-inactive'}`}
                style={{ marginRight: '10px' }}
                onClick={closePreview}>
                Cancel
              </Button>
              <Button className={`md-medium ${'dialog-button'}`} onClick={shippedFun}>
                Shipped
              </Button>
            </div>
          </DialogActions>
        }
      />
    </div>
  );
}
