import React, { useState } from 'react';
import { InputAdornment } from '@mui/material';
import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import WelcomeHi from '../../assets/svgFiles/welcomeHi';
import ButtonCustom from '../../components/ButtonCustom';
import AuthTextField from '../../components/CustomTextField';
import ErrorMessage from '../../components/ErrorMessage';
import { constants } from '../../constants';
import { pageEndPoints } from '../../constants/endPoints';
import LoginLayout from '../../layouts/LoginLayout';
import './style.css';
import { authSuccess } from '../../store/auth/action';
import { loginValidate } from '../../utils/validation';
import { loginUtils } from '../../utils/api/auth';
import { updateLoading } from '../../store/auth/action';

const Login = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [apiError, setApiError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: loginValidate,

    onSubmit: async (values) => {
      dispatch(updateLoading({ loading: true }));
      const response = await loginUtils(values);
      dispatch(updateLoading({ loading: false }));
      const { status = 0, data } = response;
      if (parseInt(status / 100) === 2) {
        dispatch(
          authSuccess({
            token: data?.token,
            loggedIn: true,
            userId: data?.user_id,
            isPasswordReset: data?.is_password_reset,
            is_delinquent: data?.is_delinquent,
          }),
          navigate(pageEndPoints?.RESET, {
            replace: true,
          }),
        );
      } else {
        setApiError(data?.message);
      }
    },
  });

  return (
    <>
      <LoginLayout>
        <form onSubmit={formik?.handleSubmit} autoComplete="off">
          <Stack direction={'column'}>
            <div className="login_welcome_header  md-medium fs20-28 color-dark-grey">
              Welcome back <WelcomeHi />
            </div>
            <div className="login_welcome_sub_header md-semibold fs36-51 color-dark-grey">
              Login to your account
            </div>
            <div>
              <AuthTextField
                label={'Username'}
                required={true}
                type={'username'}
                placeholder={'Enter Username'}
                // onChange={formik?.handleChange}
                value={formik?.values?.username}
                onChange={(e) => {
                  setApiError(null);
                  formik.setFieldValue('username', e.target.value.trim().replaceAll(' ', ''));
                }}
                error={formik?.errors?.username && formik?.touched?.username}
                message={formik?.errors?.username}
              />

              <AuthTextField
                label={'Password'}
                required={true}
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder={'Enter Password'}
                isMarginTop={'20px'}
                onChange={(e) => {
                  setApiError(null);
                  formik.setFieldValue('password', e.target.value.trim().replaceAll(' ', ''));
                }}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik?.errors?.password && formik?.touched?.password}
                message={formik?.errors?.password}
                // touched={formik?.touched?.password}
                endAdornment={
                  <InputAdornment position="end">
                    <div
                      className="md-medium fs14-20 pass_visible c-pointer"
                      onClick={() => setIsPasswordVisible((prev) => !prev)}>
                      {isPasswordVisible ? constants?.PASSWORD_HIDE : constants?.PASSWORD_SHOW}
                    </div>
                  </InputAdornment>
                }
              />
            </div>
            <Link to={pageEndPoints?.FORGOT} className="forgot-button md-medium fs16-23">
              {constants?.FORGOT_TEXT_BTN}
            </Link>
            {apiError && (
              <div className="flex-center">
                <ErrorMessage message={apiError} />
              </div>
            )}
            <div style={{ marginTop: '18px' }}>
              <ButtonCustom type={'submit'}>{constants?.LOGIN_BTN}</ButtonCustom>
            </div>
          </Stack>
        </form>
      </LoginLayout>
    </>
  );
};

export default Login;
