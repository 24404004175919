import React from 'react';

const ResetSuccess = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="44" cy="44" r="44" fill="#F4F9FF" />
      <circle cx="43.9991" cy="44.0001" r="35.4444" fill="#E1EBFC" />
      <g clipPath="url(#clip0_207_3264)">
        <path
          d="M43.555 24C45.3501 24 47.0815 24.2292 48.7493 24.6875C50.4171 25.1458 51.9703 25.8078 53.4089 26.6735C54.8475 27.5393 56.1652 28.5577 57.3619 29.729C58.5586 30.9003 59.5835 32.2179 60.4365 33.682C61.2895 35.1461 61.9451 36.7057 62.4034 38.3607C62.8617 40.0157 63.0973 41.7472 63.11 43.555C63.11 45.3501 62.8808 47.0815 62.4225 48.7493C61.9642 50.4171 61.3022 51.9703 60.4365 53.4089C59.5707 54.8475 58.5523 56.1652 57.381 57.3619C56.2097 58.5586 54.8921 59.5835 53.428 60.4365C51.9639 61.2895 50.4043 61.9451 48.7493 62.4034C47.0943 62.8617 45.3628 63.0973 43.555 63.11C41.7599 63.11 40.0285 62.8808 38.3607 62.4225C36.6929 61.9642 35.1397 61.3022 33.7011 60.4365C32.2625 59.5707 30.9448 58.5523 29.7481 57.381C28.5514 56.2097 27.5265 54.8921 26.6735 53.428C25.8206 51.9639 25.1649 50.4043 24.7066 48.7493C24.2483 47.0943 24.0127 45.3628 24 43.555C24 41.7599 24.2292 40.0285 24.6875 38.3607C25.1458 36.6929 25.8078 35.1397 26.6735 33.7011C27.5393 32.2625 28.5577 30.9448 29.729 29.7481C30.9003 28.5514 32.2179 27.5265 33.682 26.6735C35.1461 25.8206 36.7057 25.1649 38.3607 24.7066C40.0157 24.2483 41.7472 24.0127 43.555 24ZM55.0703 37.0812L52.4732 34.4841L39.8884 47.0688L34.6369 41.8172L32.0397 44.4144L39.8884 52.2631L55.0703 37.0812Z"
          fill="#0052CC"
        />
      </g>
      <defs>
        <clipPath id="clip0_207_3264">
          <rect width="39.11" height="39.11" fill="white" transform="translate(24 24)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ResetSuccess;
