import React from 'react';
// import { CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import style from './style.module.css';

const ButtonCustom = ({
  color = '#FFFFFF',
  background = '#0052cc',
  padding = '20px 30px',
  margin,
  width = '100%',
  buttonStyle = {},
  height,
  className,
  textClass,
  textStyle = {},
  disable = false,
  border = 'none',
  borderRadius = '6px',
  cursor = 'pointer',
  onClick = () => {
    null;
  },
  type,
  children,
  auth,
}) => {
  // const { buttonLoading } = useSelector((state) => state?.auth);
  const { buttonLoading } = auth;

  return (
    <button
      type={type}
      disabled={disable}
      className={`${
        disable ? style?.custom_btn_disabled : !buttonLoading && style?.custom_btn
      } ${className}`}
      style={{
        width,
        height,
        borderRadius,
        background,
        margin,
        padding,
        border,
        cursor: buttonLoading ? 'not-allowed' : cursor,
        ...buttonStyle,
      }}
      onClick={() => {
        buttonLoading ? null : onClick();
      }}>
      <span
        className={`md-semibold fs16-16 ${textClass || ''}`}
        style={{
          color: color,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '16px',
          ...textStyle,
        }}>
        {buttonLoading ? (
          <div className={style?.new_loading} />
        ) : (
          // <CircularProgress
          //   sx={{
          //     color: '#FFFFFF',
          //   }}
          //   size={30}
          //   thickness={4}
          // />
          children
        )}
      </span>
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ButtonCustom);
