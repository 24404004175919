import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { constants } from '../../constants';
import LabelCard from '../../layouts/LabelCard';
import ButtonCustom from '../ButtonCustom';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import svgFiles from '../../assets/svgFiles';
import { downloadAllJob } from '../../utils/api/orderPlace';
import { ToastNotifyError } from '../Toast/ToastNotify';
import { updateLoading } from '../../store/auth/action';

const PrintJobsHeader = ({ totalCount, onHoldDisable, downloadAllCallBack }) => {
  const dispatch = useDispatch();
  const authSuccess = useSelector((state) => state?.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isComma, setIsComma] = useState(false);
  const orderIdQuery = searchParams.get('orderId');
  useEffect(() => {
    setSearchParams(searchParams);
    if (orderIdQuery?.includes(',')) {
      setIsComma(true);
    } else {
      setIsComma(false);
    }
  }, [isComma, searchParams]);
  const downloadFun = async () => {
    dispatch(updateLoading({ loading: true }));
    const response = await downloadAllJob(orderIdQuery);
    dispatch(updateLoading({ loading: false }));
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      let fileName = orderIdQuery;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([data], {
            type: 'application/zip',
            encoding: 'UTF-8',
          }),
          fileName,
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/zip',
            encoding: 'UTF-8',
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        downloadAllCallBack();
      }
    } else if (parseInt(status / 100) === 5) {
      ToastNotifyError('Something went wrong');
    } else {
      ToastNotifyError(data?.message);
    }
  };
  return (
    <>
      <LabelCard
        borderRadius={'0px'}
        height={'98px'}
        padding={'0px 36px'}
        otherStyle={{
          borderBottom: '1px solid #E4E4E4',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Stack spacing={1}>
          <div
            className="color_black_text fs24-24 md-semibold"
            style={{ textTransform: 'capitalize' }}>
            {constants.PRINT_HEADING}
          </div>
          <div className="color_black_text">
            Total records:
            <span className="md-semibold"> {totalCount || 0}</span>
          </div>
        </Stack>
        {authSuccess?.userType === 2 ? (
          <>
            {!orderIdQuery || isComma || onHoldDisable ? (
              <Tooltip
                placement="bottom-end"
                title={
                  <div
                    className="md-semibold fs12-17"
                    style={{ display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                    {svgFiles?.Warning}{' '}
                    <span style={{ marginLeft: '5px' }}>
                      {onHoldDisable
                        ? 'Order on hold'
                        : 'Please select only one order ID to enable this button'}
                    </span>
                  </div>
                }
                arrow
                slotProps={{
                  tooltip: {
                    style: {
                      marginTop: '20px',
                      boxShadow: '0px 0px 14px #D9D9D9',
                      background: '#FFFFFF',
                      color: '#000000',
                      padding: '12px',
                      width: 'fit-content',
                    },
                  },
                  arrow: {
                    style: {
                      boxShadow: '0px 0px 14px #D9D9D9',
                      color: '#FFFFFF',
                    },
                  },
                }}>
                <div>
                  <ButtonCustom padding={'14px 18px'} width={'fit-content'} disable={true}>
                    {constants?.DOWNLOAD_ALL}
                  </ButtonCustom>
                </div>
              </Tooltip>
            ) : (
              <ButtonCustom
                padding={'14px 18px'}
                width={'fit-content'}
                disable={authSuccess?.is_delinquent || onHoldDisable}
                onClick={downloadFun}>
                {constants?.DOWNLOAD_ALL}
              </ButtonCustom>
            )}
          </>
        ) : (
          <></>
        )}
      </LabelCard>
    </>
  );
};

export default PrintJobsHeader;
