import React from 'react';
import { Stack } from '@mui/system';
import svgFiles from '../../../assets/svgFiles';
import LabelCard from '../../../layouts/LabelCard';

const OrdersCard = ({ heading = 'Printed Orders', qty = '00', icon, onClick }) => {
  return (
    <LabelCard
      padding="10px 20px 20px 20px"
      isHover={true}
      height="164px"
      onClick={onClick}
      otherStyle={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'space-between',
      }}>
      <Stack direction={'rows'} justifyContent={'space-between'}>
        <Stack spacing={3.25}>
          <div className="color_black_text md-medium fs14-22">{heading}</div>
          <div className="color_black_text md-semibold fs32-32">{qty}</div>
        </Stack>
        <Stack alignItems={'center'} justifyContent={'center'}>
          {icon}
        </Stack>
      </Stack>
      <Stack columnGap={1} direction={'rows'}>
        {svgFiles?.Incline}
        <div className="color_black_text md-regular fs14-22">{'+41.3%'}</div>
        <div className="color_lite_text md-regular fs14-22">{'than last week'}</div>
      </Stack>
    </LabelCard>
  );
};

export default OrdersCard;
