import React from 'react';
import { Grid } from '@mui/material';
import { constants } from '../../constants';
import { projectFiles } from '../../constants/filesConstants';
import './style.css';

const LoginLayout = ({ children, layoutType = constants?.LOGIN_LAYOUT }) => {
  return (
    <>
      <Grid container className="login_wrapper" columns={14}>
        <Grid item xs={6.6} className={'bg_container_left'}>
          <img
            src={
              layoutType === constants?.LOGIN_LAYOUT
                ? projectFiles?.loginBackground
                : layoutType === constants?.FORGOT_LAYOUT
                ? projectFiles?.forgotBackground
                : layoutType === constants?.RESET_LAYOUT
                ? projectFiles?.forgotBackground
                : projectFiles?.loginBackground
            }
            className="login_background_img"
          />
          <div className="heading_container">
            <div className="login_heading md-medium fs36-51">
              {layoutType === constants?.LOGIN_LAYOUT
                ? constants?.LOGIN_HEADING
                : layoutType === constants?.FORGOT_LAYOUT
                ? constants?.FORGOT_HEADING
                : layoutType === constants?.RESET_LAYOUT
                ? constants?.RESET_HEADING
                : constants?.LOGIN_HEADING}
            </div>
            <div className="login_sub_heading md-regular fs22-35">
              {layoutType === constants?.LOGIN_LAYOUT
                ? constants?.LOGIN_SUB_HEADING
                : layoutType === constants?.FORGOT_LAYOUT
                ? constants?.FORGOT_SUB_HEADING
                : layoutType === constants?.RESET_LAYOUT
                ? constants?.RESET_SUB_HEADING
                : constants?.LOGIN_SUB_HEADING}
            </div>
          </div>
        </Grid>
        <Grid item xs={7.4} className={'bg_container_right'}>
          <div className={'form_container'}>
            <div className="login_logo_container">
              <img src={projectFiles?.logoLabelApp} width={'285px'} />
            </div>
            <div className="login_child_container">{children}</div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginLayout;
