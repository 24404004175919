import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import BackwardArrow from '../../assets/svgFiles/backwardArrow';
import EmailIcon from '../../assets/svgFiles/emailIcon';
import ExceedError from '../../assets/svgFiles/exceedError';
import ResetKey from '../../assets/svgFiles/resetKey';
import { hideEmail } from '../../common';
import ButtonCustom from '../../components/ButtonCustom';
import ConfirmationPopup from '../../components/ConfirmationPopup';
import AuthTextField from '../../components/CustomTextField';
import ErrorMessage from '../../components/ErrorMessage';
import { constants } from '../../constants';
import { apiEndPoints, pageEndPoints } from '../../constants/endPoints';
import useNetworkRequests from '../../hooks/useNetworkRequests';
import LoginLayout from '../../layouts/LoginLayout';
import { authSuccess } from '../../store/auth/action';
import { forgotPasswordValidate } from '../../utils/validation';
import QuestionMark from '../../assets/svgFiles/questionMark';

const ForgotPassword = (props) => {
  const [apiError, setApiError] = useState(null);
  const [isForgotLanding, setIsForgotLanding] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [resendLimitExceeded, setResendLimitExceeded] = useState(false);

  const dispatch = useDispatch();

  // const { userId } = useSelector((state) => state?.auth);
  const { userId } = props.auth;

  const { request, response } = useNetworkRequests();

  //form submit using formik
  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema: forgotPasswordValidate,

    onSubmit: (values) => {
      const { username } = values;
      setIsForgotLanding(true); // temporary on username hit
      setResendCount(resendCount + 1); // temporary on username hit

      //temporary disable api request due to api work is in progress
      let test = false;
      test &&
        request('POST', apiEndPoints?.MAIDEN_RESET(userId), {
          username,
        });
    },
  });

  //handle response
  useEffect(() => {
    let networkStatus, data;
    // let statusCode;
    if (response) {
      ({ networkStatus, data } = response);
    }
    if (networkStatus) {
      setIsForgotLanding(true); //on username hit
      setResendCount(resendCount + 1);
      dispatch(
        authSuccess({
          isPasswordReset: true,
        }),
      );
    } else {
      setApiError(data?.message);
    }
  }, [response]);

  //api error message
  useEffect(() => {
    if (apiError) {
      setTimeout(() => {
        setApiError(null);
      }, 5000);
    }
  }, [apiError]);

  //pop up

  const handleClosePopup = () => {
    setResendLimitExceeded(false);
  };

  return (
    <LoginLayout layoutType={constants?.FORGOT_LAYOUT}>
      {/* popup code */}
      <ConfirmationPopup
        icon={<ExceedError />}
        confirmationText={"You've exceeded the maximum number of attempts. Please try again later."}
        isOpenPopup={resendLimitExceeded}
        popupWidth={'470px'}
        handleClosePopup={handleClosePopup}>
        <ButtonCustom
          width={'124px'}
          padding={'18px 20px'}
          // className={styles?.button_light_hover}
          onClick={handleClosePopup}>
          {constants?.OK_BTN}
        </ButtonCustom>
      </ConfirmationPopup>

      {isForgotLanding ? <EmailIcon /> : <ResetKey />}

      <div className="reset_container">
        <div className="reset_heading md-medium fs36-51 color-dark-grey">
          {isForgotLanding ? 'Check your email' : 'Forgot password?'}
        </div>
        <div className="forgot_subheading md-regular fs15-21 color-dark-grey">
          {isForgotLanding
            ? `An email has been sent to your registered email ID 
            ${hideEmail('test@test.com')} to reset the password`
            : 'No worries,  we’ll send you reset instructions.'}
        </div>
        {isForgotLanding ? (
          <>
            <div style={{ marginTop: '42px' }} className="md-regular fs15-21 color-dark-grey">
              Didn’t receive the email?
            </div>
            <div style={{ marginTop: '24px' }}>
              {' '}
              <ButtonCustom
                onClick={() =>
                  resendCount < 3 ? setResendCount(resendCount + 1) : setResendLimitExceeded(true)
                }>
                {constants?.RESEND_BTN}
              </ButtonCustom>
            </div>
          </>
        ) : (
          <Box component="form" pt={4} onSubmit={formik?.handleSubmit} autoComplete="off">
            <AuthTextField
              label={'Username'}
              required={true}
              type={'username'}
              placeholder={'Enter Username'}
              // onChange={formik?.handleChange}
              value={formik?.values?.username}
              onChange={(e) => {
                formik.setFieldValue('username', e.target.value.trim().replaceAll(' ', ''));
              }}
              error={formik?.errors?.username && formik?.touched?.username}
              message={formik?.errors?.username}
            />

            <div
              style={{ paddingTop: '24px', display: 'flex', alignItems: 'center' }}
              className="md-light fs12-12 color_table_heading">
              <QuestionMark width={'24px'} />
              <div style={{ marginLeft: '6px' }}>
                Forgotten the username? <span className="md-medium">Please contact the admin</span>
              </div>
            </div>

            <div style={{ paddingTop: '18px' }}>
              {apiError && (
                <div className="flex-center">
                  <ErrorMessage message={apiError} />
                </div>
              )}
              <div style={{ marginTop: '18px' }}>
                <ButtonCustom
                  // onClick={() => setIsForgotLanding(true)}
                  type={'submit'}>
                  {constants?.RESET_BTN}
                </ButtonCustom>
              </div>
            </div>
          </Box>
        )}
        <div className="flex-center">
          <Link
            className=" back_btn_login back_btn_login_text md-medium fs16-23 color-dark-2"
            to={pageEndPoints?.LOGIN}>
            <BackwardArrow />
            Back to Login
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ForgotPassword);
