import React from 'react';
import { Backdrop, Grid } from '@mui/material';
import styles from './style.module.css';
import QuestionMark from '../../assets/svgFiles/questionMark';
import { confirmationPopStyle } from '../../styles/custom_style';
import ButtonCustom from '../ButtonCustom';

const ConfirmationPopup = ({
  padding,
  backdropColor,
  backdropFilter,
  popupBackground,
  popupBorderRadius,
  popupWidth,
  confirmationText,
  icon,
  isOpenPopup,
  handleClosePopup,
  onConfirmedYes,
  children,
}) => {
  return (
    <Backdrop
      sx={{
        backgroundColor: backdropColor || 'rgba(68, 68, 68, 0.4) ',
        backdropFilter: backdropFilter || 'blur(5px)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isOpenPopup}
      onClick={handleClosePopup}>
      <Grid container sx={confirmationPopStyle} onClick={(e) => e.stopPropagation()}>
        <Grid
          item
          xs={12}
          p={padding || '36px 72px 30px 72px'}
          className={styles?.popup_container}
          sx={{
            position: 'relative',
            background: popupBackground || '#FFFFFF',
            borderRadius: popupBorderRadius || '6px',
            maxWidth: popupWidth ? `${popupWidth} !important` : '400px !important',
          }}>
          <div>{icon || <QuestionMark />}</div>
          <div className={`${styles?.text_container} md-medium fs14-22`}>
            {confirmationText || 'Are you sure you want to continue?'}
          </div>
          <div className={styles?.button_container}>
            {children || (
              <>
                <ButtonCustom width={'95px'} padding={'18px 20px'} onClick={onConfirmedYes}>
                  Yes
                </ButtonCustom>
                <ButtonCustom
                  width={'95px'}
                  background="#FFFFFF"
                  border="1px solid #0052CC"
                  padding={'18px 20px'}
                  color="#0052CC"
                  className={styles?.button_light_hover}
                  onClick={handleClosePopup}>
                  No
                </ButtonCustom>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default ConfirmationPopup;
