import React from 'react';
import { FormControl, FormLabel, OutlinedInput } from '@mui/material';
import { textFieldStyle } from '../../styles/custom_style';
import ErrorMessage from '../ErrorMessage';

const AuthTextField = ({
  label,
  required = false,
  type = 'text',
  placeholder,
  onChange,
  value,
  error,
  // touched,
  isMarginTop,
  endAdornment = null,
  startAdornment = null,
  onBlur,
  message,
}) => {
  return (
    <>
      <FormControl sx={{ marginTop: isMarginTop }}>
        <FormLabel>
          <span className="md-medium fs14-20 color-text-grey">
            {label}
            {required && '*'}
          </span>
        </FormLabel>
      </FormControl>
      <FormControl sx={{ marginTop: '12px' }} fullWidth>
        <OutlinedInput
          name={label}
          label={label}
          placeholder={placeholder}
          notched={false}
          sx={textFieldStyle}
          onChange={onChange}
          value={value}
          error={error}
          onBlur={onBlur}
          endAdornment={endAdornment}
          startAdornment={startAdornment}
          inputProps={{ type: type }}
        />
      </FormControl>
      {error && <ErrorMessage message={message} />}
    </>
  );
};

export default AuthTextField;
