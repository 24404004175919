export const constants = {
  default: '',

  ART_GENERATION_TIME: Number(60000), //time in ms(60s)
  ART_PREVIEW_TIME: Number(60000), //time in ms(60s)

  // api methods

  apiMethods: {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
  },

  // toast-type

  toastType: {
    ERROR: 'ERR',
    SUCCESS: 'SUC',
  },

  // requestStatusCode
  requestStatusCode: {
    SUCCESS: 200,
    UNAUTHORIZED: 401,
  },

  //continue button
  CONTINUE_BTN: 'Continue',

  //login
  LOGIN_HEADING: 'Create custom labels for your brand in minutes.',
  LOGIN_SUB_HEADING: 'Make personalised labels in minutes. No design skills are needed.',
  LOGIN_BTN: 'Login',
  RESET_BTN: 'Reset Password',
  FORGOT_TEXT_BTN: 'Forgot Password?',
  PASSWORD_SHOW: 'Show',
  PASSWORD_HIDE: 'Hide',

  //forgot
  FORGOT_HEADING: 'Forgot password?',
  FORGOT_SUB_HEADING: 'No worries,  we’ll send you reset instructions.',
  RESEND_BTN: 'Click to resend',
  OK_BTN: 'Okay',

  //reset
  RESET_HEADING: 'Reset password?',
  RESET_SUB_HEADING: 'Create a password of your choice.',

  //login layout type
  LOGIN_LAYOUT: 'login',
  RESET_LAYOUT: 'reset',
  FORGOT_LAYOUT: 'forgot',

  // navigation headings

  DASHBOARD: 'DASHBOARD',
  MY_ORDER: 'MY ORDERS',
  MY_PROFILE: 'MY PROFILE',
  PRINT_JOB: 'PRINT JOBS',

  //dashboard
  CREATE_ORDER_BTN: 'Create Order',

  //Create Order
  NEXT: 'Next',
  BACK: 'Back',
  PREVIEW_LABEL: 'Preview Label',
  UPLOAD_FILE: 'Upload File',
  FILE_VALIDATION: 'Only .xlsx or .csv files are supported',
  CHOOSE_LABEL_TYPE: 'Choose Label Type',
  BUYING_AGENCY: 'Buying Agency',
  LABEL_PREVIEW: 'Label Preview',
  EXCEL_TYPE:
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  EXCEL_TYPE_ARR: [
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ],
  PREVIEW_ORDER: 'Preview Order',
  DOWNLOAD_ARTWORK: 'Download Artwork',
  LATER: 'Later',
  APPROVE: 'Approve',
  CANCELED: 'Cancel',
  FINISH: 'Finish',
  APPROVE_CONTENT:
    "After you approve the artwork, the order will be sent for printing. You can check the progress of your order on 'My Orders' page",
  // order listing heading

  ORDER_ID: 'Order ID',
  ORDER_NAME: 'Order Name',
  HEADING: 'Total Quantity',
  DAYS_AGO: 'Created',
  STATUS: 'Order Status',
  OPTION: ' ',
  NO_DATA: 'No results found!',
  PER_PAGE_DATA: 20,
  CLOSE: 'Close',

  CANCEL_ORDER: 'Cancel Order',
  VIEW_ARTWORK: 'View Artwork',
  VIEW_JOBS: 'View Print Jobs',

  //Print jobs
  PRINT_HEADING: 'Print Jobs',
  JOB_ID: 'Job ID',
  JOB_NAME: 'Job Name',
  QUANTITY: 'Quantity',
  JOB_STATUS: 'Job Status',
  CREATED_AT: 'Created',
  //cancel Order
  CANCEL_CONFIRMATION: 'Are you sure you want to cancel this order?',
  YES: 'Yes',
  NO: 'No',
  JOB_PREVIEW: 'Job Preview',
  SHIPPED_BY: 'Shipment By',

  //profile page

  PROFILE_HEADING: 'My Profile',
  DOWNLOAD_ALL: 'Download all',
};

// export const status = {
//   PENDING: 'PEND',
//   APPROVED: 'APRV',
//   SHIPPED: 'SHIP',
//   IN_PROGRESS: 'INPG',

//   //keys not use in FE
//   COMPLETED: 'CMPT',
//   CREATED: 'CRTD',
//   BLOCKED: 'BLOK',
//   ON_HOLD: 'HOLD',
//   CANCELED: 'CANC',

//   //keys for FE use
//   QUALITY_CHECK: 'QTCK',
//   PRINTED: 'PRTD',
//   ARCHIVED: 'ARCH',
// };

export const status = {
  ARTWORK_PROCESSING: 2,
  ARTWORK_FAILED: 3,
  APPROVED: 5,
  QUALITY_CHECK: 12,
  IN_PROGRESS: 6,
  PENDING: 4,
  SHIPPED: 8,

  //keys not use in FE
  BLOCKED: '2',
  CANCELED: '3',
  COMPLETED: '4',
  CREATED: '5',
  ON_HOLD: '7',

  //keys for FE use
  PRINTED: '11',
  ARCHIVED: '12',
};

export const statusText = {
  [status?.PENDING]: 'Pending',
  [status?.APPROVED]: 'Approved',
  [status?.SHIPPED]: 'Shipped',
  [status?.IN_PROGRESS]: 'In Progress',

  [status?.QUALITY_CHECK]: 'Quality Check',
  [status?.PRINTED]: 'Printed',
  [status?.ARCHIVED]: 'Archived',

  // currently not in use
  // [status?.COMPLETED]: 'Completed',
  // [status?.CREATED]: 'Created',
  // [status?.BLOCKED]: 'Blocked',
  // [status?.ON_HOLD]: 'On Hold',
  // [status?.CANCELED]: 'Canceled',
};

export const statusColor = {
  [status?.PENDING]: '#BCBCBC',
  [status?.APPROVED]: '#00B8D9',
  [status?.SHIPPED]: ' #4C9AFF',
  [status?.IN_PROGRESS]: '#F6C343',

  [status?.QUALITY_CHECK]: '#FF8B7B',
  [status?.PRINTED]: '#48AB4C',
  [status?.ARCHIVED]: '#826EF1',

  // currently not in use
  // [status?.COMPLETED]: 'Completed',
  // [status?.CREATED]: 'Created',
  // [status?.BLOCKED]: 'Blocked',
  // [status?.ON_HOLD]: 'On Hold',
  // [status?.CANCELED]: 'Canceled',
};

export const statusList = [
  statusText[status?.PENDING],
  statusText[status?.APPROVED],
  statusText[status?.SHIPPED],
  statusText[status?.IN_PROGRESS],

  statusText[status?.QUALITY_CHECK],
  statusText[status?.PRINTED],
  statusText[status?.ARCHIVED],
];

export const productList = { 1: 'Paper Sticker', 2: 'Paper Tag' };

export const productListIds = { 'Paper Sticker': 1, 'Paper Tag': 2 };

export const daysList = { 1: 'Today', 2: 'This Week', 3: 'This Month', 4: 'This Year' };

// export const daysListIds = { Today: 1, 'This Week': 2, 'This Month': 3, 'This Year': 4 };

export const daysListValues = {
  Today: 'today',
  'This Week': 'week',
  'This Month': 'month',
  'This Year': 'year',
};

export const typeUser = {
  ADMIN: 3,
  CUSTOMER: 1,
  PRINT_OPERATOR: 2,
};

export const monthlyOptions = [
  {
    value: 1,
    month: 'January',
  },
  {
    value: 2,
    month: 'February',
  },
  {
    value: 3,
    month: 'March',
  },
  {
    value: 4,
    month: 'April',
  },
  {
    value: 5,
    month: 'May',
  },
  {
    value: 6,
    month: 'June',
  },
  {
    value: 7,
    month: 'July',
  },
  {
    value: 8,
    month: 'August',
  },
  {
    value: 9,
    month: 'September',
  },
  {
    value: 10,
    month: 'October',
  },
  {
    value: 11,
    month: 'November',
  },
  {
    value: 12,
    month: 'December',
  },
];

export const customerGraphTabs = [
  {
    id: 0,
    0: 'Total Orders',
    value: 'total',
  },
  {
    id: 1,
    1: 'Printed Orders',
    value: 'printed',
  },
  {
    id: 2,
    2: 'Orders In Progress',
    value: 'in progress',
  },
  {
    id: 3,
    3: 'Approved Orders',
    value: 'approved',
  },
  {
    id: 4,
    4: 'Pending Orders',
    value: 'pending approval',
  },
];

export const printGraphTabs = [
  {
    id: 0,
    0: 'Total Orders',
    value: 'total',
  },
  {
    id: 1,
    1: 'Printed Orders',
    value: 'printed',
  },
  {
    id: 2,
    2: 'Orders In Progress',
    value: 'in progress',
  },
  {
    id: 3,
    3: 'Shipped Orders',
    value: 'shipped',
  },
];

export const printJobsGraphTabs = [
  {
    id: 0,
    0: 'Total Jobs',
    value: 'total',
  },
  {
    id: 1,
    1: 'Printed Jobs',
    value: 'printed',
  },
  {
    id: 2,
    2: 'Jobs In Progress ',
    value: 'in progress',
  },
];

export const printOperatorStatus = {
  5: 'Pending',
  6: 'In Progress',
  7: 'Printed',
  8: 'Shipped',
  10: 'Error / On hold',
};

export const printOperatorProgress = [
  { final_status_id: 5 },
  { final_status_id: 6 },
  { final_status_id: 7 },
  { final_status_id: 8 },
];

export const defaultCustomerSteps = [
  { final_status_id: 4 },
  { final_status_id: 5 },
  { final_status_id: 6 },
  { final_status_id: 7 },
  { final_status_id: 12 },
  { final_status_id: 8 },
];

export const printOperatorOrderStatus = [5, 6, 7, 8, 10];
export const customerOrderStatus = [4, 5, 6, 7, 12, 8];

export const printJobsStatus = {
  1: 'Created',
  2: 'In Progress',
  3: 'Printed',
};

export const printJobsStatusColor = {
  1: '#FF8B7B',
  2: '#F6C343',
  3: '#48AB4C',
};
