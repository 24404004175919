import React, { useCallback, useEffect, useState } from 'react';
import { constants, daysListValues } from '../../constants';
import DashboardLayout from '../../layouts/DashboardLayout';
import './style.css';
// import OrderDetails from '../../components/OrderDetails';
import { jobStatusList, printList } from '../../utils/api/orderListing';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import { debounce, getTotalPage } from '../../utils';
import PrintJobsHeader from '../../components/PrintJobsHeader';
import PrintTable from '../../components/PrintTable';
import PrintFilter from '../../components/Filters/PrintFilter';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { pageEndPoints } from '../../constants/endPoints';
import Modal from '../../components/Modal';
import ViewJob from '../../components/PrintMenu/ViewJob';
import { useSearchParams } from 'react-router-dom';
import { updateLoading } from '../../store/auth/action';
import { viewPrintJob } from '../../utils/api/orderPlace';
import SpecialArtwork from '../../components/OrderPlace/SpecialArtWork';

const PrintJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authSuccess = useSelector((state) => state?.auth);
  // const [isOrderDetails, setIsOrderDetails] = useState(false);
  const [orderDetail, setOrderDetail] = useState(null);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [totalCount, setTotalCount] = useState(0);

  //order status
  const [statusList, setStatusList] = useState({});
  const [orderStatusIds, setOrderStatusIds] = useState({});
  const [statusColorCode, setStatusColorCode] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [disableFilter, setDisableFilter] = useState({});

  const [onHoldDisable, setOnHoldDisable] = useState(false);

  const [openArtwork, setOpenArtWork] = useState(false);

  // job filter
  // const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const orderIdQuery = searchParams.get('orderId');

  const [statusType, setStatusType] = useState([]);
  const [days, setDays] = useState('');
  const [orderId, setOrderId] = useState(orderIdQuery || '');
  const [jobId, setJobId] = useState('');

  const [artWork, setArtWork] = useState('');

  const filterOrderId = useCallback(
    debounce((orderId) => {
      orderListingData(page, orderId, statusType, jobId, days);
    }, 1000),
    [],
  );

  const filterJobId = useCallback(
    debounce((jobId) => {
      orderListingData(page, orderId, statusType, jobId, days);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (authSuccess?.userType !== 2) {
      navigate(pageEndPoints?.DASHBOARD, {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    getOderStatus(); // order constants
  }, []);

  //order constants apis data

  const getOderStatus = async () => {
    const response = await jobStatusList();
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      let orderStatusList = {},
        orderStatusIds = {},
        statusColor = {};

      data &&
        data.map((val) => {
          orderStatusList[val?.id] = val?.name;
          orderStatusIds[val?.name] = val?.id;
          statusColor[val?.id] = val?.color_code;
        });

      setStatusList(orderStatusList);
      setOrderStatusIds(orderStatusIds);
      setStatusColorCode(statusColor);
    }
  };

  // order constants apis ==END==

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (page) {
      orderListingData(page, orderId, statusType, jobId, days);
    }
  }, [page]);

  useEffect(() => {
    orderListingData(1, orderId, statusType, jobId, days);
  }, [statusType, days]);

  // useEffect(() => {
  //   if (location?.state?.orderId) {
  //     console.log(location.state);
  //     // setOrderId(location.state.orderId);
  //   }
  // }, []);
  useEffect(() => {
    searchParams.set('orderId', orderId);
    setSearchParams(searchParams);
  }, [orderId]);

  const downloadAllCallBack = () => {
    orderListingData(1, orderId, statusType, jobId, days);
  };

  const handleClearAll = () => {
    if (orderId || jobId || statusType.length > 0 || days) {
      setOrderId('');
      setJobId('');
      setStatusType([]);
      setDays('');
      orderListingData(1, '', [], '', '');
    }
  };

  const orderListingData = async (page = 1, id = '', statusType = [], jobId = '', days = '') => {
    setTableLoading(true); // table loading

    let jobStatus = '';
    //for job status
    if (statusType.length > 0) {
      jobStatus = statusType.reduce(function (prev, val, idx) {
        return idx === 0 ? orderStatusIds[val] : prev + ',' + orderStatusIds[val];
      }, '');
    }

    let period = '';
    // for days filter
    if (days) {
      period = daysListValues[days];
    }
    setOnHoldDisable(false);
    const response = await printList(page, id, jobStatus, jobId, period);
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      setTableLoading(false); //table loading
      let { count, results } = data;
      setTotalPage(getTotalPage(count));
      setOrders([]);
      setOrders(results);
      if (results.length > 0 && results[0]?.disable_actions) {
        setOnHoldDisable(true);
      } else {
        setOnHoldDisable(false);
      }
      setTotalCount(count);
      setDisableFilter(data?.enabled_filters);
    } else {
      setTableLoading(false); // table loading
      ToastNotifyError(data?.message);
    }
  };

  const heading = [
    constants?.JOB_ID,
    constants?.JOB_NAME,
    constants?.QUANTITY,
    constants?.CREATED_AT,
    constants?.JOB_STATUS,
    constants?.OPTION,
  ];

  const closePreview = () => {
    setOpenPreview(false);
    setArtWork('');
  };

  useEffect(() => {
    if (orderDetail?.rowId) {
    }
  }, [orderDetail?.rowId]);

  const previewJob = (id, isSpecial) => {
    if (id) {
      handleViewArtwork(id, isSpecial);
    }
  };

  //job preview

  const blobToBase64 = (url) => {
    return new Promise(async (resolve) => {
      // do a request to the blob uri
      const response = await fetch(url);
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
      // instantiate a file reader
      const fileReader = new FileReader();
      // read the file
      fileReader.readAsDataURL(blob);
      fileReader.onloadend = function () {
        resolve(fileReader.result); // Here is the base64 string
      };
    });
  };

  const handleViewArtwork = async (id, isSpecial) => {
    dispatch(updateLoading({ loading: true }));
    setOpenArtWork(false);
    setOpenPreview(false);
    const responseGenerate = await viewPrintJob(id);
    console.log('responseGenerate: ', responseGenerate);
    if (parseInt(responseGenerate?.status / 100) === 2) {
      const file = await blobToBase64(URL.createObjectURL(responseGenerate?.data));
      setArtWork(file);

      if (isSpecial) {
        setOpenArtWork(true);
      } else {
        setOpenPreview(true);
      }

      setTimeout(() => {
        dispatch(updateLoading({ loading: false }));
      }, 1000);
    } else if (parseInt(responseGenerate?.status / 100) === 5) {
      ToastNotifyError('Something went wrong');
      // handleClose();
      dispatch(updateLoading({ loading: false }));
    } else {
      ToastNotifyError('Print Job Generation In Progress');
      // handleClose();
      dispatch(updateLoading({ loading: false }));
    }
  };

  return (
    <>
      <DashboardLayout>
        <PrintJobsHeader
          totalCount={totalCount}
          onHoldDisable={onHoldDisable}
          downloadAllCallBack={downloadAllCallBack}
        />
        <div className="order_listing_container">
          <PrintFilter
            statusType={statusType}
            setStatusType={setStatusType}
            days={days}
            setDays={setDays}
            orderId={orderId}
            setOrderId={setOrderId}
            jobId={jobId}
            setJobId={setJobId}
            filterJobId={filterJobId}
            filterOrderId={filterOrderId}
            statusList={statusList}
            orderStatusIds={orderStatusIds}
            disableFilter={disableFilter}
            tableListing={orderListingData}
            handleClearAll={handleClearAll}
          />
          <div className="orders_table_container">
            <PrintTable
              rows={orders}
              heading={heading}
              setOrderDetail={setOrderDetail}
              setIsOrderDetails={previewJob}
              page={page}
              handleChangePage={handleChangePage}
              totalPage={totalPage}
              totalCount={totalCount}
              statusColorCode={statusColorCode}
              statusList={statusList}
              tableLoading={tableLoading}
              tableListing={() => orderListingData(page, orderId, statusType, jobId, days)}
            />
          </div>
        </div>
        {/* order details popup */}
        {/* <OrderDetails open={isOrderDetails} handleClosePopup={handleClosePopup} orderId={orderId} /> */}
        {/* order details popup */}
        <Modal
          openDialog={openPreview}
          closeDialog={closePreview}
          noHeader={false}
          heading={constants.JOB_PREVIEW}
          dialogCss={'preview-jobpopup'}
          dialogContent={
            <ViewJob
              rowId={orderDetail?.rowId}
              artWork={artWork}
              displayId={orderDetail?.jobId}
              frontImage={orderDetail?.frontImage}
              jobName={orderDetail?.jobName}
            />
          }
          dialogAction={''}
        />
        <SpecialArtwork
          openDialog={openArtwork}
          closeDialog={() => {
            setOpenArtWork(false);
          }}
          artWorkRes={artWork}
          isSpecial={orderDetail?.isSpecial}
          orderDetail={orderDetail}
          displayId={orderDetail?.jobId}
          jobName={orderDetail?.jobName}
        />
      </DashboardLayout>
    </>
  );
};

export default PrintJobs;
