import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';
import { tableDataStyle, tableHeadingStyle } from '../../styles/custom_style';
import { Box, CircularProgress, Pagination } from '@mui/material';
import './style.css';
import { constants, printJobsStatusColor } from '../../constants';
import Status from '../Status';
import PrintMenu from '../PrintMenu';
import { gettingDisplayCount } from '../../utils';

const PrintTable = ({
  rows = [],
  heading,
  setOrderDetail,
  setIsOrderDetails,
  page,
  handleChangePage,
  totalPage,
  statusList,
  tableLoading = false,
  tableListing,
  totalCount,
}) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F4F6F8',
      borderBottom: '1px solid #EAEAEA',
      borderRadius: '0px',
      maxWidth: '200px',

      ...tableHeadingStyle,
      //   color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      //   background: '#FFFFFF',
      borderBottom: '1px solid #EAEAEA',
      borderRadius: '0px',
      ...tableDataStyle,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    '&:hover': {
      backgroundColor: '#ececee !important',
      cursor: 'pointer',
    },
    '&:last-child td, &:last-child th': { border: 0 },
  }));

  return (
    <>
      <TableContainer component={Paper} sx={{ height: 'calc(100vh - 235px)', borderRadius: '0px' }}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead className="md-medium fs16-16 color_table_heading">
            <TableRow>
              {heading &&
                heading.map((data, idx) => (
                  <StyledTableCell key={data + idx}>{data}</StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          {!tableLoading && rows && (
            <TableBody>
              {rows.map((row, idx) => (
                <>
                  <StyledTableRow
                    className="row_hover"
                    hover
                    key={idx}
                    onClick={() => {
                      setOrderDetail({
                        rowId: row?.id,
                        jobId: row?.display_id,
                        frontImage: row?.front_image,
                        jobName: row?.job_name,
                        isSpecial: row?.is_special,
                      });
                      setIsOrderDetails(row?.id, row?.is_special || false);
                    }}>
                    <StyledTableCell component="th" scope="row">
                      <span>{row?.display_id || '-'}</span>
                    </StyledTableCell>
                    <StyledTableCell>{row?.job_name || '-'}</StyledTableCell>
                    <StyledTableCell>{row?.quantity || '-'}</StyledTableCell>
                    <StyledTableCell>{row?.days || '-'}</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: '200px' }}>
                      {(row?.status && (
                        <Status
                          statusColorCode={printJobsStatusColor}
                          statusList={statusList}
                          type={row?.status}
                          isJobList={false}
                          isPrintJobs={true}
                          disableActions={row?.disable_actions}
                        />
                      )) ||
                        '-'}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}>
                      <PrintMenu
                        id={row?.id}
                        isSpecial={row?.is_special || false}
                        tableListing={tableListing}
                        frontImage={row?.front_image}
                        statusId={row?.status}
                        jobName={row?.job_name}
                        setIsOrderDetails={setIsOrderDetails}
                        row={row}
                        setOrderDetail={setOrderDetail}
                        disableActions={row?.disable_actions}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
            </TableBody>
          )}
        </Table>
        {tableLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 0px',
              width: '100%',
            }}>
            <CircularProgress />
          </Box>
        )}
        {!tableLoading && rows.length <= 0 && (
          <div
            className="md-medium fs16-16 color_table_heading"
            style={{ textAlign: 'center', padding: '12px' }}>
            {constants?.NO_DATA}
          </div>
        )}
      </TableContainer>
      <Box className="footer_paging">
        <div
          className="md-regular fs14-14"
          style={{
            paddingLeft: '16px',
          }}>
          {gettingDisplayCount(totalCount, rows.length, page)}
        </div>
        <Pagination count={totalPage || 1} page={page} onChange={handleChangePage} />
        <div></div>
      </Box>
    </>
  );
};

export default PrintTable;
