import { Stack } from '@mui/system';
import React from 'react';
import svgFiles from '../../assets/svgFiles';
import PopupLayout from '../../layouts/PopupLayout';
import ButtonCustom from '../ButtonCustom';

const ArtworkGeneration = ({ open, handleClosePopup }) => {
  return (
    <PopupLayout
      isOpenPopup={open}
      handleClosePopup={handleClosePopup}
      popupWidth={'467px'}
      padding={'32px 50px'}>
      <Stack justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <div>{svgFiles?.GenerationProcess}</div>
        <div
          className="md-regular fs14-22"
          style={{ textAlign: 'center', marginBottom: '36px', marginTop: '16px' }}>
          Please wait... <br />
          <span className="md-medium">Artwork generation is in progress</span>
        </div>
        <ButtonCustom width="fit-content" padding="18px 48px" onClick={handleClosePopup}>
          Close
        </ButtonCustom>
      </Stack>
    </PopupLayout>
  );
};

export default ArtworkGeneration;
