import React from 'react';
import { Stack } from '@mui/system';
import { constants } from '../../constants';
import LabelCard from '../../layouts/LabelCard';

const ProfileHeader = () => {
  return (
    <>
      <LabelCard
        borderRadius={'0px'}
        height={'98px'}
        padding={'0px 36px'}
        otherStyle={{
          borderBottom: '1px solid #E4E4E4',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Stack spacing={1}>
          <div
            className="color_black_text fs24-24 md-semibold"
            style={{ textTransform: 'capitalize' }}>
            {constants?.PROFILE_HEADING}
          </div>
        </Stack>
      </LabelCard>
    </>
  );
};

export default ProfileHeader;
