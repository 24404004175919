import React from 'react';
import { Box } from '@mui/system';

const LabelCard = ({
  width = 'auto',
  height = '100%',
  padding = '16px',
  borderRadius = '16px',
  background = '#FFFFFF',
  boxShadow = '0px 0px 2px  rgb(145 158 171 / 20%), 0px 12px 24px -4px rgb(145 158 171 / 12%)',
  otherStyle = {},
  children,
  isHover = false,
  hoverStyle = {},
  onClick,
  position = 'static',
  zIndex = 0,
}) => {
  return (
    <Box
      onClick={() => (onClick ? onClick() : null)}
      sx={{
        position: position,
        width: width,
        height: height,
        zIndex: zIndex,
        padding: padding,
        borderRadius: borderRadius,
        background: background,
        boxShadow: boxShadow,
        cursor: isHover ? 'pointer' : 'default',
        '&:hover': {
          opacity: isHover ? 0.6 : 1,
          ...hoverStyle,
        },
        ...otherStyle,
      }}>
      {children}
    </Box>
  );
};

export default LabelCard;
