import React from 'react';
import { daysList, productList } from '../../constants';

import BasicTextField from '../BasicTextfield';
import FilterSelect from '../FilterSelect';
import './style.css';

const Filter = ({
  agencyList,
  statusList,
  disableFilter,
  orderId,
  setOrderId,
  buyingAgency,
  setBuyingAgency,
  productType,
  setProductType,
  statusType,
  setStatusType,
  days,
  setDays,
  filterOrderId,
  handleClearAll,
}) => {
  const getArrayValue = (obj = {}) => {
    let array = [];

    for (let x in obj) {
      array.push(obj[x]);
    }
    return array;
  };

  const handleOrderId = (value) => {
    setOrderId(value);
    filterOrderId(value);
  };

  const filterDisableData = (data = [], type = 1) => {
    let output = [];
    if (data.length > 0) {
      if (type === 1) {
        data.map((val) => output.push(statusList[val]));
      }
      if (type === 2) {
        data.map((val) => output.push(agencyList[val]));
      }
      if (type === 3) {
        data.map((val) => output.push(productList[val]));
      }
      if (type === 4) {
        data.map((val) => output.push(daysList[val]));
      }
    }
    return output;
  };

  return (
    <div className="filer_orders">
      <div className="filer_header">
        <div className="md-medium fs16-23 color_table_heading">Filter by</div>
        <div className="md-regular fs14-20 grey_shade c-pointer hover_ul" onClick={handleClearAll}>
          Clear All
        </div>
      </div>
      <div className="filer_content">
        <BasicTextField
          value={orderId}
          label={'Order ID'}
          handleChange={(e) => handleOrderId(e.target.value)}
        />
        <FilterSelect
          list={getArrayValue(agencyList)}
          value={buyingAgency}
          label={'Buying Agency'}
          handleChange={(e) => setBuyingAgency(e.target.value)}
          disabledList={filterDisableData(disableFilter?.buying_agency, 2)}
        />
        <FilterSelect
          list={getArrayValue(productList)}
          value={productType}
          label={'Product Type'}
          handleChange={(e) => setProductType(e.target.value)}
          disabledList={filterDisableData(disableFilter?.product_type, 3)}
        />
        <FilterSelect
          list={getArrayValue(statusList)}
          value={statusType}
          label={'Order Status'}
          handleChange={(e) => setStatusType(e.target.value)}
          disabledList={filterDisableData(disableFilter?.status, 1)}
        />
        <FilterSelect
          list={getArrayValue(daysList)}
          type={'radio'}
          value={days}
          label={'Days'}
          handleChange={(e) => setDays(e.target.value)}
          multiple={false}
          disabledList={filterDisableData([1, 2, 3, 4], 4)}
        />
      </div>
    </div>
  );
};

export default Filter;
