import { AUTH_SUCCESS, AUTH_LOGOUT, LOADING } from './actionType';

const initialState = {
  loggedIn: null,
  token: false,
  email: null,
  fullName: null,
  firstName: null,
  lastName: null,
  userId: null,
  userType: null,

  //first time password reset key
  isPasswordReset: true,

  // loading handlers
  loading: false,
  buttonLoading: false,
  is_delinquent: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        ...action.updates,
      };
    case AUTH_LOGOUT:
      return {
        ...initialState,
        loggedIn: false,
      };
    case LOADING:
      return {
        ...state,
        ...action.updates,
      };
    default:
      return state;
  }
};

export default authReducer;
