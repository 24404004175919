import { useEffect } from 'react';
import { ThemeProvider } from '@mui/system';
import { connect, useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loader from './components/Loader';
import { constants } from './constants';
import { apiEndPoints, pageEndPoints } from './constants/endPoints';
import useNetworkRequests from './hooks/useNetworkRequests';
import { passwordResetRoutes, privateRoutes, publicRoutes } from './routes';
import { authSuccess, logout } from './store/auth/action';
import { handlePageTitles } from './utils/pageTitles';
import { theme } from './utils/theme';
import { orderStatusList } from './utils/api/orderListing';
import { addSetting } from './store/setting/action';

// Readme: app.js
// 1. routes implement for all conditions,
// 2. first api call to save user details,
// 3. send user details to redux store,
// 4. add material ui breakpoints: wrapping with <ThemeProvider>
// 5. redux store implementation completed
// 6. common function for api call by using custom hook

// toast the notification pending, add only after discussion
// dummy loader implemented, need change when design part ready

function App(props) {
  const dispatch = useDispatch();
  const { request, response } = useNetworkRequests();

  const { loggedIn: isLogin, loading: isLoading, isPasswordReset } = props.auth;

  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (!token || !userId) {
      dispatch(logout());
    } else {
      request(constants?.apiMethods?.GET, `${apiEndPoints?.USER_AUTH_DATA}${userId}`);
    }
  }, [isLogin]);

  useEffect(() => {
    handlePageTitles(window?.location?.pathname);
  }, [window?.location?.pathname]);

  const getOderStatus = async () => {
    const response = await orderStatusList();
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      let orderStatusList = {},
        orderStatusIds = {},
        statusColor = {};

      data &&
        data.map((val) => {
          orderStatusList[val?.id] = val?.name;
          orderStatusIds[val?.name] = val?.id;
          statusColor[val?.id] = val?.color_code;
        });

      dispatch(
        addSetting({
          orderTypeLists: { orderStatusList, orderStatusIds, statusColor },
        }),
      );
    }
  };

  useEffect(() => {
    let networkStatus, data;
    // let statusCode;
    if (response) {
      ({ networkStatus, data } = response);
    }

    if (networkStatus) {
      getOderStatus();
      const { id, email, first_name, last_name, username, is_password_reset, type, is_delinquent } =
        data;
      dispatch(
        authSuccess({
          loggedIn: true,
          fullName: last_name ? `${first_name} ${last_name}` : first_name,
          firstName: first_name,
          lastName: last_name,
          email,
          userId: id,
          isPasswordReset: is_password_reset,
          username,
          userType: type,
          token,
          is_delinquent,
        }),
      );
    }
    // else {
    //   setApiError(data?.message);
    // }
  }, [response]);

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* condition for loader */}
        {isLoading ? <Loader /> : null}

        {isLogin ? (
          isPasswordReset ? (
            <Routes>
              {privateRoutes.map((route, index) => (
                <Route key={index} exact path={route.path} element={route.element} />
              ))}
              <Route path="*" element={<Navigate to={pageEndPoints?.DASHBOARD} replace />} />
            </Routes>
          ) : (
            <Routes>
              {passwordResetRoutes.map((route, index) => (
                <Route key={index} exact path={route.path} element={route.element} />
              ))}
              <Route path="*" element={<Navigate to={pageEndPoints?.RESET} replace />} />
            </Routes>
          )
        ) : isLogin === false ? (
          <Routes>
            {publicRoutes.map((route, index) => (
              <Route key={index} exact path={route.path} element={route.element} />
            ))}
            <Route path="*" element={<Navigate to={pageEndPoints?.LOGIN} replace />} />
          </Routes>
        ) : (
          <Loader />
        )}
      </ThemeProvider>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(App);
