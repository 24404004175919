import React from 'react';

const GenerationFailed = () => {
  return (
    <svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 15.25V8.5L18.25 1H2.5C1.67157 1 1 1.67157 1 2.5V29.5C1 30.3284 1.67157 31 2.5 31H11.5"
        stroke="#DE350B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.75 20.5L25.75 29.5"
        stroke="#DE350B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.75 20.5L16.75 29.5"
        stroke="#DE350B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 1V8.5H25"
        stroke="#DE350B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GenerationFailed;
