import React from 'react';

const Canceled = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.5 15.0008V12.0008C31.4995 11.4747 31.3606 10.958 31.0973 10.5026C30.834 10.0471 30.4556 9.66887 30 9.40583L19.5 3.40583C19.0439 3.14252 18.5266 3.00391 18 3.00391C17.4734 3.00391 16.9561 3.14252 16.5 3.40583L6 9.40583C5.54439 9.66887 5.16597 10.0471 4.90269 10.5026C4.63941 10.958 4.50054 11.4747 4.5 12.0008V24.0008C4.50054 24.5269 4.63941 25.0436 4.90269 25.4991C5.16597 25.9546 5.54439 26.3328 6 26.5958L16.5 32.5958C16.9561 32.8591 17.4734 32.9978 18 32.9978C18.5266 32.9978 19.0439 32.8591 19.5 32.5958L22.5 30.8858M24.75 14.1008L11.325 6.36083"
        stroke="#DE350B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.93555 10.5L18.0005 18M18.0005 18L31.0655 10.5M18.0005 18V33M25.5005 19.5L33.0005 27M25.5005 27L33.0005 19.5"
        stroke="#DE350B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Canceled;
