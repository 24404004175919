import React from 'react';

const LOGO = () => {
  return (
    <svg
      width="218"
      height="41"
      viewBox="0 0 218 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M48.0928 34.619V8.45196H53.6412V30.0371H64.2548V34.619H48.0928Z" fill="#0052CC" />
      <path
        d="M76.5866 14.1973C79.2713 14.1973 81.3296 14.7819 82.7615 15.9513C84.1933 17.1206 84.9092 18.8985 84.9092 21.2849V34.619H81.0969L80.041 31.8985H79.8978C79.325 32.6144 78.7404 33.1991 78.1438 33.6525C77.5471 34.106 76.8611 34.4401 76.0855 34.6548C75.3099 34.8696 74.3672 34.977 73.2576 34.977C72.0763 34.977 71.0143 34.7503 70.0717 34.2969C69.141 33.8434 68.4072 33.1514 67.8702 32.2207C67.3333 31.278 67.0648 30.0848 67.0648 28.6411C67.0648 26.5171 67.8106 24.954 69.3021 23.9517C70.7936 22.9375 73.0308 22.3767 76.0139 22.2693L79.4861 22.1619V21.2849C79.4861 20.2349 79.2117 19.4653 78.6628 18.9761C78.1139 18.4868 77.3503 18.2422 76.3718 18.2422C75.4053 18.2422 74.4567 18.3795 73.526 18.6539C72.5953 18.9283 71.6646 19.2744 70.7339 19.692L68.9262 16.005C69.9882 15.4442 71.1754 15.0027 72.4879 14.6805C73.8124 14.3583 75.1786 14.1973 76.5866 14.1973ZM79.4861 25.3478L77.3741 25.4194C75.6082 25.4671 74.3792 25.7833 73.6871 26.368C73.007 26.9527 72.6669 27.7223 72.6669 28.6769C72.6669 29.5121 72.9115 30.1087 73.4007 30.4667C73.89 30.8127 74.5283 30.9857 75.3158 30.9857C76.4852 30.9857 77.4696 30.6397 78.269 29.9476C79.0804 29.2556 79.4861 28.2712 79.4861 26.9944V25.3478Z"
        fill="#0052CC"
      />
      <path
        d="M96.0061 6.76953V13.2487C96.0061 14.0004 95.9822 14.7461 95.9345 15.4859C95.8987 16.2257 95.851 16.7985 95.7913 17.2041H96.0061C96.5311 16.3808 97.247 15.6828 98.1539 15.1101C99.0607 14.5254 100.236 14.2331 101.68 14.2331C103.923 14.2331 105.743 15.1101 107.139 16.8641C108.535 18.6181 109.233 21.1895 109.233 24.5782C109.233 26.8572 108.911 28.7723 108.266 30.3235C107.622 31.8627 106.721 33.0261 105.564 33.8136C104.406 34.5892 103.064 34.977 101.537 34.977C100.069 34.977 98.9116 34.7145 98.0644 34.1895C97.2172 33.6645 96.5311 33.0738 96.0061 32.4176H95.6302L94.7174 34.619H90.5472V6.76953H96.0061ZM99.9258 18.6002C98.9712 18.6002 98.2135 18.7971 97.6527 19.1908C97.0919 19.5846 96.6803 20.1752 96.4178 20.9628C96.1672 21.7503 96.03 22.7466 96.0061 23.9517V24.5424C96.0061 26.4873 96.2925 27.9788 96.8652 29.0169C97.4379 30.0431 98.482 30.5562 99.9974 30.5562C101.119 30.5562 102.008 30.0371 102.664 28.999C103.332 27.9609 103.667 26.4634 103.667 24.5066C103.667 22.5497 103.332 21.0761 102.664 20.0857C101.996 19.0954 101.083 18.6002 99.9258 18.6002Z"
        fill="#0052CC"
      />
      <path
        d="M121.994 14.2331C123.844 14.2331 125.437 14.591 126.773 15.3069C128.109 16.0109 129.142 17.0371 129.869 18.3854C130.597 19.7337 130.961 21.3804 130.961 23.3253V25.9742H118.057C118.116 27.5135 118.576 28.7246 119.435 29.6076C120.306 30.4786 121.511 30.9141 123.05 30.9141C124.327 30.9141 125.496 30.7829 126.558 30.5204C127.62 30.2579 128.712 29.8641 129.834 29.3391V33.563C128.843 34.0523 127.805 34.4102 126.719 34.6369C125.645 34.8636 124.339 34.977 122.8 34.977C120.795 34.977 119.017 34.6071 117.466 33.8673C115.927 33.1275 114.716 31.9999 113.833 30.4846C112.962 28.9692 112.526 27.0601 112.526 24.7572C112.526 22.4185 112.92 20.4735 113.707 18.9224C114.507 17.3593 115.616 16.1899 117.036 15.4143C118.456 14.6268 120.109 14.2331 121.994 14.2331ZM122.03 18.117C120.968 18.117 120.085 18.457 119.381 19.1371C118.689 19.8173 118.289 20.8852 118.182 22.3409H125.842C125.83 21.5295 125.681 20.8076 125.395 20.1752C125.12 19.5428 124.703 19.0417 124.142 18.6718C123.593 18.3019 122.889 18.117 122.03 18.117Z"
        fill="#0052CC"
      />
      <path d="M140.859 34.619H135.4V6.76953H140.859V34.619Z" fill="#0052CC" />
      <path
        d="M155.392 8.45196C158.769 8.45196 161.233 9.17981 162.784 10.6355C164.335 12.0793 165.111 14.072 165.111 16.6135C165.111 17.759 164.938 18.8567 164.592 19.9068C164.246 20.9449 163.679 21.8696 162.891 22.681C162.116 23.4924 161.078 24.1367 159.777 24.614C158.477 25.0793 156.872 25.312 154.963 25.312H152.582V34.619H147.034V8.45196H155.392ZM155.106 12.9981H152.582V20.7659H154.408C155.446 20.7659 156.347 20.6287 157.11 20.3542C157.874 20.0798 158.465 19.6502 158.882 19.0656C159.3 18.4809 159.509 17.7292 159.509 16.8104C159.509 15.5217 159.151 14.5672 158.435 13.9467C157.719 13.3143 156.609 12.9981 155.106 12.9981Z"
        fill="#0052CC"
      />
      <path
        d="M177.675 8.45196C180.05 8.45196 182.007 8.73833 183.546 9.31107C185.097 9.88381 186.249 10.7489 187 11.9063C187.752 13.0637 188.128 14.5254 188.128 16.2913C188.128 17.4845 187.901 18.5286 187.448 19.4235C186.994 20.3184 186.398 21.0761 185.658 21.6966C184.918 22.317 184.119 22.8242 183.26 23.2179L190.956 34.619H184.799L178.552 24.5782H175.599V34.619H170.051V8.45196H177.675ZM177.282 12.9981H175.599V20.0678H177.389C179.226 20.0678 180.539 19.7636 181.327 19.155C182.126 18.5346 182.526 17.6277 182.526 16.4345C182.526 15.1936 182.096 14.3106 181.237 13.7856C180.39 13.2606 179.071 12.9981 177.282 12.9981Z"
        fill="#0052CC"
      />
      <path
        d="M218 21.4997C218 23.5162 217.749 25.3538 217.248 27.0123C216.747 28.659 215.983 30.0789 214.957 31.2721C213.943 32.4653 212.654 33.3841 211.091 34.0284C209.528 34.6608 207.679 34.977 205.543 34.977C203.407 34.977 201.557 34.6608 199.994 34.0284C198.431 33.3841 197.137 32.4653 196.11 31.2721C195.096 30.0789 194.338 28.653 193.837 26.9944C193.336 25.3359 193.086 23.4924 193.086 21.4639C193.086 18.7553 193.527 16.3987 194.41 14.3941C195.305 12.3776 196.677 10.8145 198.527 9.70483C200.376 8.59514 202.727 8.0403 205.579 8.0403C208.418 8.0403 210.751 8.59514 212.577 9.70483C214.414 10.8145 215.774 12.3776 216.657 14.3941C217.552 16.4107 218 18.7792 218 21.4997ZM198.903 21.4997C198.903 23.3253 199.129 24.9003 199.583 26.2248C200.048 27.5373 200.77 28.5516 201.748 29.2675C202.727 29.9715 203.992 30.3235 205.543 30.3235C207.118 30.3235 208.394 29.9715 209.373 29.2675C210.351 28.5516 211.061 27.5373 211.503 26.2248C211.956 24.9003 212.183 23.3253 212.183 21.4997C212.183 18.7553 211.67 16.5956 210.644 15.0206C209.618 13.4455 207.929 12.658 205.579 12.658C204.015 12.658 202.739 13.016 201.748 13.7319C200.77 14.4359 200.048 15.4501 199.583 16.7746C199.129 18.0871 198.903 19.6622 198.903 21.4997Z"
        fill="#0052CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.39402C0 1.51955 1.51955 0 3.39402 0H12.2185V4.07282H4.07282V12.2185H0V3.39402ZM12.2185 36.6554H4.07282V12.2185L4.07282 28.5097H0V37.3342C0 39.2087 1.51955 40.7282 3.39402 40.7282H12.2185V36.6554ZM28.5097 36.6554V40.7282H37.3342C39.2087 40.7282 40.7282 39.2087 40.7282 37.3342V28.5097H36.6554V36.6554H28.5097ZM40.7282 12.2185H36.6554V4.07282H28.5097V0H37.3342C39.2087 0 40.7282 1.51955 40.7282 3.39402V12.2185Z"
        fill="#172B4D"
      />
      <path d="M6.1084 7.46875H10.7316V33.2633H6.1084V7.46875Z" fill="#172B4D" />
      <path d="M16.126 7.46875H23.8313V33.2633H16.126V7.46875Z" fill="#172B4D" />
      <path d="M28.4541 7.46875H34.6184V33.2633H28.4541V7.46875Z" fill="#172B4D" />
      <path d="M12.2734 7.46875H14.585V33.2633H12.2734V7.46875Z" fill="#172B4D" />
      <path d="M25.3721 7.46875H26.9131V33.2633H25.3721V7.46875Z" fill="#172B4D" />
    </svg>
  );
};

export default LOGO;
