import React, { useEffect } from 'react';
import LOGO from '../../assets/svgFiles/Logo';
import Error from '../../assets/svgFiles/Error';
import './style.css';
import { useNavigate, useSearchParams } from 'react-router-dom';

const RequestError = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const transactionId = search.get('transactionId');

  useEffect(() => {
    if (!transactionId) {
      navigate('/');
    }
  }, [transactionId]);

  return (
    <div>
      <div className="error_header">
        <LOGO />
      </div>
      <div className="error_img">
        <Error />
      </div>
      <div className="error_content">
        <div className="error_content_oops error_content_common md-semibold">OOPS!</div>
        <div className="error_content_text1 error_content_common">Request Identity not found</div>
        <div className="error_content_text2 error_content_common">
          Please contact customer support at{' '}
          <a href="mailto:support@vatslb.com" rel="noreferrer" target="_blank">
            support@vatslb.com
          </a>{' '}
          with this reference number to report this issue
        </div>
        <div className="error_content_text3 error_content_common">
          Reference Number : <span className="error_ref_num">{transactionId || '-'}</span>
        </div>
      </div>
      <div className="error_footer">
        <div
          className="footer_button md-regular fs14-14"
          onClick={() =>
            navigate('/', {
              replace: true,
            })
          }>
          Back to homepage
        </div>
      </div>
    </div>
  );
};

export default RequestError;
