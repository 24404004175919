import React, { useCallback, useEffect, useState } from 'react';
import Filter from '../../components/Filters';
import MyOrderHeader from '../../components/MyOrderHeader';
import { constants, daysListValues, productListIds } from '../../constants';
import DashboardLayout from '../../layouts/DashboardLayout';
// import { createOrderData } from '../../utils';
import './style.css';
import OrderTable from '../../components/OrderTable';
import OrderDetails from '../../components/OrderDetails';
import { orderList } from '../../utils/api/orderListing';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import { debounce, getTotalPage } from '../../utils';
import { buyingAgencyList } from '../../utils/api/orderPlace';
import ArtworkGeneration from '../../components/ArtworkGeneration';
import ArtworkGenerationFailed from '../../components/ArtworkGenerationFailed';
import CanceledOrder from '../../components/CanceledOrder';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
// import { useSearchParams } from 'react-router-dom';

const MyOrder = ({ setting }) => {
  //order status
  const statusList = setting?.orderTypeLists?.orderStatusList || {};
  const orderStatusIds = setting?.orderTypeLists?.orderStatusIds || {};
  const statusColorCode = setting?.orderTypeLists?.statusColor || {};

  const location = useLocation();
  const redirectionState = location?.state?.orderStatus || [];
  const [isOrderDetails, setIsOrderDetails] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  //buying data
  const [agencyList, setAgencyList] = useState({});
  const [agencyIds, setAgencyIds] = useState({});

  const [tableLoading, setTableLoading] = useState(false);

  const [isFailed, setIsFailed] = useState(false);
  const [isGeneration, setIsGeneration] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const [disableFilter, setDisableFilter] = useState({});

  // const [searchParams] = useSearchParams();
  // const orderStatusQuery = searchParams.get('orderStatus');
  // const statusTypeValue = orderStatusQuery ? [orderStatusQuery] : [];
  // console.log('statusTypeValue: ', statusTypeValue);

  // filter states
  const [orderId, setOrderId] = useState('');
  const [buyingAgency, setBuyingAgency] = useState([]);
  const [productType, setProductType] = useState([]);
  const [statusType, setStatusType] = useState(redirectionState);
  const [days, setDays] = useState('');
  useEffect(() => {
    (async () => {
      try {
        await getAgencyList(); // order constants
      } catch (error) {
        // Handle errors if any
        console.error('Error fetching data:', error);
      }
    })();
  }, []);

  //order constants apis data
  const getAgencyList = async () => {
    const response = await buyingAgencyList();
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      let buyingList = {},
        buyingIds = {};
      data &&
        data.map((val) => {
          buyingList[val?.id] = val?.name;
          buyingIds[val?.name] = val?.id;
        });

      setAgencyList(buyingList);
      setAgencyIds(buyingIds);
    }
  };

  // const getOderStatus = async () => {
  //   const response = await orderStatusList();
  //   const { status = 0, data } = response;
  //   if (parseInt(status / 100) === 2) {
  //     let orderStatusList = {},
  //       orderStatusIds = {},
  //       statusColor = {};

  //     data &&
  //       data.map((val) => {
  //         orderStatusList[val?.id] = val?.name;
  //         orderStatusIds[val?.name] = val?.id;
  //         statusColor[val?.id] = val?.color_code;
  //       });

  //     setStatusList(orderStatusList);
  //     setOrderStatusIds(orderStatusIds);
  //     setStatusColorCode(statusColor);
  //   }
  // };

  // order constants apis ==END==

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClosePopup = () => {
    setOrderData({});
    setIsOrderDetails(false);
  };

  useEffect(() => {
    orderListingData(1, orderId, buyingAgency, productType, statusType, days);
  }, [buyingAgency, productType, statusType, days]);

  useEffect(() => {
    if (page) {
      orderListingData(page, orderId, buyingAgency, productType, statusType, days);
    }
  }, [page]);

  const filterOrderId = useCallback(
    debounce((orderId) => {
      if (orderId) {
        orderListingData(1, orderId, buyingAgency, productType, statusType, days);
      }
    }, 1000),
    [],
  );

  // useEffect(() => {
  //   if (redirectionState) {
  //     setTableLoading(true); // table loading

  //     setTimeout(() => {
  //       setStatusType(redirectionState);
  //     }, 500);
  //   }
  // }, []);

  const handleClearAll = () => {
    if (
      orderId ||
      buyingAgency.length > 0 ||
      productType.length > 0 ||
      statusType.length > 0 ||
      days
    ) {
      setOrderId('');
      setBuyingAgency([]);
      setProductType([]);
      setStatusType([]);
      setDays('');
      setPage(1);
    }
  };

  const orderListingData = async (page, orderId, buyingAgency, productType, statusType, days) => {
    setTableLoading(true); // table loading

    let buying = '',
      product = '',
      statusOrder = [],
      period = '';

    if (buyingAgency.length > -1) {
      buying = buyingAgency.reduce(function (prev, val, idx) {
        return idx === 0 ? agencyIds[val] : prev + ',' + agencyIds[val];
      }, '');
    }
    if (productType.length > -1) {
      product = productType.reduce(function (prev, val, idx) {
        return idx === 0 ? productListIds[val] : prev + ',' + productListIds[val];
      }, '');
    }
    if (statusType.length > -1) {
      statusOrder = statusType.reduce(function (prev, val, idx) {
        return idx === 0 ? orderStatusIds[val] : prev + ',' + orderStatusIds[val];
      }, '');
    }
    if (days) {
      period = daysListValues[days];
    }

    const response = await orderList(page, orderId, buying, product, statusOrder, period);

    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      setTableLoading(false); //table loading
      let { count, results } = data;
      setTotalCount(count);
      setTotalPage(getTotalPage(count));
      setOrders([]);
      setOrders(results);
      setDisableFilter(data?.enabled_filters);
      // setDisableFilter({
      //   status: [2, 3, 12],
      //   buying_agency: [1, 2, 4],
      //   product_type: [1, 2],
      //   days: [1, 3, 4],
      // });
    } else {
      setTableLoading(false); // table loading
      ToastNotifyError(data?.message);
    }
  };
  const heading = [
    constants?.ORDER_ID,
    constants?.ORDER_NAME,
    constants?.HEADING,
    constants?.DAYS_AGO,
    constants?.STATUS,
    constants?.OPTION,
  ];
  return (
    <>
      <DashboardLayout>
        <MyOrderHeader
          orderListing={() =>
            orderListingData(1, orderId, buyingAgency, productType, statusType, days)
          }
          totalCount={totalCount}
        />
        <div className="order_listing_container">
          <Filter
            agencyList={agencyList}
            agencyIds={agencyIds}
            statusList={statusList}
            orderStatusIds={orderStatusIds}
            disableFilter={disableFilter}
            orderId={orderId}
            setOrderId={setOrderId}
            buyingAgency={buyingAgency}
            setBuyingAgency={setBuyingAgency}
            productType={productType}
            setProductType={setProductType}
            statusType={statusType}
            setStatusType={setStatusType}
            days={days}
            setDays={setDays}
            filterOrderId={filterOrderId}
            handleClearAll={handleClearAll}
          />
          <div className="orders_table_container">
            <OrderTable
              rows={orders}
              heading={heading}
              setOrderData={setOrderData}
              setIsOrderDetails={setIsOrderDetails}
              page={page}
              handleChangePage={handleChangePage}
              totalPage={totalPage}
              statusColorCode={statusColorCode}
              statusList={statusList}
              tableLoading={tableLoading}
              setIsGeneration={setIsGeneration}
              setIsFailed={setIsFailed}
              setIsCanceled={setIsCanceled}
              reRenderListing={() =>
                orderListingData(page, orderId, buyingAgency, productType, statusType, days)
              }
              totalCount={totalCount}
            />
          </div>
        </div>
        {/* order details popup */}

        <ArtworkGeneration open={isGeneration} handleClosePopup={() => setIsGeneration(false)} />
        <ArtworkGenerationFailed open={isFailed} handleClosePopup={() => setIsFailed(false)} />
        <CanceledOrder open={isCanceled} handleClosePopup={() => setIsCanceled(false)} />

        <OrderDetails
          open={isOrderDetails}
          handleClosePopup={handleClosePopup}
          orderId={orderData?.id || ''}
          orderedDate={orderData?.created_at || ''}
          displayOrderId={orderData?.display_id || ''}
          comments={orderData?.comments || []}
          orderName={orderData?.order_name || ''}
          orderStatus={orderData?.status || ''}
          orderListing={() =>
            orderListingData(1, orderId, buyingAgency, productType, statusType, days)
          }
          statusList={statusList}
        />
        {/* order details popup */}
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    setting: state.setting,
  };
};

export default connect(mapStateToProps)(MyOrder);
