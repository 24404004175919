import React, { useEffect, useState, useRef } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import LinearProgress from '@mui/material/LinearProgress';
import { useDispatch } from 'react-redux';
import ArtWork from './ArtWork';
import LabelSelect from './LabelSelect';
import Stepper from './Stepper';
import ErrorImage from '../../assets/svgFiles/errorIcon';
import UploadButton from '../../assets/svgFiles/uploadButton';
import { constants } from '../../constants';
import './style.css';
import { updateLoading } from '../../store/auth/action';
import {
  buyingAgencyList,
  downloadArtwork,
  generateArtwork,
  orderCreate,
} from '../../utils/api/orderPlace';
import Modal from '../Modal';
import SelectField from '../SelectField';
import { ToastNotifyError } from '../Toast/ToastNotify';
import { pageEndPoints } from '../../constants/endPoints';
import { useNavigate } from 'react-router';
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

const OrderPlace = ({ openOrder, closeOrder, orderListing }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listData, setListData] = useState([]);
  const [selectAgency, setselectAgency] = useState(null);
  const [selectImage, setselectImage] = useState(null);
  const [step, setStep] = useState(1);
  const [fileError, setFileError] = useState(false);
  const [disableNext, setdisableNext] = useState(true);
  const [fileDetail, setFileDetail] = useState({});
  const [open, setOpen] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [artWork, setArtWork] = useState('');
  const [progress, setProgress] = useState(0);
  const [orderDetail, setOrderDetail] = useState(0);
  const inputRef = useRef();
  const [errorMsg, setErrorMsg] = useState(null);
  const [downloadDetail, setdownloadDetail] = useState(0);
  const [listDataImage, setListDataImage] = useState([]);
  const [fileResponse, setFileResponse] = useState('');

  useEffect(() => {
    agenceyList();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 6000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const agenceyList = async () => {
    const response = await buyingAgencyList();
    const { status = 0, data } = response;
    if (parseInt(status / 100) === 2) {
      setListData(data);
    } else {
      ToastNotifyError(data?.message);
    }
  };
  useEffect(() => {
    if (openOrder) {
      setselectAgency(null);
      setselectImage(null);
      setFileDetail({});
      setFileError(false);
      setStep(1);
    }
  }, [openOrder]);
  useEffect(() => {
    if (step === 1 && !selectAgency) {
      setdisableNext(true);
    } else if (step === 2 && !selectImage) {
      setdisableNext(true);
    } else if (step === 3 && !fileError && !fileDetail?.name) {
      setdisableNext(true);
    } else {
      setdisableNext(false);
    }
  }, [step, selectAgency, selectImage, fileDetail, fileError]);

  const handleChange = (event) => {
    setselectAgency(event.target.value);
  };

  const promiseTimeout = new Promise((resolve) =>
    setTimeout(() => resolve(false), constants?.ART_GENERATION_TIME),
  );

  const blobToBase64 = (url) => {
    return new Promise(async (resolve) => {
      // do a request to the blob uri
      const response = await fetch(url);
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
      // instantiate a file reader
      const fileReader = new FileReader();
      // read the file
      fileReader.readAsDataURL(blob);
      fileReader.onloadend = function () {
        resolve(fileReader.result); // Here is the base64 string
      };
    });
  };

  const promiseTimeoutPreview = new Promise((resolve) =>
    setTimeout(() => resolve(false), constants?.ART_PREVIEW_TIME),
  );

  const nextFunc = async () => {
    setErrorMsg(null);
    if (step < 3) {
      setStep(step + 1);
    } else if (step === 3) {
      const formData = new FormData();
      formData.append('buying_agency', selectAgency);
      formData.append('product', selectImage);
      formData.append('requirement', fileDetail);
      dispatch(updateLoading({ loading: true }));
      const response = await orderCreate(formData);
      dispatch(updateLoading({ loading: false }));
      const { status = 0, data } = response;

      //for delinquent user

      if (status === 403) {
        setErrorMsg(data?.detail);
        closeProgress();
        setdisableNext(true);
        setFileError(true);
        setTimeout(() => {
          window.location.reload(true);
        }, '2500');
      } else if (parseInt(status / 100) === 2) {
        setOrderDetail(data);
        console.log('data: ', data);
        setProgress(0);
        setOpenProgress(true);
        closeOrder();
        const responseArtwork = await Promise.race([promiseTimeout, generateArtwork(data?.id)]);
        console.log('responseArtwork: ', responseArtwork);
        if (responseArtwork?.status === 400) {
          ToastNotifyError(responseArtwork?.data?.message);
        }
        if (!responseArtwork) {
          setOpen(false);
          closeOrder();
          setOpenProgress(false);
          setOpenError(true);
        } else if (parseInt(responseArtwork?.status / 100) === 2) {
          const responseGenerate = await Promise.race([
            promiseTimeoutPreview,
            downloadArtwork(data?.id),
          ]);
          if (!responseGenerate) {
            setOpen(false);
            closeOrder();
            setOpenProgress(false);
            setOpenError(true);
          } else {
            if (parseInt(responseGenerate?.status / 100) === 2) {
              setFileResponse(responseGenerate);
              setdownloadDetail(responseGenerate?.data);
              const file = await blobToBase64(URL.createObjectURL(responseGenerate?.data));
              setArtWork(file);
              closeProgress();
              setOpen(true);
            } else if (parseInt(status / 100) === 5) {
              closeProgress();
              ToastNotifyError('Something went wrong');
            } else {
              closeProgress();
              ToastNotifyError(data?.message);
            }
          }
        } else if (parseInt(status / 100) === 5) {
          closeProgress();
          ToastNotifyError('Something went wrong');
        } else {
          closeProgress();
          ToastNotifyError(data?.message);
        }
      } else if (parseInt(status / 100) === 5) {
        closeProgress();
        setdisableNext(true);
        setFileError(true);
        setErrorMsg('Something went wrong');
      } else {
        closeProgress();
        setdisableNext(true);
        setFileError(true);
        setErrorMsg(data?.message);
      }
    }
  };

  const backFunc = () => {
    if (step > 1 && step <= 3) {
      setStep(step - 1);
      if (step === 3) {
        setListDataImage([]);
        setselectImage(null);
        setFileDetail({});
        // setOpenError(false)
        setFileError(false);
        setErrorMsg('');
      } else if (step === 2) {
        setselectAgency(null);
        setListDataImage([]);
        setselectImage(null);
      }
    }
  };

  function fileSize(bytes) {
    var exp = (Math.log(bytes) / Math.log(1024)) | 0;
    var result = (bytes / Math.pow(1024, exp)).toFixed(2);
    return result + ' ' + (exp === 0 ? 'bytes' : 'KMGTPEZY'[exp - 1] + 'B');
  }

  const handleChangeFile = () => {
    setErrorMsg(null);
    const {
      current: { files: [file] = [] },
    } = inputRef;
    if (constants.EXCEL_TYPE_ARR.includes(file?.type)) {
      setFileDetail(file);
      setFileError(false);
    } else {
      setdisableNext(true);
      setFileDetail(file);
      setFileError(true);
    }
  };

  const renderUploadFile = () => {
    return (
      <div>
        {!fileError && fileDetail?.name ? (
          <label className="uploadImageActive" htmlFor="upload-button">
            <div className={`md-medium ${'fileName'}`}>{fileDetail?.name}</div>
            <div className={`md-light ${'fileSize'}`}>{fileSize(fileDetail?.size)}</div>
          </label>
        ) : fileError ? (
          <>
            <label className="uploadImageInActive" htmlFor="upload-button">
              <div className={`md-medium ${'fileName'}`}>{fileDetail?.name}</div>
              <div className={`md-light ${'fileSize'}`}>{fileSize(fileDetail?.size)}</div>
            </label>
            <div className={`md-regular ${'errorMsg'}`}>
              <span className="position-absolute">
                <ErrorImage />
              </span>
              {errorMsg ? errorMsg : constants.FILE_VALIDATION}
            </div>
            {/* <div
              onClick={() => setErrorMsg(null)}
              style={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                zIndex: 5,
              }}>
              X
            </div> */}
          </>
        ) : (
          <label className="uploadImage largeGaps" htmlFor="upload-button">
            <span style={{ marginRight: '5.4px' }}>
              <UploadButton />
            </span>
            {constants.UPLOAD_FILE}
          </label>
        )}

        <input
          type="file"
          id="upload-button"
          className="displayNone"
          onClick={(e) => {
            console.log(e);
            setFileDetail({});
            e.target.value = null;
            setFileError(false);
            setErrorMsg(null);
          }}
          onInput={handleChangeFile}
          accept={constants.EXCEL_TYPE}
          ref={inputRef}
        />
      </div>
    );
  };

  const closeProgress = () => {
    setOpenProgress(false);
  };

  const closeError = () => {
    setOpenError(false);
  };

  const closeErrorPopup = () => {
    navigate(pageEndPoints?.MY_ORDER, {
      replace: true,
    });
    setOpenError(false);
  };

  return (
    <div>
      <Modal
        openDialog={openOrder}
        closeDialog={closeOrder}
        noHeader={false}
        dialogCss={'order_popup'}
        heading={constants.CREATE_ORDER_BTN}
        dialogContent={
          <>
            <div>
              <Stepper step={step} />
            </div>
            {step === 1 ? (
              <div className="order-step-1">
                <div className={`md-medium ${'order-heading'}`}>{constants.BUYING_AGENCY}</div>
                <SelectField
                  list={listData}
                  selectedValue={selectAgency}
                  handleChange={handleChange}
                  placeHolder={'Select Buying Agency'}
                />
              </div>
            ) : step === 2 ? (
              <div className="order-step-1">
                <div className={`md-medium ${'order-heading'}`}>{constants.CHOOSE_LABEL_TYPE}</div>
                <LabelSelect
                  id={selectAgency}
                  selectImage={selectImage}
                  setselectImage={(value) => setselectImage(value)}
                  setListData={setListDataImage}
                  listData={listDataImage}
                />
              </div>
            ) : (
              <div className="order-step-1">
                <div className={`md-medium ${'order-heading'}`}>{constants.UPLOAD_FILE}</div>
                {renderUploadFile()}
              </div>
            )}
          </>
        }
        dialogAction={
          <DialogActions style={step > 1 ? { justifyContent: 'space-between' } : {}}>
            {step > 1 && (
              <Button className={`md-medium ${'dialog-button-inactive'}`} onClick={backFunc}>
                {constants?.BACK}
              </Button>
            )}
            <Button
              className={
                disableNext
                  ? `md-medium ${'dialog-button'}`
                  : `md-medium ${'dialog-button-disabled'}`
              }
              onClick={nextFunc}
              disabled={disableNext}>
              {step > 2 ? constants?.FINISH : constants?.NEXT}
            </Button>
          </DialogActions>
        }
      />
      {open && (
        <ArtWork
          openDialog={open}
          closeDialog={() => {
            setOpen(!open);
          }}
          artWorkRes={artWork}
          orderDetail={orderDetail}
          orderListing={orderListing}
          downloadDetail={downloadDetail}
          // shipDetail={false}
          responseGenerate={fileResponse}
        />
      )}
      <Modal
        openDialog={openProgress}
        closeDialog={closeProgress}
        noHeader={true}
        dialogCss={''}
        heading={''}
        dialogContent={
          <div className="progress-popup">
            <div style={{ width: '100%' }}>
              <LinearProgressWithLabel value={progress} />
              <div className="progress-text">
                Please Wait! <span style={{ fontWeight: '600' }}>Artwork Generating...</span>
              </div>
            </div>
          </div>
        }
        dialogAction={''}
      />
      <Modal
        openDialog={openError}
        closeDialog={closeError}
        noHeader={true}
        dialogCss={''}
        heading={''}
        dialogContent={
          <div className="progressError-popup">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <ErrorIcon style={{ color: '#FFD748', fontSize: '35px' }} />
              <div className="progress-text">
                The artwork generation is taking longer than usual, you can check the
                <span style={{ fontWeight: '500' }}> order status from My Orders listing</span>
              </div>
              <Button className={`md-medium ${'dialog-button'}`} onClick={closeErrorPopup}>
                {constants?.CLOSE}
              </Button>
            </div>
          </div>
        }
        dialogAction={''}
      />
    </div>
  );
};

export default OrderPlace;
