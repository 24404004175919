import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';
import { tableDataStyle, tableHeadingStyle } from '../../styles/custom_style';
import { Box, CircularProgress, Pagination } from '@mui/material';
import './style.css';
import { constants } from '../../constants';
import OrderMenu from '../OrderMenu';
import Status from '../Status';
import { gettingDisplayCount } from '../../utils';

const OrderTable = ({
  rows = [],
  heading,
  setOrderData,
  setIsOrderDetails,
  page,
  handleChangePage,
  totalPage,
  statusColorCode,
  statusList,
  tableLoading = false,
  setIsGeneration,
  setIsFailed,
  setIsCanceled,
  reRenderListing,
  totalCount,
}) => {
  const handleClickRow = (row) => {
    setOrderData({});
    setOrderData(row);
    if (row?.status === 2) {
      setIsGeneration(true);
    } else if (row?.status === 3) {
      setIsFailed(true);
    } else if (row?.status === 11) {
      setIsCanceled(true);
    } else {
      setIsOrderDetails(true);
    }
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F4F6F8',
      borderBottom: '1px solid #EAEAEA',
      borderRadius: '0px',
      maxWidth: '200px',

      ...tableHeadingStyle,
      //   color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      //   background: '#FFFFFF',
      borderBottom: '1px solid #EAEAEA',
      borderRadius: '0px',
      ...tableDataStyle,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    '&:hover': {
      backgroundColor: '#ececee !important',
      cursor: 'pointer',
    },
    '&:last-child td, &:last-child th': { border: 0 },
  }));

  return (
    <>
      <TableContainer component={Paper} sx={{ height: 'calc(100vh - 235px)', borderRadius: '0px' }}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead className="md-medium fs16-16 color_table_heading">
            <TableRow>
              {heading &&
                heading.map((data, idx) => (
                  <StyledTableCell key={data + idx}>{data}</StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          {!tableLoading && rows && (
            <TableBody>
              {rows.map((row, idx) => (
                <>
                  <StyledTableRow
                    className="row_hover"
                    hover
                    key={row.display_id + `${idx + 500}`}
                    onClick={() => handleClickRow(row)}>
                    <StyledTableCell component="th" scope="row">
                      <span>{row?.display_id || '-'}</span>
                    </StyledTableCell>
                    <StyledTableCell>{row?.order_name || '-'}</StyledTableCell>
                    <StyledTableCell>{row?.quantity || '0'}</StyledTableCell>
                    <StyledTableCell>{row?.days || '-'}</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: '200px' }}>
                      {(row?.status && (
                        <Status
                          statusColorCode={statusColorCode}
                          shipmentDetails={row?.shipment_detail}
                          statusList={statusList}
                          page={page}
                          type={row?.status}
                          comment={row?.comments}
                          priority={row?.priority === 1}
                        />
                      )) ||
                        '-'}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}>
                      <OrderMenu
                        rowId={row.id}
                        reRenderListing={reRenderListing}
                        status={row?.status}
                        shippingStatus={row?.is_shippable}
                        orderData={() => setOrderData(row)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
            </TableBody>
          )}
        </Table>
        {tableLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 0px',
              width: '100%',
            }}>
            <CircularProgress />
          </Box>
        )}
        {!tableLoading && rows.length <= 0 && (
          <div
            className="md-medium fs16-16 color_table_heading"
            style={{ textAlign: 'center', padding: '12px' }}>
            {constants?.NO_DATA}
          </div>
        )}
      </TableContainer>
      <Box className="footer_paging">
        <div
          className="md-regular fs14-14"
          style={{
            paddingLeft: '16px',
          }}>
          {gettingDisplayCount(totalCount, rows.length, page)}
        </div>
        <Pagination count={totalPage || 1} page={page} onChange={handleChangePage} />
        <div></div>
      </Box>
    </>
  );
};

export default OrderTable;
