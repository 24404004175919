import React from 'react';

const Priority = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="24"
        height="24"
        transform="translate(24 24) rotate(-180)"
        fill="white"
        fillOpacity="0.01"
      />
      <path
        d="M5.55009 9.025C5.32271 9.15728 5.05235 9.19464 4.79761 9.12897C4.54287 9.0633 4.32426 8.8999 4.18917 8.67417C4.05407 8.44844 4.01337 8.17857 4.07587 7.92303C4.13838 7.6675 4.29906 7.44688 4.52309 7.309L11.4931 3.136C11.6488 3.04286 11.8269 2.99384 12.0084 2.99419C12.1898 2.99454 12.3677 3.04425 12.5231 3.138L19.4291 7.31C19.6561 7.44712 19.8194 7.6688 19.883 7.92629C19.9465 8.18378 19.9052 8.45597 19.7681 8.683C19.631 8.91002 19.4093 9.07328 19.1518 9.13686C18.8943 9.20043 18.6221 9.15912 18.3951 9.022L12.0041 5.16L5.55009 9.024L5.55009 9.025Z"
        fill="#DE350B"
      />
      <path
        d="M12.0041 11.1629L5.55009 15.0279C5.32271 15.1602 5.05235 15.1975 4.79761 15.1319C4.54287 15.0662 4.32426 14.9028 4.18917 14.6771C4.05407 14.4513 4.01337 14.1815 4.07587 13.9259C4.13838 13.6704 4.29906 13.4498 4.52309 13.3119L11.4931 9.1379C11.6488 9.04476 11.8269 8.99574 12.0084 8.9961C12.1898 8.99645 12.3677 9.04616 12.5231 9.13991L19.4291 13.3139C19.6561 13.451 19.8194 13.6727 19.883 13.9302C19.9465 14.1877 19.9052 14.4599 19.7681 14.6869C19.631 14.9139 19.4093 15.0772 19.1518 15.1408C18.8943 15.2043 18.6221 15.163 18.3951 15.0259L12.0041 11.1629Z"
        fill="#DE350B"
        fill-opacity="0.8"
      />
      <path
        d="M12.0041 17.1761L5.55009 21.0411C5.43737 21.1105 5.31199 21.1568 5.18123 21.1773C5.05047 21.1979 4.91693 21.1923 4.78835 21.1608C4.65978 21.1294 4.53873 21.0727 4.43222 20.9941C4.32571 20.9155 4.23586 20.8166 4.16789 20.703C4.09991 20.5894 4.05516 20.4635 4.03623 20.3325C4.0173 20.2015 4.02457 20.068 4.05761 19.9398C4.09065 19.8117 4.14881 19.6913 4.22872 19.5858C4.30862 19.4803 4.40868 19.3916 4.52309 19.3251L11.4931 15.1511C11.6488 15.0579 11.8269 15.0089 12.0084 15.0093C12.1898 15.0096 12.3677 15.0593 12.5231 15.1531L19.4291 19.3271C19.6561 19.4642 19.8194 19.6859 19.883 19.9434C19.9465 20.2009 19.9052 20.4731 19.7681 20.7001C19.631 20.9271 19.4093 21.0904 19.1518 21.1539C18.8943 21.2175 18.6221 21.1762 18.3951 21.0391L12.0041 17.1761Z"
        fill="#DE350B"
        fill-opacity="0.6"
      />
    </svg>
  );
};

export default Priority;
