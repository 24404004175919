import React from 'react';

const ExceedError = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3255 3.79825C18.8064 2.81725 17.1925 2.81725 16.6735 3.79825L3.17345 29.2982C3.05192 29.5268 2.99168 29.7829 2.99858 30.0417C3.00548 30.3005 3.0793 30.553 3.21283 30.7748C3.34636 30.9965 3.53507 31.1799 3.76055 31.3071C3.98603 31.4342 4.2406 31.5008 4.49945 31.5002H31.4994C31.7581 31.5008 32.0125 31.4343 32.2379 31.3072C32.4632 31.1802 32.6517 30.9969 32.7851 30.7753C32.9185 30.5536 32.9922 30.3012 32.999 30.0426C33.0058 29.784 32.9455 29.5281 32.8239 29.2998L19.3255 3.79825ZM19.4995 27.0002H16.4995V24.0002H19.4995V27.0002ZM16.4995 21.0002V13.5002H19.4995L19.5009 21.0002H16.4995Z"
        fill="#F6C343"
      />
    </svg>
  );
};

export default ExceedError;
