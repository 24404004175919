import React from 'react';

const QuestionMark = ({ width = '36px' }) => {
  return (
    <div>
      <svg
        width={width}
        height={width}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.605 16.875L21.255 18.255C20.175 19.335 19.5 20.25 19.5 22.5H16.5V21.75C16.5 20.085 17.175 18.585 18.255 17.505L20.115 15.615C20.67 15.075 21 14.325 21 13.5C21 12.7044 20.6839 11.9413 20.1213 11.3787C19.5587 10.8161 18.7956 10.5 18 10.5C17.2044 10.5 16.4413 10.8161 15.8787 11.3787C15.3161 11.9413 15 12.7044 15 13.5H12C12 11.9087 12.6321 10.3826 13.7574 9.25736C14.8826 8.13214 16.4087 7.5 18 7.5C19.5913 7.5 21.1174 8.13214 22.2426 9.25736C23.3679 10.3826 24 11.9087 24 13.5C23.9978 14.7649 23.4965 15.9777 22.605 16.875ZM19.5 28.5H16.5V25.5H19.5M18 3C16.0302 3 14.0796 3.38799 12.2597 4.14181C10.4399 4.89563 8.78628 6.00052 7.3934 7.3934C4.58035 10.2064 3 14.0218 3 18C3 21.9782 4.58035 25.7936 7.3934 28.6066C8.78628 29.9995 10.4399 31.1044 12.2597 31.8582C14.0796 32.612 16.0302 33 18 33C21.9782 33 25.7936 31.4196 28.6066 28.6066C31.4196 25.7936 33 21.9782 33 18C33 9.705 26.25 3 18 3Z"
          fill="#F6C343"
        />
      </svg>
    </div>
  );
};

export default QuestionMark;
