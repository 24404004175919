import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { messages } from '../common/messages';
import { constants } from '../constants';
import { pageEndPoints } from '../constants/endPoints';
import { logout, updateLoading } from '../store/auth/action';
import { v4 as uuidv4 } from 'uuid';

const NETWORK_BASE_URL = process.env.REACT_APP_NETWORK_BASE_URL;

const useNetworkRequests = () => {
  const uuid = uuidv4();

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [errorResponse, setErrorResponse] = useState(null);
  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateLoading({
        loading,
      }),
    );
  }, [loading]);

  const startFetching = () => {
    setLoading(true);
    setResponse(null);
    setErrorResponse(null);
  };

  const clear = () => {
    setResponse(null);
  };

  const fetchedData = () => {
    setLoading(false);
  };

  const requestData = async (
    method = constants?.apiMethods?.GET,
    url,
    data = {},
    isLoading = false,
  ) => {
    let config;

    if (token) {
      config = {
        method,
        url: `${NETWORK_BASE_URL}/${url}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Request-ID': uuid,
        },
        data,
      };
    } else {
      config = {
        method,
        url: `${NETWORK_BASE_URL}/${url}`,
        data,
        headers: {
          'X-Request-ID': uuid,
        },
      };
    }
    isLoading && startFetching(); //call loader and set initial data as empty

    await axios(config)
      .then((res) => {
        fetchedData(); // stop loader and error state null

        setResponse({
          networkStatus: true,
          data: res?.data,
          statusCode: res?.status,
        }); // save response data
      })
      .catch((err) => {
        fetchedData(); // stop loader and error response state null
        setResponse({
          networkStatus: false,
          data: err?.response?.data,
          statusCode: err?.response?.status,
        });
        setErrorResponse(err?.response?.data);

        if (err?.response?.status === constants?.requestStatusCode?.UNAUTHORIZED) {
          dispatch(logout()); //dispatching force logout
          //   toast(constants?.toastType?.ERROR, messages?.AUTH_SESSION_EXPIRED);

          navigate(pageEndPoints?.LOGIN, {
            replace: true,
          });
        } else if (parseInt(err?.response?.status / 100) === 5) {
          window.location.href = `/error?transactionId=${uuid}`;
        } else if (err?.response) {
          //   toast(constants?.toastType?.ERROR, err?.response?.data?.message);
        } else if (err?.request) {
          //   toast(constants?.toastType?.ERROR, messages?.backendSlow);
        } else {
          //   toast(constants?.toastType?.ERROR, messages?.backendUnusual);
        }
      });
  };

  return {
    loading,
    request: requestData,
    clear,
    response,
    errorResponse,
  };
};

export default useNetworkRequests;
