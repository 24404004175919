import React from 'react';

const Progress = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="26" cy="26" r="26" fill="#F8D46C" fillOpacity="0.16" />
      <path
        d="M26 12C23.2311 12 20.5243 12.8211 18.222 14.3594C15.9197 15.8978 14.1253 18.0843 13.0657 20.6424C12.0061 23.2006 11.7288 26.0155 12.269 28.7313C12.8092 31.447 14.1426 33.9416 16.1005 35.8995C18.0584 37.8574 20.553 39.1908 23.2687 39.731C25.9845 40.2712 28.7994 39.9939 31.3576 38.9343C33.9157 37.8747 36.1022 36.0803 37.6406 33.778C39.1789 31.4757 40 28.7689 40 26C39.9958 22.2883 38.5194 18.7298 35.8948 16.1052C33.2702 13.4806 29.7117 12.0042 26 12ZM26 38C22.8174 38 19.7652 36.7357 17.5147 34.4853C15.2643 32.2348 14 29.1826 14 26C14 22.8174 15.2643 19.7652 17.5147 17.5147C19.7652 15.2643 22.8174 14 26 14V26L34.481 34.481C33.3688 35.5972 32.047 36.4827 30.5916 37.0866C29.1361 37.6905 27.5757 38.0009 26 38Z"
        fill="#F8D46C"
      />
    </svg>
  );
};

export default Progress;
