import React, { useState } from 'react';
import { ClickAwayListener, Popover } from '@mui/material';
import { connect } from 'react-redux';
import style from './style.module.css';
import DownArrow from '../../assets/svgFiles/downArrow';
import { projectFiles } from '../../constants/filesConstants';
import MenuCard from '../MenuCard';
import UserAvatar from '../UserAvatar';

const Navbar = (props) => {
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { fullName, email } = props.auth;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={style?.navbar_container}>
      <div className={style?.navbar_logo}>
        <img src={projectFiles?.logoLabelApp} />
      </div>
      <ClickAwayListener onClickAway={handleClose}>
        <div className={style?.navbar_menu_toggle}>
          <div className={style?.navbar_user_container} onClick={handleClick}>
            <div className={style?.user_avatar_container}>
              <UserAvatar fullName={fullName} />
            </div>
            <div className={style?.user_options_container}>
              <DownArrow />
            </div>
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            PaperProps={{
              sx: {
                background: '#FFFFFF',
                boxShadow:
                  '0px 0px 2px rgb(145 158 171 / 20%), 0px 12px 24px -4px rgb(145 158 171 / 12%)',
                borderRadius: '16px',
                overflow: 'hidden',
                marginTop: '7px',
                marginLeft: '-20px',
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <MenuCard fullName={fullName} email={email} />
          </Popover>

          {/* <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={isMenuOpen}
          open={isMenuOpen}
          onClose={handleClose}
          PaperProps={{
            style: {
              // maxHeight:  * 4.5,
              width: '20ch',
            },
          }}>
          <MenuItem onClick={handleClose}>Data 1</MenuItem>
          <MenuItem onClick={handleClose}>Data 1</MenuItem>
          <MenuItem onClick={handleClose}>Data 1</MenuItem>
          <MenuItem onClick={handleClose}>Data 1</MenuItem>
        </Menu> */}
        </div>

        {/* <MenuCard
        fullName={fullName}
        email={email}
        isMenuOpen={isMenuOpen}
        handleClose={handleClose}
      /> */}
      </ClickAwayListener>
      {/* menu listing */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Navbar);
